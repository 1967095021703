import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UapiService } from 'src/app/services/http/uapi.service';
import { Router } from '@angular/router';
import $ from 'jquery';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {AwashService} from '../../services/awash.service';

@Component({
  selector: 'app-awash-bank-modal',
  templateUrl: './awash-bank-modal.component.html',
  styleUrls: ['./awash-bank-modal.component.css']
})
export class AwashBankModalComponent implements OnInit {
  selectedFlight;
  exchangeRate;
  price;
  data;
  url;
  pnr;
  pnrSearchBookingResponse: any;
  awashBankAccount = "01320115600801";
  awashPhoneNumber = "251913359044";
  awashFullName = "Mukera Mukera";
  step = 1;
  otp: string;
  loading = false;

  constructor(public sanitizer: DomSanitizer, private router: Router, public globalService: GlobalService, private uapiService: UapiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AwashBankModalComponent>,
              private awashService: AwashService) { }

  ngOnInit(): void {
    console.log('ON INIT')
    this.selectedFlight = this.globalService.getSelectedFlight();
  }

  goBackStep() {
    this.step = this.step - 1;
  }

  continue() {
    // this.dialogRef.close({awashBankAccount: this.awashBankAccount})
    if (!this.loading) {
      this.step += 1;
      if (this.step === 1) {
        // this.uapiService.postBooking()
      }
    }
  }

  dismiss() {
    this.dialogRef.close()
  }
}
