import { Component, HostListener } from '@angular/core';
import { FacebookService } from 'ngx-facebook';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'angular-starter';
  version = 'Angular version 9.1.2';
  session: boolean = false;

  page_id = environment.page_id || '';

  constructor(private facebookService: FacebookService) {
    this.session = localStorage.getItem('login') == 'true' ? true : false;
  }

  ngOnInit(): void {
    this.initFacebookService();
  }

  private async initFacebookService() {
    try {
      const initParams = { xfbml: true, version: 'v3.2' };
      this.facebookService.init(initParams);

      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute('page_id', this.page_id);
      chatbox.setAttribute('attribution', 'biz_inbox');

      await (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    } catch (err) {
      console.log('ERR >>> ', err);
    }
  }
}
