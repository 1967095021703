import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UapiService } from 'src/app/services/http/uapi.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  paid: boolean = false;
  paymentError = false;
  bookingResponse: any;
  tickets: any[] = [];
  Object = Object;
  pnrInfoInterval;
  checkingStatus = false;
  issuingTicket = false;
  selectedFlight: any;
  dataReturned = false;
  selectedPaymentMethod: any;
  paymentErro: any;
  isAtmPayment = false;
  constructor(private globalService: GlobalService, private uapiService: UapiService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.bookingResponse = this.globalService.getBookingResponse()
    this.selectedFlight = this.globalService.getBookedFlight();
    this.selectedPaymentMethod = this.globalService.getSelectedPaymentMethodObj()
    if (!this.bookingResponse.BookingResponse) {
      this.uapiService.pnrInfo(
        {
          "pnr": this.bookingResponse.pnr,
          "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiQkFOS09GQUJZU1NJTklBIiwicGFzc3dvcmQiOiJxVHRScmU0UGlpcTVaSEdER2VvdVhTRDdZeW1weThJWXlFY1ZYc0tlcThOZ3ptS0E2ajh1SERzRWE5S1IiLCJpYXQiOjE1MTYyMzkwMjJ9.TKr6LzsaERAPbNAbFW2EYP1jhov6T6DYHfqKGQsp-7OZXfjI4O8aXhRklrnlybpguspviJ7gXv8p6F6hG0X0iw"
        }
      ).subscribe((res: any) => {
        if (res.pnr) {
          console.log({res})
          this.dataReturned = true;
          this.bookingResponse = res.bookingResponse
          this.bookingResponse.TotalPrice = res.totalPrice
          this.bookingResponse.MainPassenger = res.mainPassenger
          this.bookingResponse.pnr = res.bookingResponse.pnr
          this.bookingResponse.npnr = res.bookingResponse.npnr
          this.bookingResponse.PaymentStatus = res.payment_status
          this.bookingResponse.passengers = res.passengers
          this.bookingResponse.payment_method = res.payment_method;
          this.bookingResponse.isAtm = res.paymentMethod.code === 'Bank of Abyssina ATM' || res.paymentMethod.code === 'boa_atm';

          if (res.tickets.length > 0) {
            this.globalService.setTickets(res.tickets[0].number)
            this.tickets = res.tickets[0].number
            this.toastr.info('Ticket is already issued before ', "MSG", { timeOut: 5000 });
          }
          //Subscribe for Payment Completion Update
          // this.pnrInfoInterval = setInterval(() => {
          //   this.pnrInfo()
          // }, 5000)
        }
      }, err => {

      })
    } else {
      this.dataReturned = true;
    }
    this.paymentError = this.globalService.getPaymentError()
    this.isAtmPayment = this.selectedPaymentMethod.code === 'boa_atm';

  }

  paidTicket() {
    this.paid = true;
  }

  issueETicket() {
    this.issuingTicket = true;
    this.paid = true;
    const issueParams = {
      commission: {
        amount: 'UAH0',
      },
      fop: {
        type: 'Cash',
      },
      pnr: this.bookingResponse.pnr ? this.bookingResponse.pnr : this.bookingResponse.BookingResponse.pnr,
      ip: ''
    }
    this.uapiService.pnrInfo(
      {
        "pnr": this.bookingResponse.pnr ? this.bookingResponse.pnr : this.bookingResponse.BookingResponse.pnr,
        "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiQkFOS09GQUJZU1NJTklBIiwicGFzc3dvcmQiOiJxVHRScmU0UGlpcTVaSEdER2VvdVhTRDdZeW1weThJWXlFY1ZYc0tlcThOZ3ptS0E2ajh1SERzRWE5S1IiLCJpYXQiOjE1MTYyMzkwMjJ9.TKr6LzsaERAPbNAbFW2EYP1jhov6T6DYHfqKGQsp-7OZXfjI4O8aXhRklrnlybpguspviJ7gXv8p6F6hG0X0iw"
      }
    )
      .subscribe((res: any) => {
        if (res.pnr) {
          this.bookingResponse.PaymentStatus = res.payment_status
          this.bookingResponse.seatAssigned = res.seatAssigned
          if (res.payment_status === 'paid') {
            this.globalService.getBookingResponse().PaymentStatus = 'paid'
            this.globalService.setBookingResponse(this.bookingResponse)
            this.globalService.setSelectedBookedFlight(this.selectedFlight)
            if (this.pnrInfoInterval) {
              clearInterval(this.pnrInfoInterval)
            }

            if (res.tickets && res.tickets.length > 0 && res.tickets[0].status === 'ticketed' && res.tickets[0].number && res.tickets[0].number.length > 0) {
              this.globalService.setTickets(res.tickets[0].number)
              this.tickets = res.tickets[0].number;
              this.issuingTicket = false;
              this.toastr.warning('Ticket is already issued before, You can print the e-Ticket now');
            } else {
              this.doIssueTicket(issueParams)
            }
          }
        }
      }, err => {
        this.checkingStatus = false
        this.issuingTicket = false;
      })

  }

  doIssueTicket(issueParams) {
    this.uapiService.issueTicket(issueParams).toPromise()
      .then((res: any) => {
        let data = res.data["air:ETR"]
        if (!data['air:AirReservationLocatorCode']) {
          Object.keys(data).map(key => {
            let obj = data[key]
            let travelerObj = obj['common_v47_0:BookingTraveler']
            let firstName = travelerObj[Object.keys(travelerObj)[0]]['common_v47_0:BookingTravelerName']['First']
            let lastName = travelerObj[Object.keys(travelerObj)[0]]['common_v47_0:BookingTravelerName']['Last']
            let ticketObj = obj['air:Ticket']
            let ticketNumber = ticketObj[Object.keys(ticketObj)[0]]['TicketNumber']
            let ticketStatus = ticketObj[Object.keys(ticketObj)[0]]['TicketStatus']
            if (ticketNumber !== "") {
              this.tickets.push({
                'name': firstName + ' ' + lastName,
                'number': ticketNumber
              })
            }
          })
        } else {
          let obj = data;
          let travelerObj = obj['common_v47_0:BookingTraveler']
          let firstName = travelerObj[Object.keys(travelerObj)[0]]['common_v47_0:BookingTravelerName']['First']
          let lastName = travelerObj[Object.keys(travelerObj)[0]]['common_v47_0:BookingTravelerName']['Last']
          let ticketObj = obj['air:Ticket']
          let ticketNumber = ticketObj[Object.keys(ticketObj)[0]]['TicketNumber']
          let ticketStatus = ticketObj[Object.keys(ticketObj)[0]]['TicketStatus']
          this.tickets.push({
            'name': firstName + ' ' + lastName,
            'number': ticketNumber
          })
        }
        this.globalService.setTickets(this.tickets)
        this.issuingTicket = false;

        this.saveTicket()
      })
      .catch(err => this.issuingTicket = false)
  }

  saveTicket() {

    this.bookingResponse.Ticket = {
      'status': 'ticketed',
      'number': this.tickets,
    }

    this.uapiService.saveTicket(this.bookingResponse).toPromise()
      .then(res => {
        this.toastr.success('Ticket Sent Succcessfully');
      }).catch(e => {
        this.toastr.warning('Unable to Send Ticket');
      })
  }

  print(): void {
    // let printContents = document.getElementById('print-section').innerHTML;
    // let myWindow=window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    // myWindow.document.write("<p>This is 'myWindow'</p>");
    // myWindow.document.write(`
    //   <html>
    //     <head>
    //       <title>Print tab</title>
    //       <style>
    //       //........Customized style.......
    //       </style>
    //     </head>
    //     <body onload="window.print();window.close()">${printContents}</body>
    //   </html>`
    // );

    // myWindow.document.close();
    // myWindow.focus();
    // myWindow.print();
    // //myWindow.close();
    // var printContents = document.getElementById('print-section').innerHTML;
    var height = window.screen.height;
    var width = window.screen.width;
    var myWindow = window.open('', '', `top=0,left=0,height=${height},width=${width}`);
    myWindow.document.write(
      `
      <html>
        <head></head>
        <body>
          <table class="table ticket-table">
            <thead>
              <tr>
                <th scope="col">Passengers</th>
                <th scope="col">Ticket Number</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mikiyas Belayneh Maru</td>
                <td>121212121212212</td>
                <td><i class="fa fa-print"></i></td>
              </tr>
              <tr>
                <td>Mikiyas Belayneh Maru</td>
                <td>121212121212212</td>
                <td><i class="fa fa-print"></i></td>
              </tr>
            </tbody>
          </table>
        </body>
      </html>
      `
    );
    // myWindow.document.write(`
    //   <html>
    //     <head>
    //       <title>Print tab</title>
    //     </head>
    //     <body onload="window.print();window.close()">${printContents}</body>
    //   </html>`
    // );

    myWindow.document.close();
    myWindow.focus();
    myWindow.print();

  }

  checkStatus() {
    this.checkingStatus = true;
    this.pnrInfo()
  }

  pnrInfo() {
    return this.uapiService.pnrInfo(
      {
        "pnr": this.bookingResponse.BookingResponse ? this.bookingResponse.BookingResponse.pnr : this.bookingResponse.pnr,
        "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiQkFOS09GQUJZU1NJTklBIiwicGFzc3dvcmQiOiJxVHRScmU0UGlpcTVaSEdER2VvdVhTRDdZeW1weThJWXlFY1ZYc0tlcThOZ3ptS0E2ajh1SERzRWE5S1IiLCJpYXQiOjE1MTYyMzkwMjJ9.TKr6LzsaERAPbNAbFW2EYP1jhov6T6DYHfqKGQsp-7OZXfjI4O8aXhRklrnlybpguspviJ7gXv8p6F6hG0X0iw"
      }
    )
      .subscribe((res: any) => {
        if (res.pnr) {
          this.bookingResponse.PaymentStatus = res.payment_status
          this.bookingResponse.seatAssigned = res.seatAssigned
          if (res.payment_status === 'paid') {
            this.globalService.getBookingResponse().PaymentStatus = 'paid'
            this.globalService.setBookingResponse(this.bookingResponse)
            this.globalService.setSelectedBookedFlight(this.selectedFlight)
            if (this.pnrInfoInterval) {
              clearInterval(this.pnrInfoInterval)
            }
          }
        }
        this.checkingStatus = false;
      }, err => this.checkingStatus = false)
  }

  preview(index = null) {
    return this.uapiService.pnrInfo(
      {
        "pnr": this.bookingResponse.BookingResponse ? this.bookingResponse.BookingResponse.pnr : this.bookingResponse.pnr,
        "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiQkFOS09GQUJZU1NJTklBIiwicGFzc3dvcmQiOiJxVHRScmU0UGlpcTVaSEdER2VvdVhTRDdZeW1weThJWXlFY1ZYc0tlcThOZ3ptS0E2ajh1SERzRWE5S1IiLCJpYXQiOjE1MTYyMzkwMjJ9.TKr6LzsaERAPbNAbFW2EYP1jhov6T6DYHfqKGQsp-7OZXfjI4O8aXhRklrnlybpguspviJ7gXv8p6F6hG0X0iw"
      }
    )
      .subscribe((res: any) => {
        if (res.pnr) {
          this.bookingResponse.PaymentStatus = res.payment_status
          this.bookingResponse.BranchName = res.branch_name
          this.bookingResponse.seatAssigned = res.seatAssigned
          if (res.payment_status === 'paid') {
            this.globalService.getBookingResponse().PaymentStatus = 'paid'
            this.globalService.setBookingResponse(this.bookingResponse)
            this.globalService.setSelectedBookedFlight(this.selectedFlight)
            if (this.pnrInfoInterval) {
              clearInterval(this.pnrInfoInterval)
            }
            window.open('/preview/' + (index ? index : ''))
          }
        }
        this.checkingStatus = false;
      }, err => this.checkingStatus = false)
    // this.router.navigate(['/artboard/'+index], {
    // });
  }
}
