<img src="../../../../assets/gif/loading.gif" *ngIf="!fareRulesLoaded && !loaded && valid" alt="loading fare rules">

<div class="container preview-body" *ngIf="loaded">
    <div class="card" *ngIf="valid && loaded && fareRulesLoaded && bookingResponse.TotalPrice">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 logo-preview">
                    <img src="./assets/params/images/logo/guzogo_icon.png" width="100" height="65" alt="">
                    <span class="guzogo-logo-text">Guzogo Flight Ticket</span>
                    <img src="./assets/params/images/logo/boa_logo.png" width="170" height="65" alt="">
                </div>
                <div class="col-md-9">
                    <div class="logo-sub-box">
                    </div>
                </div>
            </div>
            <div class="row col-md-12 text-center">
                <h5 *ngIf="bookingResponse.BranchName">Branch Name: <span>{{ bookingResponse.BranchName }}</span></h5>
            </div>
            <div class="row pnr-label-box">
                <div class="col-md-4">
                    <span class="pnr-label-1" *ngIf="valid && loaded && fareRulesLoaded ">PNR:<span
                        class="pnr-value-1">{{ bookingResponse.BookingResponse.pnr }}</span> </span>
                </div>
            </div>
            <div class="row col-md-12 vendor">
                <div>
                    <span class="pnr-label-1" *ngIf="valid && loaded && fareRulesLoaded ">Airline / Vendor: <span
                        class="pnr-value-1">{{ getCarrierName(bookingResponse.airlineLocatorInfo[0].supplierCode) }}</span></span>
                </div>
                <div>
                    <span *ngIf="valid && loaded && fareRulesLoaded ">Vendor Locator:
                        <span>{{ bookingResponse.airlineLocatorInfo[0].locatorCode }}</span></span>
                </div>
            </div>
            <div class="row ticket-box">
                <div class="col-md-12">
                    <table id="print-section" class="table ticket-table">
                        <thead>
                            <tr>
                                <th scope="col">Passengers</th>
                                <th scope="col">Ticket Number</th>
                                <th scope="col" *ngFor="let segment of bookingResponse.BookingResponse.segments">Seat
                                    for<br />{{ segment.from }} -
                                    {{ segment.to }}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="valid && loaded && fareRulesLoaded ">
                            <tr *ngFor="let ticket of tickets; let i = index;">
                                <td>{{ ticket.name | uppercase }}</td>
                                <td class="ticket-number">{{ ticket.number }}</td>
                                <td *ngFor="let segment of bookingResponse.BookingResponse.segments; let k = index;">
                                    {{ (bookingResponse.Passengers[i] && bookingResponse.Passengers[i].seatAssigned) ? (bookingResponse.Passengers[i].seatAssigned[k] ? bookingResponse.Passengers[i].seatAssigned[k].seat : '-') : '-'  }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="valid && loaded && fareRulesLoaded">
                <div *ngFor="let direction of directions; let j = index">
                    <div class="card segment-card" *ngIf="direction.segments">
                        <div class="card-body" *ngFor="let segment of direction.segments; let i = index;">
                            <div class="row">
                                <div class="col-md-3">
                                    <img [src]='"https://pics.avs.io/200/50/"+ segment.airline +".png"' width="137"
                                        height="57" alt=""><br>
                                    <div class="airline-name-box">
                                        <span class="airline-name">{{ getCarrierName(segment.airline) }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3 flight-info-box">
                                    <div>
                                        <span class="location-info">{{ getAirportFullName(segment.from) }}</span>
                                    </div>
                                    <div>
                                        <span class="time-info">
                                            <span
                                                class="time-info-1">{{ formatGregorianTime(segment.departure) |  date: 'EEE, MMM d, yyyy'  }}</span>
                                            <br>
                                            <span class="flight-times-1">
                                                {{ formatGregorianTime(segment.departure) | date: 'HH:mm' }}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            class="time-info-1" >{{ showLocalTime(utilService.getEthiopianDate(segment.departure), segment.from) }}</span>
                                        <br>
                                        <span class="flight-times-1" >
                                            {{ showLocalTime(utilService.formatEthiopiaTime(segment.departure),segment.from) }}</span>
                                    </div>
                                </div>
                                <div class="col-md-2 flight-info-box">
                                    <div class="plane-box">
                                        <i class="fa fa-plane plan-icon">
                                            <br>
                                            {{ utilService.getInt(segment.duration[0]/60) }}hr
                                            {{ utilService.getDecimals(segment.duration[0]) > 0 ? utilService.getDecimals(segment.duration[0]) + 'm' : '' }}
                                        </i>
                                    </div>
                                </div>
                                <div class="col-md-3 flight-info-box">
                                    <div>
                                        <span class="location-info">{{ getAirportFullName(segment.to) }}</span>
                                    </div>
                                    <div>
                                        <span class="time-info">
                                            <span
                                                class="time-info-1">{{ formatGregorianTime(segment.arrival) | date: 'EEE, MMM d, yyyy' }}</span>
                                            <br>
                                            <span class="flight-times-1">
                                                {{ formatGregorianTime(segment.arrival) | date: 'HH:mm' }}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            class="time-info-1" >{{ showLocalTime(utilService.getEthiopianDate(segment.arrival), segment.to) }}</span>
                                        <br>
                                        <span
                                            class="flight-times-1" >{{ showLocalTime(utilService.formatEthiopiaTime(segment.arrival), segment.to) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span>Baggage fee FREE up to:
                                    <b>{{ bookingResponse.BookingResponse.fareQuotes[0].pricingInfos[0].baggage[i].amount }}
                                        {{ bookingResponse.BookingResponse.fareQuotes[0].pricingInfos[0].baggage[i].units }}</b></span>
                            </div>
                            <div class="row" *ngIf="getTransitTime(i, j)">
                                <div class="col-md-12 segment-box">
                                    <span>TRANSIT TIME - <b>{{ getTransitTime(i, j) }}</b> at
                                        <b>{{ getAirportFullName(segment.to) }}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card segment-card" *ngIf="!direction.segments">
                        <div class="card-body" *ngFor="let segment of direction; let i = index;">
                            <div class="row">
                                <div class="col-md-3">
                                    <img [src]='"https://pics.avs.io/200/50/"+ segment.airline +".png"' width="137"
                                        height="57" alt=""><br>
                                    <div class="airline-name-box">
                                        <span class="airline-name">{{ getCarrierName(segment.airline) }}</span>
                                    </div>
                                </div>
                                <div class="col-md-3 flight-info-box">
                                    <div>
                                        <span class="location-info">{{ getAirportFullName(segment.from) }}</span>
                                    </div>
                                    <div>
                                        <span class="time-info">
                                            <span
                                                class="time-info-1">{{ formatGregorianTime(segment.departure) | date: 'EEE, MMM d, yyyy' }}</span>
                                            <br>
                                            <span class="flight-times-1">
                                                {{ formatGregorianTime(segment.departure) | date: 'HH:mm' }}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            class="time-info-1">{{ showLocalTime(utilService.getEthiopianDate(segment.departure), segment.from) }}</span>
                                        <br>
                                        <span class="flight-times-1">
                                            {{ showLocalTime(utilService.formatEthiopiaTime(segment.departure), segment.from) }}</span>
                                    </div>
                                </div>
                                <div class="col-md-2 flight-info-box">
                                    <div class="plane-box">
                                        <i class="fa fa-plane plan-icon">
                                            <br>
                                            {{ utilService.getInt(segment.duration[0]/60) }}hr
                                            {{ utilService.getDecimals(segment.duration[0]) > 0 ? utilService.getDecimals(segment.duration[0]) + 'm' : '' }}
                                        </i>
                                    </div>
                                </div>
                                <div class="col-md-3 flight-info-box">
                                    <div>
                                        <span class="location-info">{{ getAirportFullName(segment.to) }}</span>
                                    </div>
                                    <div>
                                        <span class="time-info">
                                            <span
                                                class="time-info-1">{{ formatGregorianTime(segment.arrival) | date: 'EEE, MMM d, yyyy' }}</span>
                                            <br>
                                            <span class="flight-times-1">
                                                {{ formatGregorianTime(segment.arrival) | date: 'HH:mm' }}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            class="time-info-1">{{ showLocalTime(utilService.getEthiopianDate(segment.arrival), segment.to) }}</span>
                                        <br>
                                        <span
                                            class="flight-times-1">{{ showLocalTime(utilService.formatEthiopiaTime(segment.arrival), segment.to) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span>Baggage fee FREE up to:
                                    <b>{{ bookingResponse.BookingResponse.fareQuotes[0].pricingInfos[0].baggage[i].amount }}
                                        {{ bookingResponse.BookingResponse.fareQuotes[0].pricingInfos[0].baggage[i].units }}</b></span>
                            </div>
                            <div class="row" *ngIf="getTransitTime(i, j)">
                                <div class="col-md-12 segment-box">
                                    <span>TRANSIT TIME - <b>{{ getTransitTime(i, j) }}</b> at
                                        <b>{{ getAirportFullName(segment.to) }}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-10" *ngIf="valid && loaded && fareRulesLoaded ">
                    <p><b>Check-in time: </b> {{ getCheckInTime() | date: 'MMM d, y, HH:mm' }}</p>
                </div>
                <div class="col-md-10" id="fare-rules" *ngIf="fareRulesLoaded && fareRules">
                    <span *ngIf="fareRules.outbound.length !== 0"><b>Fare Rules:</b></span>
                    <p *ngIf="fareRules.outbound.length !== 0">{{ fareRules.outbound[0].split('--------------------------------------------------')[0] }}</p>
                    <p *ngIf="fareRules.inbound.length !== 0">{{ fareRules.inbound[0].split('--------------------------------------------------')[0] }}</p>
                </div>
                <div class="row">
                    <div class="col-md-12 total-box">
                        <span id="print-help">
                            <i class="fa fa-question-circle help-icon"></i>
                            <span class="help-text">Please press Ctrl+P to print this page</span>
                        </span>
                        <span>Total Price: <span
                                id="total-price">{{ bookingResponse.TotalPrice.substring(3) | currency: 'ETB ' }}</span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="(!valid && loaded) || (loaded && !bookingResponse.TotalPrice)" class="invalid-box">
        <div class="row">
            <div class="col-md-12 logo-preview">
                <img src="./assets/params/images/logo/guzogo_icon.png" routerLink='/' width="100" height="65" alt="">
                <span class="guzogo-logo-text">Guzogo Flight Ticket</span>
                <img src="./assets/params/images/logo/boa_logo.png" width="170" height="65" alt="">
            </div>
            <div class="col-md-9">
                <div class="logo-sub-box">
                </div>
            </div>
        </div>
        <div class="col">
            <h5 *ngIf="loaded && !samePNR">Please <a (click)="navigateToOpenPNR(bookingResponse.BookingResponse.pnr)">open PNR
                <b>{{ bookingResponse.BookingResponse.pnr }}</b></a> again and continue</h5>
            <h5 *ngIf="loaded && !bookingResponse.TotalPrice && samePNR">Sorry, something went wrong.</h5>
            <h5 *ngIf="loaded && !bookingResponse.TotalPrice && samePNR">Please check again on the previous window</h5>
            <button class="btn" style="border: solid 1px;
                    margin-top: 40px;
                    width: 150px;
                    height: 50px;" routerLink="/"><i class="fa fa-angle-left"></i><span class="edit-text">Go
                    Home</span></button>
        </div>
    </div>
</div>