<app-header></app-header>
<div class="container">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 title-section">
                    <span class="seat-label">Seat Selection</span>
                    <button class="btn choose-seat" (click)="cancel()"
                        *ngIf="!seatsLoaded || seatsFetchFailure">CANCEL</button>
                    <span type="button" *ngIf="seatsLoaded && !seatsFetchFailure" (click)="goPassengerDetail()"
                        class="btn next-btn">{{'Home.done' | translate}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="card legend-box">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="legend-title">Legend</span>
                                </div>
                            </div>
                            <div class="row legend-content-box">
                                <div class="col-md-12 d-flex flex-row">
                                    <img src="../assets/params/images/logo/chair_active.png" width="20" height="20"
                                        alt="">
                                    <span class="legend-content">Available</span>
                                </div>
                            </div>
                            <div class="row legend-content-box">
                                <div class="col-md-12">
                                    <img src="../assets/params/images/logo/chair_active_leg.png" width="20" height="20"
                                        alt="">
                                    <span class="legend-content">Extra Leg Room</span>
                                </div>
                            </div>

                            <div class="row legend-content-box">
                                <div class="col-md-12">
                                    <img src="../assets/params/images/logo/blocked_chair.png" width="20" height="20"
                                        alt="">
                                    <span class="legend-content">Not Available</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <img src="../assets/gif/loading.gif" *ngIf="!seatsLoaded && !seatsFetchFailure" alt="">
                    <div class="row" *ngIf="seatsLoaded">

                        <div class="col-md-10" *ngIf="passengers">
                            <div class="card table-card">
                                <div class="card-body">
                                    <table class="table table-borderless table-custom">
                                        <thead>
                                            <tr>
                                                <th scope="col">{{'Home.passengers' | translate}}</th>
                                                <th *ngFor="let segment of segments" scope="col">
                                                    {{ segment.from }} - {{ segment.to }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let passenger of passengers; let i = index;">
                                                <td *ngIf="passenger.ageCategory !== 'INF'"
                                                    style="text-transform :uppercase;">{{ passenger.firstName }} {{
                                                    passenger.middleName }}
                                                    {{ passenger.lastName }}</td>
                                                <td *ngFor="let segment of segments; let k = index">
                                                    <div class="chair-cell-1" *ngIf="passenger.ageCategory !== 'INF'">
                                                        <span class="chair-cell-text"
                                                            *ngIf="passenger.seatAssigned && passenger.seatAssigned[k]">{{
                                                            passenger.seatAssigned[k].seat }}</span>
                                                        <span class="chair-cell-text" *ngIf="!passenger.seatAssigned"> -
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <mat-tab-group>
                            <mat-tab *ngFor="let segment of segments; let k = index;"
                                [label]="segment.from + ' - ' + segment.to">
                                <div>
                                    <div class="card custom-border">
                                        <div class="card-body">
                                            <div class="d-flex flex-row justify-content-center align-items-center">
                                                <div class="d-flex flex-row location">A</div>
                                                <div class="d-flex flex-row location">C</div>
                                                <div class="d-flex flex-row location">G</div>
                                                <div class="d-flex flex-row location">J</div>
                                            </div>
                                            <div *ngFor="let item of bseats[k]; let i = index"
                                                class="d-flex flex-row justify-content-center align-items-center">
                                                <div *ngFor="let seat of item.seats; let j = index;" class="business">
                                                    <div *ngIf="seat.Type == 'Aisle'"
                                                        class="asile d-flex flex-row col-md-1"
                                                        [mdePopoverTriggerFor]="popover" mdePopoverTriggerOn="false">
                                                        <span *ngIf="item.rowNum < 10">&nbsp;</span>{{item.rowNum}}
                                                        <span *ngIf="item.rowNum < 10">&nbsp;</span>
                                                    </div>
                                                    <div *ngIf="(seat.Type == 'Seat') && (seat.Availability == 'Available') && !seat.passenger"
                                                        class="seat-chair d-flex flex-row"
                                                        [mdePopoverTriggerFor]="popover" mdePopoverTriggerOn="click"
                                                        (click)="setSelectedSeat(seat)">
                                                        <i *ngFor="let passenger of passengers">
                                                            <span
                                                                *ngIf="passenger.seatAssigned && passenger.seatAssigned[k] && passenger.seatAssigned[k].seat === seat.SeatCode">
                                                                <img class="active-indicator-img"
                                                                    src="../assets/params/images/logo/selected_active_chair.png"
                                                                    width="30" height="30" alt="">
                                                                <span>
                                                                    {{ passenger.firstName.substring(0, 1) }}
                                                                    {{ passenger.lastName.substring(0, 1) }}</span>
                                                            </span>
                                                        </i>
                                                        <img src="../assets/params/images/logo/chair_active.png"
                                                            width="30" height="30" alt="">
                                                    </div>

                                                    <div *ngIf="(seat.Type == 'Seat') && (seat.Availability == 'Available') && seat.passenger"
                                                        class="seat-chair d-flex flex-row"
                                                        [mdePopoverTriggerFor]="popover" mdePopoverTriggerOn="click"
                                                        (click)="setSelectedSeat(seat)"><img
                                                            src="../assets/params/images/logo/selected_active_chair.png"
                                                            width="30" height="30" alt=""><span>{{
                                                            seat.passenger.firstName.substring(0, 1) }}
                                                            {{ seat.passenger.lastName.substring(0, 1) }}</span></div>
                                                    <div *ngIf="(seat.Type == 'Seat') && (seat.Availability != 'Available')"
                                                        class="seat-chair d-flex flex-row"
                                                        [mdePopoverTriggerFor]="popover" mdePopoverTriggerOn="click"
                                                        (click)="setSelectedSeat(seat)"><img
                                                            src="../assets/params/images/logo/blocked_chair.png"
                                                            width="30" height="30" alt=""></div>
                                                    <span>{{increase()}}</span>
                                                    <mde-popover #popover="mdePopover"
                                                        [mdePopoverOverlapTrigger]="false"
                                                        [mdePopoverCloseOnClick]="false">
                                                        <mat-card class="popover-card">
                                                            <mat-card-content *ngIf="selectedSeat">
                                                                <div class="row popover-title">
                                                                    <div class="col-md-12">
                                                                        <span
                                                                            class="popover-title-text">{{selectedSeat.SeatCode}}</span>
                                                                    </div>
                                                                </div>
                                                                <table class="table table-borderless table-custom">
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let passenger of passengers; let l = index;">
                                                                            <td
                                                                                *ngIf="!passenger.seatAssigned || !passenger.seatAssigned[k] && passenger.ageCategory !== 'INF'">
                                                                                <div *ngIf="passenger.ageCategory !== 'INF'"
                                                                                    class="col-md-2"><i
                                                                                        class="fa fa-expand empty-chair"></i>
                                                                                </div>
                                                                            </td>
                                                                            <td
                                                                                *ngIf="passenger.seatAssigned && passenger.seatAssigned[k] && passenger.ageCategory !== 'INF'">
                                                                                <div class="col-md-2"><img
                                                                                        src="../assets/params/images/logo/selected_active_chair.png"
                                                                                        width="40" height="40" alt="">
                                                                                </div>
                                                                            </td>
                                                                            <td *ngIf="passenger.ageCategory !== 'INF'">
                                                                                <span class="passenger-name">
                                                                                    {{ passenger.firstName }}
                                                                                    {{ passenger.middleName }}
                                                                                    {{ passenger.lastName }}</span>
                                                                            </td>
                                                                            <td
                                                                                *ngIf="!passenger.seatAssigned || !passenger.seatAssigned[k] && passenger.ageCategory !== 'INF'">
                                                                                <input
                                                                                    *ngIf="passenger.ageCategory !== 'INF'"
                                                                                    type="button" class="btn take-seat"
                                                                                    value="Take Seat"
                                                                                    (click)="takeSeat(l, seat, segment.from, k)">
                                                                            </td>
                                                                            <td
                                                                                *ngIf="passenger.seatAssigned && passenger.seatAssigned[k] && passenger.ageCategory !== 'INF'">
                                                                                <input
                                                                                    *ngIf="passenger.ageCategory !== 'INF'"
                                                                                    type="button"
                                                                                    class="btn change-seat"
                                                                                    value="Change Seat"
                                                                                    (click)="takeSeat(l, seat, segment.from, k)">
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div class="row">
                                                                    <div class="col-md-12 close-btn">
                                                                        <input type="button" class="btn" value="close"
                                                                            (click)="closePopover()">
                                                                    </div>
                                                                </div>
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </mde-popover>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div *ngIf="seatsFetchFailure">No Seat Selection Available</div>
                </div>
            </div>
        </div>
    </div>
</div>