import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';



@Injectable({
  providedIn: 'root'
})
export class UapiService {

  // private url = 'https://sandbox.guzogo.com/api/u';
  // private ticketUrl = 'https://sandbox.guzogo.com/api/t';
  // private baseURL= 'https://sandbox.guzogo.com/api/u/';

  // private url = 'http://localhost:3000/api/b';
  // private ticketUrl = 'http://localhost:3800/api/t';
  // private baseURL= 'http://localhost:3800/api/u';

  private baseURL = 'https://uhdhFmyLNt.guzogo.com/api/u/';
  private url = 'https://uhdhFmyLNt.guzogo.com/api/b/';
  private ticketUrl = 'https://uhdhFmyLNt.guzogo.com/api/t';

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  public postShoppingList(data) {
    return this.httpClient.post(this.url + '/shopping', data, {});
  }
  public getExchangeRate(data) {
    return this.httpClient.post(this.url + '/exchange', data, {});
  }

  public checkCSPaymentStatus(data) {
    return this.httpClient.post(`https://bmsthyhuxretxtw.guzogo.com/cybersource/payment/payment_stat.php`, data, {});
  }

  public CSPaymentStatus(data) {
    return this.httpClient.post('https://bmsthyhuxretxtw.guzogo.com/cybersource/sa-sop/payment_stat.php', data, {});

  }
  public updatePaymentStatus(data) {
    return this.httpClient.post(`https://agentportal.guzogo.com/api/u/pnr_portal/updatePNRPaymentStatus/`, JSON.stringify(data), {})

  }
  public getSeatMap(params) {
    return this.httpClient.post(this.url + '/seatmap', params, {});
  }

  public postBooking(params) {
    return this.httpClient.post(this.url + '/booking', params, {});
  }

  public postQueue(params, persistParams) {
    return this.httpClient.post(this.url + '/queue/', {
      'params': params,
      'persistParams': persistParams
    }, {});
  }

  public issueTicket(params) {
    // return this.httpClient.post("http://172.31.30.127:5000/api/u" + "/issueTicket", params, {});
    return this.httpClient.post(this.ticketUrl + "/issueTicket", params, {});
    // return this.httpClient.get('assets/json/ticketResp.json');
  }

  public saveTicket(body) {

    // return this.httpClient.post( "http://172.31.30.127:5000/api/u" + '/ticket', body)
    return this.httpClient.post(this.ticketUrl + '/ticket', body)
  }

  public postUniversalRecordByPNR(params) {
    return this.httpClient.post(this.url + '/ur-with-pnr', params, {})
  }

  public postUniversalRecordByTravelerName(params) {
    return this.httpClient.post(this.url + '/ur-with-traveler-name', params, {})
  }

  public postUniversalRecordByTicket(params) {
    return this.httpClient.post(this.url + '/ur-with-ticket', params, {})
  }

  public pnrInfo(params) {
    return this.httpClient.post(this.url + '/pnrinfo', params, {})
  }

  public getHistory() {
    return this.httpClient.post(this.url + '/history', this.authService.getAuthUser(), {})
  }

  public getFareRules(params) {
    return this.httpClient.post(this.url + '/fareinfo', params, {})
  }

  public getPCRPackage() {
    return this.httpClient.post(this.url + '/packagePCRPackageInfo', this.authService.getAuthUser(), {})
  }

  public getKulbiGebrielPackage() {
    return this.httpClient.post(this.url + '/packageKulbiGebriel', this.authService.getAuthUser(), {})
  }

  public postTelebirrPayment(params) {
    return this.httpClient.post('https://sandbox.guzogo.com/api/v2/webhook/v2/telebirr/post', params, {})
  }

}

