<h2 mat-dialog-title>Fare Rules</h2>
<mat-dialog-content class="mat-typography">
    <div *ngIf="loadingFareRules">
        <img src="../assets/gif/loading.gif" width="75px" alt="">
    </div>
    <div class="content" *ngIf="noFareInfo && !loadingFareRules">
        <p>Fare rules could not be fetched</p>
    </div>
    <div *ngIf="!loadingFareRules && fareRules.outbound && !noFareInfo" class="content">
        <h3><b>Outbound Flight</b></h3>
        <h4 *ngIf="outboundSegmentDetail.meals" ><b><i style='font-size:24px' class='fas'>&#xf818;</i> Meals : </b> {{outboundSegmentDetail.meals}}</h4>
        <h4 *ngIf="outboundSegmentDetail.inFlightServices"><b><i class="fa fa-plane" aria-hidden="true"></i>  In-Flight Services : </b> <span *ngFor="let flightService of outboundSegmentDetail.inFlightServices">{{flightService}}</span> </h4>
        <div>
            <p><span><b><i class="fa fa-sticky-note" aria-hidden="true"></i> Fare Rule : </b></span>{{fareRules.outbound}}</p>
        </div>
        <hr *ngIf="fareRules.inbound && inboundSegmentDetail">
        <div  *ngIf="fareRules.inbound && inboundSegmentDetail">
            <h3><b>Return Flight</b></h3>
            <h4 *ngIf="inboundSegmentDetail.meals" ><b><i style='font-size:24px' class='fas'>&#xf818;</i> Meals : </b> {{inboundSegmentDetail.meals}}</h4>
            <h4 *ngIf="inboundSegmentDetail.inFlightServices"><b><i class="fa fa-plane" aria-hidden="true"></i>  In-Flight Services : </b> <span *ngFor="let flightService of inboundSegmentDetail.inFlightServices">{{flightService}}</span> </h4>
            <div>
                <p><span><b><i class="fa fa-sticky-note" aria-hidden="true"></i> Fare Rule : </b></span>{{fareRules.inbound}}</p>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="dismiss()">Close</button>
</mat-dialog-actions>