<header>
    <!-- <nav class="navbar navbar-expand-md fixed-top navbar-dark custom-nav">
        <div class="container">
            <a class="navbar-brand" routerLink='/'>
                <img src="../assets/imgs/GuzoGo-Logo.png" alt="">
            </a>

            <div class="navbar-collapse">
              <p style="margin-right: 19px;
            font-size: 18px;
            color: #fff;"><i class="fa fa-phone-alt" aria-hidden="true"></i> Call us @ 7473/ whatsup</p>

            </div>


            <div class="collapse navbar-collapse" id="pnrSearchBar">

                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <p style="margin-right: 19px;
                        font-size: 18px;
                        color: #fff;"><i class="fa fa-phone-alt" aria-hidden="true"></i> Call us @ 7473/ whatsup</p>
                    </li>

                    <li class="nav-item header-calendar-selector-li">
                        <mat-form-field class="header-calendar-selector" routerLinkActive="header-calendar-selector-active" >
                            <mat-label>Calendar Type</mat-label>
                            <mat-select [(ngModel)]="selectedCalendarType" (ngModelChange)='onCalendarTypeChange()'
                                [value]='selectedCalendarType'>
                                <mat-option value="EC">
                                    <img src="../assets/imgs/EthiopianFlag.jpg" width="15px" height="10px" alt="" srcset="">
                                    Ethiopian
                                </mat-option>
                                <mat-option value="GC">
                                    Gregorian
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </li>
                    <li class="nav-item header-calendar-selector-li">
                        <mat-form-field>
                            <mat-label>Language Type</mat-label>
                            <mat-select [(ngModel)]="selectedLanguageType" (ngModelChange)='onLanguageChange()'>
                                <mat-option value="en">
                                    English
                                </mat-option>
                                <mat-option value="am">
                                    Amharic
                                </mat-option>
                                <mat-option value="tg">
                                    Tigrigna
                                </mat-option>
                                <mat-option value="or">
                                    Afaan Oromoo
                                </mat-option>
                                <mat-option value="sm">
                                    Af-Soomaali
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </li>
                </ul>
            </div>


          </div>
    </nav> -->

    <nav class="navbar navbar-expand-md fixed-top navbar-dark custom-nav">


        <div class="container">
          <a class="navbar-brand" routerLink='/'>
              <img src="../assets/imgs/GuzoGo-Logo.png" alt="">
          </a>
        </div>

        <div class="header_social mr-16 flex justify-center items-center">
          <!-- <li> -->
            <img src="../assets/imgs/telegram-guzogo.png"  alt="" class="mr-2" />
            <img src="../assets/imgs/whatsapp-guzogo2.png"  alt="" class="mr-2"/>
            <a color="#fff" class="flex justify-center">
              <b>+251974092839</b>
            </a>
          <!-- </li> -->

          </div>

        <!-- <div class="d-flex  me-auto mb-2 mb-lg-0"> -->
    </nav>
    <br>
</header>
