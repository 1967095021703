import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from "moment";
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth_url = 'https://uhdhFmyLNt.guzogo.com/api/b';
  // private auth_url = 'http://172.31.30.127:3000/api/u';
  // private auth_url = 'http://localhost:3000/api/b';
  private jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public setSession(authResult) {
    const expiresAt = moment().add(authResult.expiresIn, 'second');
    this.storageService.secureStorage.setItem('token', authResult.token);
    this.storageService.secureStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
    this.storageService.secureStorage.setItem('auth_user', JSON.stringify({
      'email': authResult.user.email,
      'name': authResult.user.name,
      'role' : authResult.user.role
    }))
  }

  getAuthUser() {
    return JSON.parse(this.storageService.secureStorage.getItem('auth_user'))
  }

  logout() {
    this.storageService.secureStorage.removeItem("token");
    this.storageService.secureStorage.removeItem("expires_at");
    this.storageService.secureStorage.removeItem("auth_user");
  }
  register() {
    this.storageService.secureStorage.removeItem("token");
    this.storageService.secureStorage.removeItem("expires_at");
    this.storageService.secureStorage.removeItem("auth_user");
  }

  public isAuthenticated(): boolean {
    if(!localStorage.getItem('token')){
      return false
    }
    const token = this.storageService.secureStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  public authenticate(params) {
    return this.http.post(this.auth_url + '/auth', params, {})
  }

  public resetPassword(params) {
    return this.http.post(this.auth_url + '/resetpassword', params, {})
  }


}
