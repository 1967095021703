<div class="container login-container">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 offset-md-4 custom-logo-box">
                    <img src="./assets/params/images/logo/guzogo_icon.png" width="130" height="80" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 offset-md-4 login-label-box">
                    <span class="logon-label" *ngIf="!passwordExpired">GuzoGo Portal Login</span>
                    <span class="logon-label" *ngIf="passwordExpired">Reset Password</span>
                </div>
            </div>
            <div class="row justify-center" *ngIf="loginError">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong class="reservation-danger-text">Incorrect Username or Password</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="row" *ngIf="!passwordExpired">
                <div class="col-md-4 offset-md-4 login-input-box">
                    <div class="form-group login-btn-box">
                        <input name="email" [(ngModel)]="user.email" type="text" class="form-control login-input"
                            placeholder="Username">
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!passwordExpired">
                <div class="col-md-4 offset-md-4 login-input-box">
                    <div class="form-group login-btn-box">
                        <input name="password" (keyup.enter)="onLogin()" [(ngModel)]="user.password" type="password"
                            class="form-control login-input" placeholder="Password">
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!passwordExpired">
                <div class="col-md-4 offset-md-4">
                    <div class="form-group login-btn-box">
                        <a class="btn login-btn" (keyup.enter)="onLogin()" (click)='onLogin()' [ngClass]="{'disabled': loggingIn}">
                            <span>Login</span>
                        </a>
                        <br>
                        <img src="../assets/gif/loading.gif" width="75px" *ngIf="loggingIn" alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="row" *ngIf="!passwordExpired">
                <div class="col-md-4 offset-md-4">
                    <div class="form-group login-btn-box">
                        <a class="reset" (click)='toggleResetPassword()' [ngClass]="{'disabled': loggingIn}">
                            <span>Reset Password</span>
                        </a>
                    </div>
                </div>
            </div> -->
            <div class="row" id="reset-box" *ngIf="passwordExpired">
                <mat-horizontal-stepper #stepper>
                    <mat-step>
                        <ng-template matStepLabel>Email</ng-template>
                        <div class="row">
                            <div class="login-input-box">
                                <div class="form-group login-btn-box">
                                    <input name="email" [(ngModel)]="resetUser.email" type="text"
                                        class="form-control login-input" placeholder="Email">
                                </div>
                            </div>
                        </div>
                        <div class="next">
                            <button mat-button matStepperNext [disabled]="!resetUser.email">Next</button>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Current Password</ng-template>
                        <div class="alert alert-danger alert-dismissible fade show" *ngIf="!resetAuthenticated"
                            role="alert">
                            <strong class="reservation-danger-text">Incorrect Email or Password</strong>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="login-input-box">
                            <div class="form-group login-btn-box">
                                <label for="">{{ resetUser.email }}</label>
                                <input name="password" [(ngModel)]="resetUser.prev_password" type="password"
                                    class="form-control login-input" placeholder="Current Password">
                            </div>
                        </div>
                        <div class="next">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button (click)="authReset()" [disabled]="resetAuthenticating">Next</button>
                        </div>
                        <div class="row" *ngIf="resetAuthenticating">
                            <img src="../assets/gif/loading.gif" width="75px" alt="">
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>New Password</ng-template>
                        <div class="alert alert-success alert-dismissible fade show"
                            *ngIf="resetComplete && !resetError" role="alert">
                            <strong class="reservation-success-text">Password Reset Successfully</strong>
                            <button type="button" class="close reservation-close" data-dismiss="alert"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="alert alert-danger alert-dismissible fade show" *ngIf="resetError" role="alert">
                            <strong class="reservation-danger-text">Something went wrong. Please use a different
                                password</strong>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="login-input-box" *ngIf="resetAuthenticated">
                            <div class="form-group login-btn-box">
                                <input name="password" [(ngModel)]="resetUser.password" type="password"
                                    class="form-control login-input" placeholder="New Password">
                            </div>
                        </div>
                        <div class="login-input-box" *ngIf="resetAuthenticated">
                            <div class="form-group login-btn-box">
                                <input name="password" [(ngModel)]="resetUser.confirm_password" type="password"
                                    class="form-control login-input" placeholder="Confirm New Password">
                            </div>
                        </div>
                        <div class="next">
                            <button mat-button (click)="stepper.reset()">Back</button>
                            <button mat-button *ngIf="resetAuthenticated" [ngClass]="{'disabled': resetting || !resetUser.password || !resetUser.confirm_password || resetUser.password.length < 6
                                        || resetUser.confirm_password.length < 6
                                        || resetUser.password !== resetUser.confirm_password}" [disabled]="resetting || !resetUser.password || !resetUser.confirm_password || resetUser.password.length < 6
                                        || resetUser.confirm_password.length < 6
                                        || resetUser.password !== resetUser.confirm_password
                                        || resetComplete" id="reset-btn"
                                (click)="resetPassword()">Reset Password</button>
                        </div>
                        <div class="row" *ngIf="resetting">
                            <img src="../assets/gif/loading.gif" width="75px" alt="">
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
            <div class="form-group login-btn-box" *ngIf="passwordExpired">
                <a class="reset" (click)='toggleResetPassword()' [ngClass]="{'disabled': loggingIn}">
                    <span>Login</span>
                </a>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <img src="./assets/params/images/logo/backgound_plane.png" width="230" height="130" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 offset-md-8 login-bottom-logo-box">
                    <img src="./assets/params/images/logo/checkout_image.png" width="150" height="90" alt="">
                </div>
            </div>
        </div>
    </div>
</div>