import { Injectable } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage/storage.service';
@Injectable({
  providedIn: 'root'
})
export class TranslateUtilService {

  currentLanguage : string = "en"
  
  constructor(private translate: TranslateService, private storageService: StorageService) { }

  public switchLanguage(language: string) {
    this.currentLanguage = language
    this.translate.use(language);
    this.storageService.secureStorage.setItem('SELECTED_LANG', language)
  }

  public setDefaultLanguage(language : string){
    this.translate.setDefaultLang(language || this.currentLanguage);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
