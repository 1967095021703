import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './modules/general/about/about.component';
import { HomeComponent } from './modules/general/home/home.component';
import { SigninComponent } from './modules/general/signin/signin.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { SearchComponent } from './modules/general/search/search.component';
import { ArtboardComponent } from './modules/general/artboard/artboard.component';
import { CybersourceComponent } from './modules/general/cybersource/cybersource.component';
import { SuccessComponent } from './modules/general/success/success.component';
import { SeatComponent } from './modules/general/seat/seat.component';
import { PreviewComponent } from './modules/general/preview/preview.component';
import { AuthGuard } from './services/auth.guard';
import { RegisterComponent } from './modules/general/register/register.component';
import { BookingHistoryComponent } from './modules/general/booking-history/booking-history.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'about', component: AboutComponent, canActivate: [AuthGuard]  },
  // { path: 'signin', component: SigninComponent },
  // { path: 'register', component: RegisterComponent },
  { path: 'search', component: SearchComponent},
  { path: 'artboard', component: ArtboardComponent},
  { path: 'success', component: SuccessComponent  },
  { path: 'seat', component: SeatComponent  },
  // { path: 'preview/:id', component: PreviewComponent  }
  // { path: 'cybersource', component: CybersourceComponent, canActivate: [AuthGuard]  },
  // { path: 'preview', component: PreviewComponent  },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
