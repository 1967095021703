<mat-dialog-content class="mat-typography content">
    <div class="form-row">
        <div class="col-md-6">
            <h2 mat-dialog-title>
                <img src="assets/imgs/payment_gateways/Awash_Bank_Final_logo.jpg" alt="HelloCash">
            </h2>
        </div>
        <div class="col-md-6" *ngIf="step === 0">
            <div class="form-group">
                <label for="input-phone" class="form-label">Awash Bank Account Number</label>
                <input type="text" [(ngModel)]='awashBankAccount' name="input-phone" class="form-control input-size"
                    id="phone-number" placeholder="Enter Awash Bank account number">
            </div>
            <div class="form-group">
                <label for="input-phone" class="form-label">Full name</label>
                <input type="text" [(ngModel)]='awashFullName' name="input-phone" class="form-control input-size"
                    id="phone-number" placeholder="Enter account holder name">
            </div>
            <div class="form-group">
                <label for="input-phone" class="form-label">Phone Number</label>
                <input type="text" [(ngModel)]='awashPhoneNumber' name="input-phone" class="form-control input-size"
                    id="phone-number" placeholder="Enter phone number">
            </div>
            <p><strong>After choosing to continue, your flight should be booked and you should receive an OTP to your
                    phone number to complete your payment.</strong></p>
        </div>
        <div class="col-md-6" *ngIf="step === 1">
            <div class="form-group">
                <label for="otp" class="form-label">OTP</label>
                <input type="text" [(ngModel)]='otp' name="otp" class="form-control input-size" id="otp"
                    placeholder="e.g. 310..">
            </div>
            <p><strong>An OTP has been sent to the phone number {{ awashPhoneNumber }}. After confirming the OTP, your
                    payment should be completed and you should receive an eTicket shortly.</strong></p>
        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="step === 0" (click)="dismiss()">Close</button>
    <button *ngIf="step === 1" (click)="goBackStep()">Back</button>
      <button mat-stroked-button class="action-btn btn btn-warning search-btn-custom" (click)="continue()"
              [disabled]="!awashBankAccount || !awashPhoneNumber || awashPhoneNumber.length < 9 || loading">BOOK NOW</button>
  <img class="loading" src="../assets/gif/loading.gif" *ngIf="loading" alt="processing"><br />
</mat-dialog-actions>
