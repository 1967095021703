<app-header></app-header>
<div class="container home-whole-box">
    <div class="row justify-content-center">
        <div class="col-md-11 col-lg-11 col-xl-11 home-box">
            <mat-tab-group (selectedTabChange)="onTabChange($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">check_box</mat-icon>
                        <span class="tab-label">{{ 'Home.one-way' | translate }}</span>
                    </ng-template>
                    <div class="one-way">
                        <div class="row row-box ">
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <label for="fromlocation" class="form-custom-label">{{ 'Home.from' | translate
                                    }}</label>
                                <div class="form-input-box">
                                    <div class="input-icon-box">
                                        <img src="../assets/params/icons/plane_icon.png" class="flight-labels-img"
                                            width="20" height="20" alt="">
                                    </div>
                                    <ng2-completer [ngClass]="{'has-error': one_way_departure}"
                                        textNoResults="No Airport/City Found, Please Try Again" [autoMatch]="true"
                                        [pause]="500" type="text" [textSearching]="'Please wait...'"
                                        class="form-control input-box" id="fromlocation" [(ngModel)]="departureStr"
                                        [datasource]="dataService" [minSearchLength]="2"
                                        placeholder="{{ 'SearchR.choosed' | translate }}"
                                        (selected)="departureAirportSelected($event, false)"></ng2-completer>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <label for="tolocation" class="form-custom-label">{{ 'Home.to' | translate }}</label>
                                <div class="form-input-box">
                                    <div class="input-icon-box">
                                        <img src="../assets/params/icons/plane_icon.png" class="flight-labels-img"
                                            width="20" height="20" alt="">
                                    </div>
                                    <ng2-completer [ngClass]="{'has-error': one_way_arrival}"
                                        textNoResults="No Airport/City Found, Please Try Again" [pause]="500"
                                        type="text" [textSearching]="'Please wait...'" class="form-control input-box"
                                        id="fromlocation" [(ngModel)]="arrivalStr" [datasource]="dataService"
                                        [minSearchLength]="2" placeholder="{{ 'SearchR.chooser' | translate }}"
                                        (selected)="arrivalAirportSelected($event)"></ng2-completer>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row row-box ">
                            <label for="staticEmail"
                                class="col-md-2 col-lg-2 col-xl-2 col-form-label form-custom-label">{{ 'Profile.calendar' | translate }}</label>
                            <div class="col-md-3 col-lg-3 col-xl-3 form-custom-value">
                                <select class="form-control" id="exampleFormControlSelect1" name="calendarType"
                                    [(ngModel)]='calendarType' (ngModelChange)="onCalendarTypeChange(calendarType)">
                                    <option value="EC">Ethiopian Calendar</option>
                                    <option value="GC">Gregorian Calendar</option>
                                </select>
                            </div>
                        </div>
                        <div class="row row-box">
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <div class="form-input-box">
                                    <label for="fromlocation" [hidden]="calendarType == 'EC'" class="form-custom-label"
                                        id="">{{'Home.departuredate' | translate}} </label>
                                    <mat-form-field id="fromlocation" [hidden]="calendarType == 'EC'">
                                        <input matInput [matDatepicker]="picker1" placeholder="{{'Home.departuredate' | translate}}"
                                            readonly="readonly" (click)="picker1.open()" [(ngModel)]="departureDateGC"
                                            [min]="today">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <div [hidden]="calendarType == 'GC'" class="date-input-box">
                                        <label for="departure_date" class="form-custom-label"
                                            id="departure_date_label">{{ 'Home.departuredate' | translate }}</label>
                                        <div>
                                            <input id="daparture_date" (change)="onDateChange($event)"
                                                [(ngModel)]="departureDateEC" class="datepicker_Eth" name="date_from"
                                                [value]="today" />
                                            <br>
                                            <input class="greg_val" #departure_date name="departureDate" hidden>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row row-box ">
                            <label for="staticEmail" class="col-md-2 col-form-label form-custom-label">{{
                                'Home.passengers' | translate }}</label>
                            <div class="col-md-2 form-custom-value">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <img src="../assets/params/icons/man.png" class="man-icon" width="25"
                                                    height="25" alt="">
                                            </div>
                                            <div class="col-md-9">
                                                <select class="form-control select-custom"
                                                    id="exampleFormControlSelect1" [(ngModel)]="adultPassengers">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="age-text">(12+)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <img src="../assets/params/icons/man.png" class="child-icon" width="20"
                                                    height="20" alt="">
                                            </div>
                                            <div class="col-md-9">
                                                <select class="form-control select-custom"
                                                    id="exampleFormControlSelect1" [(ngModel)]="childPassengers">
                                                    <option>0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="age-text">(2-11)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <img src="../assets/params/icons/baby.png" class="child-icon" width="20"
                                                    height="20" alt="">
                                            </div>
                                            <div class="col-md-9">
                                                <select class="form-control select-custom"
                                                    id="exampleFormControlSelect1" [(ngModel)]="infantPassengers">
                                                    <option selected="selected">0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="age-text">(-2)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row row-box ">
                            <label for="staticEmail"
                                class="col-md-2 col-lg-2 col-xl-2 col-form-label form-custom-label">{{ 'Home.cabinclass'
                                | translate }}</label>
                            <div class="col-md-2 col-lg-2 col-xl-2">
                                <select #oneWayCabin class="form-control select-box"
                                    (change)="oneWayCabinOnChange(oneWayCabin.value)">
                                    <option *ngFor="let cabin of cabinClassAllowed" [value]="cabin">{{cabin}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="form-group row row-box ">
                            <label for="staticEmail"
                                class="col-md-2 col-lg-2 col-xl-2 col-form-label form-custom-label">{{ 'SearchR.airline'
                                | translate }}</label>
                            <div class="col-md-3 col-lg-3 col-xl-3">
                                <select class="form-control select-box" id="exampleFormControlSelect1"
                                    [(ngModel)]="filteredAirline">
                                    <option *ngFor="let airline of availAirlines" [ngValue]="airline.code"
                                        selected="airline.code === '-'">{{airline.name}}</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="row justify-content-end">
                            <a type="button" class="btn btn-warning search-btn-custom" (click)="search('one-way')">{{
                                'Home.search' | translate }}</a>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">check_box</mat-icon>
                        <span class="tab-label">{{ 'Home.round-trip' | translate }}</span>
                    </ng-template>

                    <div class="return">
                        <div class="row row-box ">
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <label for="fromlocation" class="form-custom-label">{{ 'Home.from' | translate
                                    }}</label>
                                <div class="form-input-box">
                                    <div class="input-icon-box">
                                        <img src="../assets/params/icons/plane_icon.png" class="flight-labels-img"
                                            width="20" height="20" alt="">
                                    </div>
                                    <ng2-completer [ngClass]="{'has-error': return_departure}"
                                        textNoResults="No Airport/City Found, Please Try Again" [autoMatch]="true"
                                        [pause]="500" type="text" [textSearching]="'Please wait...'"
                                        class="form-control input-box" id="fromlocation"
                                        [(ngModel)]="departureReturnStr" [datasource]="dataService"
                                        [minSearchLength]="2" placeholder="{{ 'SearchR.choosed' | translate }}"
                                        (selected)="departureAirportSelected($event, true)"></ng2-completer>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <label for="tolocation" class="form-custom-label">{{ 'Home.to' | translate }}</label>
                                <div class="form-input-box">
                                    <div class="input-icon-box">
                                        <img src="../assets/params/icons/plane_icon.png" class="flight-labels-img"
                                            width="20" height="20" alt="">
                                    </div>
                                    <ng2-completer [ngClass]="{'has-error': return_arrival}"
                                        textNoResults="No Airport/City Found, Please Try Again" [pause]="500"
                                        type="text" [textSearching]="'Please wait...'" class="form-control input-box"
                                        id="fromlocation" [(ngModel)]="arrivalReturnStr" [datasource]="dataService"
                                        [minSearchLength]="2" placeholder="{{ 'SearchR.chooser' | translate }}"
                                        (selected)="arrivalAirportSelected($event, true)"></ng2-completer>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row row-box ">
                            <label for="staticEmail"
                                class="col-md-2 col-lg-2 col-xl-2 col-form-label form-custom-label">{{'Profile.calendar' | translate}}</label>
                            <div class="col-md-3 col-lg-3 col-xl-3 form-custom-value">
                                <select class="form-control" id="exampleFormControlSelect1" name="calendarType"
                                    [(ngModel)]='calendarType'>
                                    <option value="EC">Ethiopian Calendar</option>
                                    <option value="GC">Gregorian Calendar</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="row row-box">
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <div class="form-input-box">
                                    <mat-form-field id="fromlocation_return">
                                        <input matInput [matDatepicker]="picker_return_departure"
                                            placeholder="{{'Home.departuredate' | translate}}" readonly="readonly"
                                            (click)="picker_return_departure.open()" [(ngModel)]="departureDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker_return_departure">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker_return_departure></mat-datepicker>
                                    </mat-form-field>

                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <div class="form-input-box">
                                    <mat-form-field id="to_location_return">
                                        <input matInput [matDatepicker]="picker_return_arrival"
                                            placeholder="{{'Home.returndate' | translate}}" readonly="readonly"
                                            (click)="picker_return_arrival.open()" [(ngModel)]="returnDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker_return_arrival">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker_return_arrival></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div> -->
                        <div class="row row-box">
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <div class="form-input-box">
                                    <label for="fromlocation" [hidden]="calendarType == 'EC'" class="form-custom-label"
                                        id="">{{'Home.departuredate' | translate}}</label>
                                    <mat-form-field id="fromlocation_return" [hidden]="calendarType == 'EC'">
                                        <input matInput [matDatepicker]="picker_return_departure" [min]="today"
                                            readonly="readonly" (click)="picker_return_departure.open()"
                                            [(ngModel)]="departureDateGC">
                                        <mat-datepicker-toggle matSuffix [for]="picker_return_departure">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker_return_departure></mat-datepicker>
                                    </mat-form-field>
                                    <div [hidden]="calendarType == 'GC'" class="date-input-box">
                                        <label for="departure_date_1" class="form-custom-label"
                                            id="departure_date_label">{{'Home.departuredate' | translate}}</label>
                                        <div>
                                            <input id="daparture_date_1" (change)="onDateChange($event)"
                                                class="datepicker_Eth_1" name="date_from" [value]="today" />
                                            <br>
                                            <input class="greg_val" #departure_date_1 name="departureDate" hidden>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <div class="form-input-box">
                                    <label for="fromlocation" [hidden]="calendarType == 'EC'" class="form-custom-label"
                                        id="">{{'Home.returndate' | translate}}</label>
                                    <mat-form-field id="to_location_return" [hidden]="calendarType == 'EC'">
                                        <input matInput [matDatepicker]="picker_return_arrival" readonly="readonly"
                                            [min]="departureDateGC" (click)="picker_return_arrival.open()"
                                            [(ngModel)]="returnDateGC">
                                        <mat-datepicker-toggle matSuffix [for]="picker_return_arrival">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker_return_arrival></mat-datepicker>
                                    </mat-form-field>
                                    <div [hidden]="calendarType == 'GC'" class="date-input-box">
                                        <label for="departure_date_2" class="form-custom-label"
                                            id="departure_date_label">{{'Home.returndate' | translate}}</label>
                                        <div>
                                            <input id="return_date" (change)="onDateChange($event)"
                                                class="datepicker_Eth_2" name="date_from" [value]="today" />
                                            <br>
                                            <input class="greg_val" #return_date name="returnDate" hidden>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row row-box ">
                            <label for="staticEmail" class="col-md-2 col-form-label form-custom-label">{{
                                'Home.passengers' | translate }}</label>
                            <div class="col-md-2 form-custom-value">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <img src="../assets/params/icons/man.png" class="man-icon" width="25"
                                                    height="25" alt="">
                                            </div>
                                            <div class="col-md-9">
                                                <select class="form-control select-custom"
                                                    id="exampleFormControlSelect1" [(ngModel)]="adultPassengers">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="age-text">(12+)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <img src="../assets/params/icons/man.png" class="child-icon" width="20"
                                                    height="20" alt="">
                                            </div>
                                            <div class="col-md-9">
                                                <select class="form-control select-custom"
                                                    id="exampleFormControlSelect1" [(ngModel)]="childPassengers">
                                                    <option>0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="age-text">(2-11)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <img src="../assets/params/icons/baby.png" class="child-icon" width="20"
                                                    height="20" alt="">
                                            </div>
                                            <div class="col-md-9">
                                                <select class="form-control select-custom"
                                                    id="exampleFormControlSelect1" [(ngModel)]="infantPassengers">
                                                    <option selected="selected">0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="age-text">(-2)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row row-box ">
                            <label for="staticEmail"
                                class="col-md-2 col-lg-2 col-xl-2 col-form-label form-custom-label">{{ 'Home.cabinclass'
                                | translate }}</label>
                            <div class="col-md-2 col-lg-2 col-xl-2">
                                <select #returnCabin class="form-control select-box"
                                    (change)="returnCabinOnChange(returnCabin.value)">
                                    <option *ngFor="let cabin of cabinClassAllowed" [value]="cabin">{{cabin}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="form-group row row-box ">
                            <label for="staticEmail"
                                class="col-md-2 col-lg-2 col-xl-2 col-form-label form-custom-label">{{ 'SearchR.airline'
                                | translate }}</label>
                            <div class="col-md-3 col-lg-3 col-xl-3">
                                <select class="form-control select-box" id="exampleFormControlSelect1"
                                    [(ngModel)]="filteredAirline">
                                    <option *ngFor="let airline of availAirlines" [ngValue]="airline.code"
                                        selected="airline.code === '-'">{{airline.name}}</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="row justify-content-end">
                            <button type="button" class="btn btn-warning search-btn-custom"
                                (click)="search('return')">{{ 'Home.search' | translate }}</button>
                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
