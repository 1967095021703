import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UapiService } from 'src/app/services/http/uapi.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  passwordExpired = false;
  loggingIn = false;
  user = {
    email: '',
    password: ''
  }
  resetUser = {
    email: '',
    password: '',
    confirm_password: '',
    prev_password: ''
  }
  resetAuthenticating = false;
  resetAuthenticated = true;
  resetError = false;
  resetting = false;
  resetComplete = false;
  loginError = false;
  @ViewChild('stepper') private stepper: MatStepper;

  constructor(private authService: AuthService, private router: Router, private cdRef: ChangeDetectorRef, ) {
    localStorage.setItem('login', 'false');
  }

  ngOnInit(): void {
    // this.authService.logout()

  }

  onLogin() {
    this.loginError = false;
    this.loggingIn = true;
    this.authService.authenticate(this.user)
      .subscribe((res: any) => {
        if (res.success) {
          this.authService.setSession(res)
          this.router.navigate(['/'])
        } else {
          this.loginError = true;
        }
        this.loggingIn = false;
      }, err => {
        if (err.error.msg) {
          if (err.error.msg == 'Password Expired') {
            this.passwordExpired = true;
          } else {
            this.loginError = true;
          }
        } else {
          this.loginError = true;
        }
        this.loggingIn = false;
      })
  }

  resetPassword() {
    this.resetting = true;
    this.resetError = false;
    if (!this.resetUser.password || !this.resetUser.confirm_password || this.resetUser.password.length < 6
      || this.resetUser.confirm_password.length < 6
      || this.resetUser.password !== this.resetUser.confirm_password && this.resetUser.confirm_password) {
      return;
    }
    if (this.resetUser.email && this.resetUser.prev_password && this.resetUser.password && this.resetUser.confirm_password) {
      this.authService.resetPassword(this.resetUser).subscribe(
        (res: any) => {
          if (res.success) {
            this.resetComplete = true;
            this.authService.setSession(res)
            setTimeout(() => {
              this.router.navigate(['/'])
            }, 2000)
          } else {
            this.resetError = true;
          }
          this.resetting = false;
        },
        err => {
          this.resetting = false;
          this.resetError = true;
        }
      )
    }
  }

  toggleResetPassword() {
    this.loginError = false;
    if (!this.loggingIn) {
      this.passwordExpired = !this.passwordExpired
    }
  }

  authReset() {
    this.resetAuthenticating = true;
    this.resetAuthenticated = true;
    this.authService.authenticate({ email: this.resetUser.email, password: this.resetUser.prev_password })
      .subscribe((res: any) => {
        if (res.success) {
          this.resetAuthenticating = false;
          this.resetAuthenticated = true;
          this.stepper.next()
        } else {
          this.resetAuthenticated = false;
          this.cdRef.detectChanges()
        }
        
      }, err => {
        if (err.error.msg) {
          if (err.error.msg == 'Password Expired') {
            this.resetAuthenticated = true;
            this.stepper.next()
          } else {
            this.resetAuthenticated = false;
          }
        } else {
          this.resetAuthenticated = false;
          this.cdRef.detectChanges()
        }
        this.resetAuthenticating = false;
      })
  }

}
