<app-header></app-header>
<div class="container whole-success-page">
    <div class="card">
        <div class="card-body">
            <div *ngIf="!paid && dataReturned" class="row pnr-info-group">
                <div class="">
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                        <strong class="reservation-success-text">Reservation Created Successfully</strong>
                        <button type="button" class="close reservation-close" data-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row pnr-info-group">
                <div class="">
                    <span class="pnr-label">PNR </span>
                  <span class="pnr-value" *ngIf="isAtmPayment">{{ bookingResponse.npnr ? bookingResponse.npnr : '' }}</span>
                  <span class="pnr-value" *ngIf="!isAtmPayment">{{ bookingResponse.pnr ? bookingResponse.pnr : '' }}</span>
                </div>
            </div>
            <div *ngIf="bookingResponse.PaymentStatus !== 'paid' && dataReturned" class="pending">
                <div class="row confirm-messege-box">
                    <div>
                        <span class="confirm-message-box-text" *ngIf="bookingResponse.PaymentStatus !== 'paid' && dataReturned">
                            {{selectedPaymentMethod.description}}
                        </span>
                    </div>
                </div>
                <div class="row content-center-align col-md-12">
                    <div>
                        <span class="booking-detail" *ngIf="bookingResponse.PaymentStatus !== 'paid' && dataReturned">
                            Total Price: <b>{{ bookingResponse.TotalPrice.substring(3) | currency: 'ETB ' }}</b>
                        </span>
                    </div>
                </div>
                <div class="alert alert-danger alert-dismissible fade show" *ngIf="paymentError" role="alert">
                    <strong class="reservation-danger-text">Your Booking has been made successfully, but your payment has failed.
                        <br />
                        Please use another payment method to complete the booking with the above PNR.
                        <br />
                        Or contact +251-7473 for assistance
                    </strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- <div class="row last-button-confirm-group" *ngIf="bookingResponse.PaymentStatus === 'paid'">
                    <div>
                        <div class="row">
                            <div class="col-md-4 content-center-align">
                                <button class="btn ticket-button">Send SMS</button>
                            </div>
                            <div class="col-md-4 content-center-align">
                                <button class="btn ticket-button">Send Email</button>
                            </div>
                            <div class="col-md-4 content-center-align">
                                <button class="btn ticket-button">Send SMS</button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div *ngIf="bookingResponse.PaymentStatus === 'paid' && dataReturned" class="paid">
                <div class="row pnr-info-group">
                    <div class="">
                        <span class="payment-status-label">Payment Status</span>
                        <div class="">
                            <div class="row pnr-payment-group-box">
                                <div class="col-md-12 content-center-align">
                                    <div class="pnr-success-btn"><i class="fa fa-check"></i><span>PAID</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="">
                        <div class="row">
                            <div class="col-md-6"><input class="btn send-btn" type="button" value="Send SMS"></div>
                            <div class="col-md-6"><input class="btn send-btn" type="button" value="Send Email"></div>
                        </div>
                    </div>
                </div> -->
            </div>
            <button [disabled]="checkingStatus" routerLink='/' class="btn bg-yellow-400 mt-10 rounded-xl px-10">{{'Home.continue' | translate}}</button>
        </div>
    </div>
</div>
