import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-passenger-type-form',
  templateUrl: './passenger-type-form.component.html',
  styleUrls: ['./passenger-type-form.component.css']
})
export class PassengerTypeFormComponent implements OnInit {

  passengerType : Number = 0;
  constructor(public dialogRef: MatDialogRef<PassengerTypeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
   }

  ngOnInit(): void {
    console.log(this.data)
  }

  setPassengerType(){
    this.dialogRef.close({selected : this.passengerType});
  }
}
