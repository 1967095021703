<div class="container" style="width : unset;">
    <h2 class='text-large' mat-dialog-title>{{'Home.localflightr' | translate}}</h2>
    <mat-dialog-content class="mat-typography">
        <!-- <div class="row">
            <div>
                <span class='text-large'>{{'Home.localflightr' | translate}} </span> <span class="text-large"></span>
            </div>
        </div> -->
        <div class="row">
            <mat-radio-group [(ngModel)]="passengerType">
                <mat-radio-button class="example-margin" [checked]="true" value="1"><span
                        class="wrap-mat-radio-label">{{'Home.kebeleid' | translate}}</span></mat-radio-button>
                <mat-radio-button class="example-margin" value="2"><span class="wrap-mat-radio-label">{{'Home.ethinter'
                        | translate}}</span></mat-radio-button>
                <mat-radio-button class="example-margin" value="3"><span class="wrap-mat-radio-label">{{'Home.noneof' |
                        translate}}</span></mat-radio-button>
            </mat-radio-group>
        </div>
        <p *ngIf="data.flight.passengers.INF"><i class="fa fa-info-circle"></i> <span class="font-bold">Remark: </span> For infant passengers, you need to provide birth certificate or any proof of infancy at the airport</p>
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
        <button [disabled]="!passengerType" mat-button color='primary' (click)="setPassengerType()">
            <span>{{'Home.continue' | translate}}</span>
        </button>
    </div>
</div>