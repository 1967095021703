<h2 mat-dialog-title>Select Payment Method</h2>
<mat-dialog-content class="mat-typography">
    <mat-grid-list cols="2" rowHeight="2:1">
        <mat-grid-tile *ngFor="let paymentMethod of paymentMethods">
            <div *ngIf="paymentMethod.name === 'Bank of Abyssinia'" class="selection-overlay">
            </div>
            <mat-card class="payment-card">
                <mat-card-header>
                    <mat-card-title>{{ paymentMethod.name }}</mat-card-title>
                </mat-card-header>
                <img mat-card-image [src]="paymentMethod.image" [alt]="paymentMethod.image">
            </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>