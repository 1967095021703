import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UapiService } from 'src/app/services/http/uapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hellocash',
  templateUrl: './ebirr-dialog.component.html',
  styleUrls: ['./ebirr-dialog.component.css']
})
export class EbirrDialogComponent implements OnInit {
  selectedFlight;
  exchangeRate;
  price;
  url;
  pnr;
  pnrSearchBookingResponse: any;
  helloCashPhoneNumber: string;
  safe: SafeResourceUrl;
  loading = false;

  constructor(public sanitizer: DomSanitizer, private router: Router, public globalService: GlobalService, private uapiService: UapiService,
    public dialog: MatDialog,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EbirrDialogComponent>) { }

  ngOnInit(): void {
    this.url = this.data.hppUrl;
    this.safe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.selectedFlight = this.globalService.getSelectedFlight();
    let url = this.data.hppUrl;

    console.log('DATA: ', this.data);

    var formData = new FormData();
    formData.append(
      'hppRequestId',
      this.data.hppRequestId,
    );
    formData.append(
      'referenceId',
      this.data.referenceId,
    );
    formData.append(
      'srcAccountNo',
      this.data.srcAccountNo,
    );
    formData.append(
      'srcAccountName',
      this.data.srcAccountName,
    );
    this.httpClient
      .post(url, formData, {
        headers: {
          'Content-Type': 'application/form-data',
        },
        responseType: 'text'
      })
      .toPromise()
      .then((respon: any) => {  
        const html = respon
        this.data.html = html
      })
      .catch((_err) => {
        console.error('ERrror ', _err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  continue() {
    this.dialogRef.close({ helloCashPhoneNumber: this.helloCashPhoneNumber })
  }

  dismiss() {
    this.dialogRef.close()
  }


  URLChangeHandler(iframe): void {
    const changes = '';
    console.log('EVENT: ', iframe.contentWindow)
    // return;
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes) {
      console.log("object", changes);
    }
  }

}

function MD_DIALOG_DATA(MD_DIALOG_DATA: any) {
  throw new Error('Function not implemented.');
}

