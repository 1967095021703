import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import $ from 'jquery';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UapiService } from 'src/app/services/http/uapi.service';

@Component({
  selector: 'app-telebirr-dialog',
  templateUrl: './telebirr-dialog.component.html',
  styleUrls: ['./telebirr-dialog.component.css']
})
export class TelebirrDialogComponent implements OnInit {
  selectedFlight;
  exchangeRate;
  price;
  url;
  pnr;
  pnrSearchBookingResponse: any;
  safe: SafeResourceUrl;
  loading = true;
  data;

  constructor(
    @Inject(MAT_DIALOG_DATA) private telebirrData: any,
    public sanitizer: DomSanitizer, private router: Router, public globalService: GlobalService, private uapiService: UapiService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    $('iframe').on('load', e => {
      console.log('E: ', e.target.contentWindow, e.target.src, )
      this.loading = false;
      if ( e.target.src === this.telebirrData.successURL) {
        this.dismiss();
      }
    })
    this.selectedFlight = this.globalService.getSelectedFlight();
    console.log('CS DIALOG> DATA:', this.telebirrData)
    this.url = this.telebirrData.telebirrURL;
    console.log(this.url)
    this.safe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  URLChangeHandler(iframe): void {
    const changes = '';
    console.log('EVENT: ', iframe.contentWindow, { iframe })
    return;
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

  dismiss() {
    this.dialog.closeAll()
  }

}
