import { Component, OnChanges, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UapiService } from 'src/app/services/http/uapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-hellocash',
  templateUrl: './hellocash.component.html',
  styleUrls: ['./hellocash.component.css']
})
export class HellocashComponent implements OnInit {
  selectedFlight;
  exchangeRate;
  price;
  data;
  url;
  pnr;
  pnrSearchBookingResponse: any;
  helloCashPhoneNumber: string;

  constructor(public sanitizer: DomSanitizer, private router: Router, public globalService: GlobalService, private uapiService: UapiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<HellocashComponent>) { }

  ngOnInit(): void {
    console.log('ON INIT')
    this.selectedFlight = this.globalService.getSelectedFlight();
  }

  continue() {
    this.dialogRef.close({helloCashPhoneNumber: this.helloCashPhoneNumber})
  }

  dismiss() {
    this.dialogRef.close()
  }
}
