<div class="app">
  <!-- <header>
    <nav class="navbar navbar-expand-md fixed-top navbar-dark custom-nav" style="background-color: #131C66;">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="./assets/params/images/logo/guzo_logo.png" width="60" height="60" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarsExampleDefault">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button value="1" checked>PNR</mat-radio-button>
                <mat-radio-button value="2">Name</mat-radio-button>
                <mat-radio-button value="3">Ticket Number</mat-radio-button>
              </mat-radio-group>
              <input class="form-control custom-input" type="text" placeholder="Search">
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container">
      <div class="container-fluid row  container-fluid-custom">
        <div class="label-box"><span class="label-text">Hello Gezachew Ayenew</span></div>
        <div class="splider"></div>
        <div><span class="label-text">Logout</span><i class="fa fa-power-off power-off-custom"></i></div>
      </div>
    </div>
  </header> -->

  <main>
    <router-outlet></router-outlet>

    <!-- Messenger Chat Plugin Code -->
    <div id="fb-root"></div>

    <!-- Your Chat Plugin code -->
    <div id="fb-customer-chat" class="fb-customerchat">
    </div>


  </main>

</div>
