import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent implements OnInit {

  paymentMethods = [
    {
      "name": "Bank of Abyssinia",
      "image": "assets/imgs/payment_gateways/BOA.png"
    },
    {
      "name": "Commercial Bank of Ethiopia",
      "image": "assets/imgs/payment_gateways/CBE.png"
    },
    {
      "name": "Oromia International Bank",
      "image": "assets/imgs/payment_gateways/OIB.jpg"
    },
    {
      "name": "Berhan Bank",
      "image": "assets/imgs/payment_gateways/Berhan_Bank.png"
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
