<h2 mat-dialog-title>Terms and Conditions</h2>
<mat-dialog-content class="mat-typography">

    <div id="st-content-wrapper" style="padding: 10px;">
        <div class="container">
            <strong>Introduction and definition</strong><br />
            GuzoGo is an online travel agency operated by GuzoGO Travel Plc. GuzoGo is a private limited company
            incorporated under the laws of the Federal democratic republic of Ethiopia in Addis Ababa, Sub-City Bole,
            Ethiopia, with headquarters located at Rwanda Street, Rosetta Building 3rd floor.
            These terms and conditions, their subject matter and their formation, are governed by laws of the Federal
            democratic republic of Ethiopia.

            GuzoGO application is provided by GuzoGo Travel Plc to help customers gather travel information, determining
            the availability of flight and related services, making legitimate reservations or transacting business with
            airlines, and for no other Purposes. The terms “GuzoGo”, “us”, “we” or “our” refer to GuzoGo Travel Plc and
            the term “you” refers to the customer or passenger using the GuzoGO mobile application.
            These terms and conditions, as may be amended from time to time, apply to all our services directly or
            indirectly (through suppliers) made available online, through any mobile device, by email or by telephone.
            By accessing, browsing and using our mobile application and by completing a reservation, you acknowledge and
            agree to have read, understood and agreed to the terms and conditions set out below (including the privacy
            statement).
            These pages, the content and infrastructure of these pages, and the online reservation service provided on
            the application are owned, operated and provided by GuzoGO Travel Plc and are provided for your personal,
            non-commercial use only. Therefore, you are not allowed to re-sell, deep-link, use, copy, monitor (e.g.
            spider, scrape), display, download or reproduce any content or information, reservations, products or
            services available on our Platform for any commercial or competitive activity or purposes.
            “Platform” means the application on which the Services are made available owned, controlled, managed,
            maintained and/or hosted by GuzoGO Travel Plc.
            “Service” means the online reservation and purchasing service (including the facilitation of payments) of
            various suppliers/airlines/banks and services as from time to time made available by Suppliers on the
            Platform and/or any related service made available by GuzoGO travel Plc and its GuzoGo mobile application.
            “Supplier” means the provider of flight tickets, payment methods and any other travel or related product or
            service as from time to time made available for reservation and information provision on the Platform.


            <br /><br /><strong>Disclaimer</strong><br />
            GuzoGo Travel Plc disclaims any liability or responsibility for any communication with the Supplier on or
            through its platform. You cannot derive any rights from any request to, or communication with the Supplier
            or (any form of) acknowledgement of receipt of any communication or request. GuzoGo cannot guarantee that
            any request or communication will be (duly and timely) received/read by, complied with, executed or accepted
            by the Supplier.
            In order to duly complete and secure your reservation, you need to use your correct email address. We are
            not responsible or liable for (and have no obligation to verify) any wrong or misspelled email address or
            inaccurate or wrong (mobile) phone number or credit card number.
            Any claim or complaint against GuzoGO or in respect to the Service must be promptly submitted, but in any
            event within 30 days after the scheduled day of consummation of the product or service (e.g. used tickets).
            Any claim or complaint that is submitted after the 30 days period, may be rejected and the claimant shall
            forfeit its right to any (damage or cost) compensation.
            Subject to the limitations set out in these terms and conditions and to the extent permitted by law, we
            shall only be liable for direct damages actually suffered, paid or incurred by you due to an attributable
            shortcoming of our obligations in respect to our services, up to an aggregate amount of the aggregate cost
            of your reservation as set out in the confirmation email or invoice (whether for one event or series of
            connected events).
            However and to the extent permitted by law, neither we nor any of our officers, directors, employees,
            representatives, subsidiaries, affiliated companies, distributors, affiliate (distribution) partners,
            licensees, agents or others involved in creating, sponsoring, promoting, or otherwise making available the
            site and its contents shall be liable for
            i.any punitive, special, indirect or consequential loss or damages, any loss of production, loss of profit,
            loss of revenue, loss of contract, loss of or damage to goodwill or reputation, loss of claim,
            ii.any inaccuracy relating to the (descriptive) information (including rates, availability and ratings) of
            the Supplier as made available on our Platform,
            iii.the services rendered or the products offered by the Supplier or other business
            partners/affiliates/suppliers,
            iv.any (direct, indirect, consequential or punitive) damages, losses or costs suffered, incurred or paid by
            you, pursuant to, arising out of or in connection with the use, inability to use or delay of our Platform,
            v.any (personal) injury, death, property damage, or other (direct, indirect, special, consequential or
            punitive) damages, losses or costs suffered, incurred or paid by you, whether due to (legal) acts, errors,
            breaches, (gross) negligence, willful misconduct, omissions, non-performance, misrepresentations, tort or
            strict liability by or (wholly or partly) attributable to the Supplier or any of our other business partners
            (including any of their employees, directors, officers, agents, representatives or affiliated companies)
            whose products or service are (directly or indirectly) made available, offered or promoted on or through the
            Platform, including any (partial) cancellation, overbooking, strike, force majeure or any other event beyond
            our control.
            Whether or not the Supplier has charged you for your ticket, or if we are facilitating the payment of the
            ticket price, you agree and acknowledge that the Supplier is at all times responsible for the collection,
            withholding, remittance and payment of the applicable taxes due on the total amount of the reservation price
            to the relevant tax authorities. GuzoGo is not liable or responsible for the remittance, collection,
            withholding or payment of the relevant taxes due on the reservation price to the relevant tax
            authorities GuzoGo does not act as the merchant of record for any product or service made available on the
            Platform.
            our Services
            Through the Platform, we ( GuzoGo and its affiliate (suppliers and/or distribution partners) provide an
            online platform through which Suppliers can advertise their products and service for reservation, and
            through which visitors of the Platform can make such reservations by the reservation service made available.
            By making a reservation through GuzoGo application, you enter into a direct (legally binding) contractual
            agreement with the Supplier.
            When rendering our Service, the information that we disclose is based on the information provided to us by
            Suppliers.

            <br /><br /><strong>Documentation and</strong><br />
            For the purpose of verification, identification and/or clarification, you consent to give us a copy of your
            government issued ID or driver’s license or passport, according to the terms and regulations of the National
            Bank of Ethiopia and all airlines. GuzoGo will not be liable or responsible for the inconvenience/issues
            that will arise for that matter.

            
            <br /><br /><strong>Prices and rates</strong><br />
            The prices on our Platform are highly competitive. All prices are displayed including VAT/sales tax and all
            other taxes (subject to change of such taxes), unless stated differently on our platform or the confirmation
            email/invoice. Product or service prices are per person or group as displayed in the platform and subject to
            validity as indicated on the email/invoice, if applicable. Applicable fees and taxes may be charged by the
            Supplier in the event of cancellation or refund/exchange. Obvious errors and mistakes (including misprints)
            are not binding. All special offers and promotions seen on the application are marked as such.
            Privacy Policy
            This privacy policy is applicable to GuzoGo mobile application

            
            <br /><br /><strong>Data Privacy Principles and Utilization of Personal Data</strong><br />
            We, GuzoGo, are pleased that you are visiting our mobile application and we would like to provide you with
            some information about the collection, processing and use of personal data when using this mobile
            application. GuzoGo collects processes and uses personal data exclusively according to this data privacy
            statement and within the scope of statutory authorization. If you need further information or have any
            questions about how GuzoGo handles data privacy, please contact us at contact@guzogo.com

            <br /><br /><strong>What is personal data?</strong><br />
            Personal data is any information concerning the personal or material circumstances of an identified or
            identifiable individual. This includes information such as your name, your address, your telephone number or
            your date of birth and under certain circumstances booking information of users.

            <br /><br /><strong>General Information about Data Privacy</strong><br />
            Unless stated otherwise in the following sections, no personal data will be collected, processed or used
            while using this mobile application.

            <br /><br /><strong>Connection information</strong><br />
            When you access our mobile application, particular information is transmitted by a standard procedure, such
            as the IP address and the platform of your mobile device. As a rule, we will analyze these data only
            statistically in order to prevent errors and to improve our services. Only in the event of potential abuse
            related to bookings, we would use the connection information to facilitate the identification of the
            responsible person.

            <br /><br /><strong>Social Media</strong><br />
            On our mobile application, we have integrated links to various social media services (Facebook and Google).
            By clicking on the relevant symbol or the full name, you will be redirected to the mobile application of the
            relevant service.
            On the social media service mobile applications, the data privacy policies and terms of use of the relevant
            service apply. GuzoGo does not have any impact on the collection, processing, and use of data on these
            mobile applications.

            <br /><br /><strong>Facebook Connect</strong><br />
            We allow you to sign up and log in using your Facebook account. If you sign up using Facebook, Facebook will
            ask your permission to share certain information from your Facebook account with GuzoGo. This may include
            your first name, last name, email address in order for us to verify your identity and gender, general
            location, a link to your Facebook profile, your time zone, birthday, profile picture, your “likes” and your
            list of friends.
            This information is collected by Facebook and is provided to us under the terms of Facebook’s privacy
            policy. You can control the information that we receive from Facebook using the privacy settings in your
            Facebook account.
            If you sign up on GuzoGo using Facebook, your GuzoGo account will be connected automatically to your
            Facebook account, and information regarding your activity on GuzoGo, may be shared with Facebook and may be
            published in your timeline and your Facebook friends’ newsfeeds.
            Google
            You can sign up and log in using your Google account. When you sign up using Google, Google will ask your
            permission to share certain information from your Google account with GuzoGo. This may include your first
            name, last name, email address in order for us to verify your identity and gender, a link to your Google
            profile. This information is collected by Google and is provided to us under the terms of Google’s privacy
            policy.
            If you sign up on GuzoGo using Google, by default information regarding your activity on GuzoGo will be
            visible on Google to everyone in your Google circles and shared with Google according to Google’s terms of
            service and Google’s privacy policy. For more information on how to manage the activities you share back to
            your Google account, see Google’s support page. You can manage who from your Google circles sees your GuzoGo
            activity from within your Google App settings page.

            <br /><br /><strong>Security</strong><br />
            GuzoGo implements technical and organizational security measures to safeguard stored personal data against
            inadvertent or deliberate manipulation, loss or destruction and against access by unauthorized persons. Our
            security measures are continuously improved in line with technological progress. Furthermore, technologies
            such as SSL and many others are incorporated to the system to safeguard the transactions made in the mobile
            application.


            <br /><br /><strong>Your rights</strong><br />
            Upon written request, we will be glad to inform you pursuant to our obligation by law if and which personal
            data of yours are stored with us. Under the conditions of the provisions of the Ethiopian laws and
            regulations, you may request correction, blocking and deletion of such data, unless these requests conflict
            with statutory time limits for storage.


            <br />Concerning your rights please contact:<br />
            GuzoGo travel Plc.<br />
            Addis Ababa<br />
            Ethiopia<br />
            contact@GuzoGo.com<br />
            In the event that there are variances between the data privacy statements in various languages arising from
            translation, then the English version shall prevail.

        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="dismiss()">Close</button>
</mat-dialog-actions>