<mat-dialog-content class="mat-typography content">
    <div class="form-row">
        <div class="col-md-6">
            <h2 mat-dialog-title>
                <img src="assets/imgs/payment_gateways/wegagen_hellocash.png" alt="HelloCash">
            </h2>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="input-phone" class="form-label">Wegagen HelloCash phone number</label>
                <input type="text" [(ngModel)]='helloCashPhoneNumber' name="input-phone" class="form-control input-size"
                    id="phone-number" placeholder="e.g. +2519..">
            </div>
            <p><strong>After choosing to continue, your flight should be booked and you should receive an invoice from
                    HelloCash with this phone number to complete your payment.</strong></p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="dismiss()">Close</button>
    <button mat-stroked-button class="action-btn btn btn-warning search-btn-custom" (click)="continue()" [disabled]="!helloCashPhoneNumber || helloCashPhoneNumber.length < 9">BOOK NOW</button>
</mat-dialog-actions>