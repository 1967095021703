import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UtilService } from 'src/app/services/util.service';
import { AirportsService } from 'src/app/services/airports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { isArray } from 'util';
import { UapiService } from 'src/app/services/http/uapi.service';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  tickets = [];
  bookingResponse: any;
  loaded = false;
  carriers: any = [];
  segments = [];
  airports: any[] = [];
  directions: any;
  index = null;
  valid = false;
  tryingAgain = false;
  fareRules = { inbound: [], outbound: [] }
  fareRulesLoaded = false;
  pnr = null;
  samePNR = false;
  LOCAL_AIRPORTS = ["ADD", "AMH", "ASO", "AWA", "AXU","BCO",
        "BJR", "DIR", "GDE", "GDQ", "GMB",
        "JIJ", "JIM", "LLI", "MQX", "SHC", "SZE"
  ];

  isLocalFlight = true;

  constructor(private globalService: GlobalService, public utilService: UtilService, public airportService: AirportsService,
    private route: ActivatedRoute, private titleService: Title, private uapiService: UapiService, private router: Router) {
    this.airportService.getCarriers().subscribe(data => {
      this.carriers = data;
    })
    this.airportService.getAirports().subscribe(data => {
      this.airports = data
    })
    this.index = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.initVar();
  }

  initVar() {
    const bookingResp = this.globalService.getBookingResponse()
    if (bookingResp.pnr) {
      this.pnr = bookingResp.pnr
    } else {
      this.pnr = bookingResp.BookingResponse.pnr
    }
    this.tickets = this.globalService.getTickets();

    if (this.index > this.tickets.length - 1) {
      this.loaded = false
      return;
    }
    if (this.index >= 0) {
      this.tickets = [this.tickets[this.index]]
    }
    this.titleService.setTitle('GuzoGo_E-Ticket__' + this.tickets[0].name.split(' ')[0] + '_' + this.tickets[0].number + '_' + new Date().getTime())
    this.bookingResponse = this.globalService.getBookingResponse();

    //Just to ByPass Prob Occured On PNR Imported
    if (this.bookingResponse.pnr) {
      this.bookingResponse.BookingResponse = {}
      this.bookingResponse.BookingResponse = this.bookingResponse
    }


    if (this.bookingResponse && this.bookingResponse.passengers) {
      this.bookingResponse.Passengers = this.bookingResponse.passengers
    }

    //Seat Assignment
    this.bookingResponse.BookingResponse.Passengers.map((_passenger, index) => {
      if (index === 0) {
        if (this.bookingResponse.MainPassenger && this.bookingResponse.MainPassenger.seatAssigned) {
          this.bookingResponse.Passengers[0].seatAssigned = this.bookingResponse.MainPassenger.seatAssigned;
        }
      } else {
        if (this.bookingResponse.Passengers[index] && this.bookingResponse.Passengers[index].seatAssigned) {
          this.bookingResponse.Passengers[index].seatAssigned = this.bookingResponse.Passenger[index].seatAssigned;
        }
      }
    })
    
    if (this.bookingResponse.BookingResponse.pnr === this.globalService.getSelectedBookedFlight().pnr) {
      if (this.bookingResponse.BookingResponse.pnr === this.pnr) {
        
        this.directions = []
        this.directions[0] = []
        
        const fetchedDirections = this.bookingResponse.BookingResponse;
        if(fetchedDirections.segments){
          fetchedDirections.segments.forEach(_segment => {
            this.directions[0].push(_segment);
          })
        }else {
          
          if(fetchedDirections.directions && fetchedDirections.directions.length > 0){
          
            fetchedDirections.directions.forEach( _direction => {
          
              _direction.forEach( direction => {

                if(direction.segments && direction.segments){
                  direction.segments.forEach( _segment => {
                    this.directions[0].push(_segment)
                  });
                }

              })

            });
          }

        }
        
        this.valid = true;
      }
      this.loaded = true;
      this.tryingAgain = false;
      this.samePNR = true;
      this.fetchFareRules();
    } else {
      this.valid = false;
      this.loaded = true;
      this.tryingAgain = false;
      this.samePNR = false;
    }

    this.directions[0].forEach( _segment => {
      
      if(this.LOCAL_AIRPORTS.indexOf(_segment.from) < 0 || this.LOCAL_AIRPORTS.indexOf(_segment.to) < 0){
        
        this.isLocalFlight = false
      }

    });

    // console.log(" Rest " , this.bookingResponse.BookingResponse.BookingResponse)
  }

  getTransitTime(index, dirIndex) {
    const arrivalAirportCodes = []
    if (this.directions && this.directions[dirIndex] && this.directions[dirIndex].segments && this.directions[dirIndex].segments.length > 1) {
      this.directions[dirIndex].segments.map((segment, index) => {
        if (index === this.directions[dirIndex].segments.length - 1) {
          return
        } else {
          arrivalAirportCodes.push(segment.to)
        }
      })
    } else {

      if ((this.directions[0] && this.directions[0].length != 1) && (this.directions[0][dirIndex + 1] && (this.directions[0][dirIndex + 1].from != this.directions[0][dirIndex].to))) {
        const time = new Date(this.directions[0][dirIndex + 1].departure).getTime() - new Date(this.directions[0][dirIndex].arrival).getTime()
        return this.utilService.getInt(time / (3600 * 1000)) + ' hr' + (this.utilService.getDecimals(time / 60000) ? ' ' + this.utilService.getDecimals(time / 60000) + ' m' : '')
      }

    }

    if (this.directions && this.directions[dirIndex] && this.directions[dirIndex].segments && this.directions[dirIndex].segments[index + 1]) {
      const time = new Date(this.directions[dirIndex].segments[index + 1].departure).getTime() - new Date(this.directions[dirIndex].segments[index].arrival).getTime()
      return this.utilService.getInt(time / (3600 * 1000)) + ' hr' + (this.utilService.getDecimals(time / 60000) ? ' ' + this.utilService.getDecimals(time / 60000) + ' m' : '')
    }
  }

  getAirportFullName(IATACode) {
    let airport = this.airports.find(airport => airport.IATACode === IATACode)
    if (airport) {
      return airport.airport + " (" + airport.IATACode + ")"
    } else {
      return ""
    }
  }

  getCarrierName(carrierIATACode) {
    let carrierFound = this.carriers.find(carrier => carrier.carrier_code === carrierIATACode)
    if (carrierFound) {
      return carrierFound.carrier_name1
    } else {
      return carrierIATACode
    }

  }

  onTryAgain() {
    this.tryingAgain = true;
    // this.initVar()
  }

  close() {
    window.close()
  }

  async fetchFareRules() {
    let res: any = null;
    this.fareRulesLoaded = false;
    const bookedResponse = this.globalService.getBookingResponse();
    const params = {
      segments: [],
      passengers: {
        ADT: 0,
        CNN: 0,
        INF: 0
      },
      long: true,
      requestId: 'test',
    }

    if (bookedResponse) {
      //It is UR
      if (bookedResponse.segments) {
        params.segments = bookedResponse.segments.map(_segment => _segment);
        bookedResponse.passengers.map((_passenger, index) => {
          params.passengers[_passenger.ageCategory] += 1
        })
      }
    } else {
      const outboundSegments = this.globalService.getSelectedOutboundFlight().segments

      const returnSegments = this.globalService.getSelectedInboundFlight() ?
        this.globalService.getSelectedInboundFlight().segments : []
      const passengers = this.globalService.getSearchParams().passengers;
      passengers['ADT'] = parseInt(passengers['ADT'])
      passengers['CNN'] = parseInt(passengers['CNN'])
      passengers['INF'] = parseInt(passengers['INF'])
      params.segments = outboundSegments.concat(returnSegments)
      params.passengers = passengers
    }
    /*
    if (this.globalService.getFareRulesResp()) {
      res = this.globalService.getFareRulesResp()
    } else {

      res = await this.uapiService.getFareRules(params).toPromise()
      this.globalService.setFareRulesResp(res)
      }*/
      res = false
    if (res) {

      if(res.fareRules){
        res.fareRules.forEach((_fareRule, _idx) => {
          if (_fareRule) {
            if (Array.isArray(_fareRule)) {
              if (_idx === 0) {
  
                this.fareRules.outbound.push(_fareRule['_']);
              } else {
  
                this.fareRules.inbound.push(_fareRule['_'])
              }
            } else {
              this.fareRules.outbound.push(_fareRule['_']);
            }
          }
  
        })
      }
      
    }
    this.fareRulesLoaded = true;
    if (this.valid && this.bookingResponse.TotalPrice) {
      setTimeout(() => window.print(), 1000)
    }
  }

  getCheckInTime() {
    if (this.directions) {
      let departure = null;
      if (isArray(this.directions[0])) {
        departure = new Date(this.directions[0][0]['departure'])
      } else {
        if (this.directions[0] && this.directions[0].segments && this.directions[0].segments[0]) {
          departure = new Date(this.directions[0].segments[0].departure)
        } else {
          departure = new Date(this.directions[0].departure)
        }

      }            

      let hours = 1;
      this.directions[0].forEach( _segment => {
        if(this.LOCAL_AIRPORTS.indexOf(_segment.from) < 0 || this.LOCAL_AIRPORTS.indexOf(_segment.to) < 0){
          hours = 3
        }
      })
      
      return departure.setHours(departure.getHours() - hours)
    }
  }

  navigateToOpenPNR(pnr) {
    this.router.navigateByUrl(`/artboard?identifier=${pnr}&type=0`)
  }

  formatGregorianTime(time){
    return this.formatTimedZone(time)
  }

  offsetExtract(incomingTime) {
    var time = (incomingTime.split('T')[1]).includes('-') ? (incomingTime.split('T')[1]).split('-')[1] : (incomingTime.split('T')[1]).split('+')[1]

    var sign = (incomingTime.split('T')[1]).includes('-') ? '-' : '+'

    var offset = time.split(':')

    var min = parseInt(offset[0]) * 60

    return min / 60
  }

  formatTimedZone(time) {

    const reference = (moment().format('Z'))
    const ref = reference.includes('+') ? parseInt(reference.split(':')[0]) : parseInt(reference.split(':')[0]) * -1
    var sign = (time.split('T')[1]).includes('-') ? '-' : '+'

    var offset = sign === "-" ? (-1 * this.offsetExtract(time) - ref) : this.offsetExtract(time) - ref

    var val = offset > 0 ? "+" + offset : JSON.stringify(offset)

    return moment(time).add(val, 'hours')
  }

  showLocalTime(ethiopianTime , airportCode){

    if(this.LOCAL_AIRPORTS.indexOf(airportCode) < 0 || this.LOCAL_AIRPORTS.indexOf(airportCode) < 0){
        
      return ""
    }else {
      return ethiopianTime;
    }
  }
}
