import { Injectable } from '@angular/core';
import { SearchParamModel } from '../models/search-param.model';
import { PassengerModel } from '../models/passenger.model';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private mSearchParams: SearchParamModel;
  selectedFlight: any;
  selectedOutboundFlight: any;
  selectedInboundFlight: any;
  passengers: PassengerModel[] = [];
  bookingResponse: any;
  selectedCalendarType: string = 'EC';
  tickets: any[] = [];
  bookedFlight: any;
  selectedBookedFlight: any;
  segments: any;
  selectedPaymentMethod: any;
  LOCAL_AIRPORTS = ["ADD", "AMH", "ASO", "AWA", "AXU", "BCO",
    "BJR", "DIR", "DSE", "GDE", "GDQ", "GMB", "HUM",
    "JIJ", "JIM", "LLI", "MQX", "SHC", "SZE"
  ];
  isLocalFlight = false;

  constructor(private storageService: StorageService) {
    if (!this.getSelectedCalendarType()) {
      this.setSelectedCalendarType('EC')
    }
  }

  setSearchParams(params) {
    this.storageService.secureStorage.setItem('searchParam', JSON.stringify(params));
  }

  getSearchParams() {
    return JSON.parse(this.storageService.secureStorage.getItem('searchParam'));
  }

  setFlightType(flightType) {
    this.storageService.secureStorage.setItem('flightType', (flightType));
  }
  setCybersourceData(data) {
    this.storageService.secureStorage.setItem('data', JSON.stringify(data));
  }
  getCybersourceData() {
    return JSON.parse(this.storageService.secureStorage.getItem('data'));
  }
  setUSDPrice(price) {
    this.storageService.secureStorage.setItem('price', JSON.stringify(price));
  }
  getUSDPrice() {
    return this.storageService.secureStorage.getItem('price');
  }

  getPaymentError() {
    return JSON.parse(this.storageService.secureStorage.getItem('paymentError'))
  }

  setPaymentError(error) {
    return this.storageService.secureStorage.setItem('paymentError', JSON.stringify(error))
  }

  setTickets(tickets) {
    this.tickets = tickets;
    this.storageService.secureStorage.setItem('TICKETS', JSON.stringify(tickets));
  }

  getTickets() {
    return JSON.parse(this.storageService.secureStorage.getItem('TICKETS'));
  }

  getFlightType() {
    return this.storageService.secureStorage.getItem('flightType');
  }

  setDepartureSearchLocation(departureLocation) {
    this.storageService.secureStorage.setItem('departureLocation', JSON.stringify(departureLocation));
  }

  getDepartureSearchLocation() {
    return JSON.parse(this.storageService.secureStorage.getItem('departureLocation'));
  }


  setArrivalSearchLocation(arrivalLocation) {
    this.storageService.secureStorage.setItem('arrivalLocation', JSON.stringify(arrivalLocation));
  }

  getArrivalSearchLocation() {
    return JSON.parse(this.storageService.secureStorage.getItem('arrivalLocation'));
  }

  setSelectedInboundFlight(flight) {
    // this.selectedInboundFlight = flight;
    this.storageService.secureStorage.setItem('SELECTED_INBOUND_SEGMENT', JSON.stringify(flight));
  }

  getSelectedInboundFlight() {
    // return this.selectedInboundFlight;
    const data = this.storageService.secureStorage.getItem('SELECTED_INBOUND_SEGMENT')
    if (!!data && !!data.trim() && data !== 'undefined') {
      return JSON.parse(this.storageService.secureStorage.getItem('SELECTED_INBOUND_SEGMENT'));
    }
    return null;
  }

  getSelectedOutboundFlight() {
    return JSON.parse(this.storageService.secureStorage.getItem('SELECTED_OUTBOUND_SEGMENT'));
  }

  setSelectedOutboundFlight(flight) {
    this.storageService.secureStorage.setItem('SELECTED_OUTBOUND_SEGMENT', JSON.stringify(flight));
    this.selectedOutboundFlight = flight;
  }

  setSelectedFlight(flight) {
    this.storageService.secureStorage.setItem('SELECTED_FLIGHT', JSON.stringify(flight));
    this.selectedFlight = flight;
  }

  getSelectedFlight() {
    return JSON.parse(this.storageService.secureStorage.getItem('SELECTED_FLIGHT'));
    // return this.selectedFlight;
  }

  getBookedFlight() {
    return JSON.parse(this.storageService.secureStorage.getItem('BOOKED_FLIGHT'))
  }

  setBookedFlight(flight) {
    this.bookedFlight = flight;
    this.storageService.secureStorage.setItem('BOOKED_FLIGHT', JSON.stringify(flight))
  }

  setPassengers(passengers) {
    this.storageService.secureStorage.setItem('PASSENGERS', JSON.stringify(passengers));
    this.passengers = passengers;
  }

  getPassengers(): PassengerModel[] {
    return JSON.parse(this.storageService.secureStorage.getItem('PASSENGERS'));
  }

  getBookingResponse() {
    return JSON.parse(this.storageService.secureStorage.getItem('BOOKING_RESPONSE'));
  }

  setBookingResponse(resp) {
    this.storageService.secureStorage.setItem('BOOKING_RESPONSE', JSON.stringify(resp));
  }

  getSelectedCalendarType() {
    return JSON.parse(this.storageService.secureStorage.getItem('CALENDAR_TYPE'))
    return this.selectedCalendarType;
  }

  setSelectedCalendarType(calendarType) {
    this.storageService.secureStorage.setItem('CALENDAR_TYPE', JSON.stringify(calendarType))
    this.selectedCalendarType = calendarType
  }

  getSelectedBookedFlight() {
    return JSON.parse(this.storageService.secureStorage.getItem('BOOKING_SELECTED_RESPONSE'))
  }

  setSelectedBookedFlight(flight) {
    this.selectedBookedFlight = flight;
    this.storageService.secureStorage.setItem('BOOKING_SELECTED_RESPONSE', JSON.stringify(flight))
  }


  setSegments(segments) {
    this.segments = segments
    this.storageService.secureStorage.setItem("segments", JSON.stringify(this.segments));
  }

  getSegments() {
    return JSON.parse(this.storageService.secureStorage.getItem("segments"))
  }

  clearFlightSession() {
    //Clear Passengers
    if (localStorage.getItem("PASSENGERS")) {
      localStorage.removeItem("PASSENGERS")
    }

    //Clear Inbound Segment
    if (localStorage.getItem("SELECTED_INBOUND_SEGMENT")) {
      localStorage.removeItem("SELECTED_INBOUND_SEGMENT")
    }

    //Clear Outbound Segment
    if (localStorage.getItem("SELECTED_OUTBOUND_SEGMENT")) {
      localStorage.removeItem("SELECTED_OUTBOUND_SEGMENT")
    }

    //Clear Booking Response
    if (localStorage.getItem("BOOKING_RESPONSE")) {
      localStorage.removeItem("BOOKING_RESPONSE")
    }

    //Clear Booked Flight
    if (localStorage.getItem("BOOKED_FLIGHT")) {
      localStorage.removeItem("BOOKED_FLIGHT")
    }

    // Clear fare rules
    if (localStorage.getItem('FARE_RULES_RESP')) {
      localStorage.removeItem("FARE_RULES_RESP")
    }
  }

  getFareRulesResp() {
    return JSON.parse(this.storageService.secureStorage.getItem('FARE_RULES_RESP'))
  }

  setFareRulesResp(res) {
    this.storageService.secureStorage.setItem('FARE_RULES_RESP', JSON.stringify(res))
  }

  removeFareRulesResp() {
    this.storageService.secureStorage.removeItem('FARE_RULES_RESP')
  }

  getSelectedPaymentMethod() {
    return this.selectedPaymentMethod;
  }

  setSelectedPaymentMethod(method) {
    this.selectedPaymentMethod = method;
  }

  getSelectedPaymentMethodObj() {
    return JSON.parse(this.storageService.secureStorage.getItem('P_METHOD'))
  }

  setSelectedPaymentMethodObj(method) {
    this.storageService.secureStorage.setItem('P_METHOD', JSON.stringify(method))
  }

}
