import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AirportsService } from './airports.service';
const Zemen = require('zemen')
// import * as moment from 'moment';
import * as moment from "moment-timezone";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private _airportJsonURL = 'assets/json/flight_city_with_airport.json';
  private _airportShortCodeMapURL = 'assets/json/airportShortcodeToCityMap.json';
  private _airports = 'assets/json/carriers.json';
  private _countries = 'assets/json/countries.json';
  airports: any[] = [];
  month_name_amh = ['መስከረም', 'ጥቅምት', 'ኅዳር', 'ታህሣሥ', 'ጥር', 'የካቲት', 'መጋቢት', 'ሚያዝያ', 'ግንቦት', 'ሰኔ', 'ሐምሌ', 'ነሐሴ', 'ጳጉሜ'];

  constructor(private http: HttpClient, private airportService: AirportsService) {
    this.airportService.getAirports().subscribe(data => {
      this.airports = data
    })
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  getAddUpByPercentage = (totalPrice, percentage) => {
    const currency = totalPrice.substr(0, 3)
    const priceInNumber = parseFloat(totalPrice.replace(currency, ""))
    return 'ETB'+ Math.floor((priceInNumber + (priceInNumber*percentage/100)))
  }

  getEthiopianDate(date, dontNeedFormatting = false) {

    //For Headers only
    if (dontNeedFormatting) {

      date = Zemen.toEC(new Date(date))
      const monthIndex = date.getMonth()
      return `${this.month_name_amh[monthIndex]} ${date.getDate()}, ${date.getFullYear()}`
    }

    const hrs = moment(date).hours()

    if (hrs >= 0 && hrs <= 5) {

      date = Zemen.toEC(this.addDays(new Date(date), 1))

    } else {

      date = Zemen.toEC(new Date(date))
    }

    const monthIndex = date.getMonth()
    return `${this.month_name_amh[monthIndex]} ${date.getDate()}, ${date.getFullYear()}`

  }

  formatEthiopiaTime(strUtcDateTime) {

    let time = (moment(strUtcDateTime).tz('Africa/Nairobi')) //Force Format to Ethiopian TimeZone
    // let utcDate = new Date(strUtcDateTime);
    let localTime: any;
    let hr = time.hours();
    let min = time.minutes();

    localTime = (hr + 6) % 12;
    if (min > 59) {
      localTime = '-'
    } else if (hr >= 6 && hr <= 11) {
      if (hr == 6) {
        localTime = 12
      }
      localTime = 'ከጠዋቱ ' + this.formatNum(localTime) + ':' + this.formatNum(min)
    } else if (hr >= 12 && hr <= 18) {
      if (hr == 18) {
        localTime = 12
      }
      localTime = 'ከቀኑ ' + this.formatNum(localTime) + ':' + this.formatNum(min)
    } else if (hr >= 19 && hr <= 23) {
      localTime = 'ከምሽቱ ' + this.formatNum(localTime) + ':' + this.formatNum(min)
    } else if (hr == 0) {
      localTime = 'ከሌሊቱ ' + '6:' + this.formatNum(min)
    } else if (hr <= 5 && hr >= 1) {
      localTime = 'ከሌሊቱ ' + this.formatNum(localTime) + ':' + this.formatNum(min)
    } else {
      localTime = '-'
    }

    return localTime;
  }

  formatNum(num) {
    if (num < 10) {
      num = '0' + num;
    }
    return num;
  };

  public getAirports(): Observable<any> {
    return this.http.get(this._airportJsonURL);
  }

  public getAirportMapped(): Observable<any> {
    return this.http.get(this._airportShortCodeMapURL)
  }

  public getCarriers(): Observable<any> {
    return this.http.get(this._airports)
  }

  async getAirportFullName(IATACode) {
    if (this.airports) {
      let airport = this.airports.find(airport => airport.IATACode === IATACode)
      if (airport) {
        return airport.airport + ' (' + airport.IATACode + ')'
      } else {
        return ''
      }
    } else {
      return '';
    }
  }


  getDecimals(num) {
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return rminutes;
  }

  getInt(num) {
    return parseInt(num)
  }

  public getCountries(): Observable<any> {
    return this.http.get(this._countries)
  }

  formatISODateTime(date) {
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    return year + '-' + this.formatNum(monthIndex + 1) + '-' + this.formatNum(day)
      + 'T' + this.formatNum(hours) + ':' + this.formatNum(minutes) + ':' + this.formatNum(seconds);
  }

  public detectBrowser = () => {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else {
      return 'Unknown';
    }
  }
}
