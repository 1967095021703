import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { UapiService } from 'src/app/services/http/uapi.service';
import { GlobalService } from 'src/app/services/global.service';
import { PassengerModel } from 'src/app/models/passenger.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seat',
  templateUrl: './seat.component.html',
  styleUrls: ['./seat.component.css']
})
export class SeatComponent implements OnInit {

  bseats: any;//bussiness json
  eseats: any;//economy json
  blength: any; //bseats length
  selectionArr: string[] = []; //selection array 
  seatsLoaded = false;

  uniqueId: number = 0;

  //this variables will change later agin
  empty: boolean = true;
  empty1: boolean = false;

  selectedSeat: any;
  seats: any[];
  segments: any[] = [];

  passengers: PassengerModel[];
  seatsFetchFailure = false;

  @ViewChildren(MdePopoverTrigger) trigger: QueryList<MdePopoverTrigger>;

  constructor(private uapiService: UapiService, public globalService: GlobalService, private cdRef: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit(): void {

    this.segments = this.globalService.getSegments()
    this.getSeatMap()
  }

  getSeatMap() {
    this.seatsFetchFailure = false;
    this.uapiService.getSeatMap({ 'seatSegment': this.segments })
      .subscribe((resp: any) => {

        let respSegments = []
        Object.keys(resp.resp).map((key, i) => {
          respSegments[i] = resp.resp[key]
        })
        this.bseats = respSegments
        this.blength = this.bseats.length
        this.seatsLoaded = true;
      }, err => {
        this.seatsFetchFailure = true;
      })
    this.passengers = this.globalService.getPassengers()
  }

  setSelectedSeat(seat) {
    this.selectedSeat = seat;
  }

  increase() {
    this.uniqueId++;
    
  }
  

  closePopover() {

   
    this.trigger.toArray().map(item => {
      item.closePopover();
    })

  }
  //will update this method based on index parameter later
  takeSeat(passengerIndex, seat, from, segmentIndex) {
    if (!this.passengers[passengerIndex].seatAssigned) {
      this.passengers[passengerIndex].seatAssigned = []
    }
    this.bseats[segmentIndex].map(seat => {
      seat.seats.map(_seat => {
        if (_seat.passenger) {
          delete _seat.passenger
        }
      })
    })
    this.passengers[passengerIndex].seatAssigned[segmentIndex] = { from: from, seat: seat.SeatCode }
    this.empty = false;
    seat.passenger = { 'firstName': this.passengers[passengerIndex].firstName, 'lastName': this.passengers[passengerIndex].lastName, 'passengerIndex': passengerIndex }
    this.bseats[segmentIndex][this.bseats[segmentIndex].indexOf(seat)] = seat;
    this.globalService.setPassengers(this.passengers)
    this.cdRef.detectChanges()
  }

  chageSeat() {
    this.empty = true;
  }

  takeSeat1() {
    this.empty1 = false;
  }

  chageSeat1() {
    this.empty1 = true;
  }

  cancel() {
    this.router.navigate(['/artboard'])
  }

  goPassengerDetail() {
    this.globalService.setPassengers(this.passengers)
    this.router.navigate(['/artboard'])
  }
}
