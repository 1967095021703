import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AwashService {

  private url = 'http://localhost:3000/api/b';
  private ticketUrl = 'http://localhost:3800/api/t';
  private baseURL= 'http://localhost:3800/api/u';

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  public postBill(params) {
    return this.httpClient.post(this.url + '/awash/postBill', params, {});
  }

  public approveOtp(params) {
    return this.httpClient.post(this.url + '/awash/approveOTP', params, {});
  }

}
