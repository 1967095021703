import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { CompleterService, CompleterData, CompleterItem } from 'ng2-completer';
import { AirportsService } from 'src/app/services/airports.service';
import { SearchParamModel, LegModel } from 'src/app/models/search-param.model';
import * as moment from 'moment';
import { GlobalService } from 'src/app/services/global.service';
import * as $ from '../../../../assets/jquery/js/jquery-3.4.1.min.js'; // I faced issue in using jquery's popover

import { TranslateService } from '@ngx-translate/core';
import { TranslateUtilService } from 'src/app/services/translate.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UapiService } from 'src/app/services/http/uapi.service';
import { PassengerTypeFormComponent } from 'src/app/components/passenger-type-form/passenger-type-form.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FacebookService, InitParams } from 'ngx-facebook';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements AfterViewInit {
  locations = [1];
  length = 1;
  calendarType = 'GC';
  loadedIndexes = [0];

  public departureStr: string;
  public arrivalStr: string;
  public departureReturnStr: string;
  public arrivalReturnStr: string;
  public multiCityStr: any[] = [{ departure: '', arrival: '' }];
  departureDateGC: any = new Date();
  returnDateGC: any;

  departureDateEC: any = new Date();
  returnDateEC: any;

  //Auto-Complte Airport Data-Service
  public dataService: CompleterData;

  // Store Selected Airport Obj ( Except Multi-City)
  private departureAirportObj;
  private arrivalAirportObj;

  // Except Multi-City
  public departureDate: Date = new Date();
  public departureDate1: Date = new Date();
  public returnDate: Date = new Date();

  // multi-city dates
  departureDates_multi = [new Date()];

  //Passengers
  public adultPassengers: number = 1;
  public childPassengers: number = 0;
  public infantPassengers: number = 0;

  //Cabins
  public oneWayCabinClass: string = 'Economy';
  public returnCabinClass: string = 'Economy';
  public cabinClassAllowed: string[] = ['Economy', 'Business', 'First'];

  dateInterval: any;
  today = new Date();
  selectedTab = 0;

  fetchingHistory = false;

  availAirlines = [
    {
      name: '-',
      code: '-',
    },
    {
      name: 'Ethiopian Airlines',
      code: 'ET',
    },
    {
      name: 'Turkish Airlines',
      code: 'TK',
    },
    {
      name: 'Emirates',
      code: 'EK',
    },
    {
      name: 'Egypt Airlines',
      code: 'MS',
    },
    {
      name: 'Kenyan Airways',
      code: 'KQ',
    },
    {
      name: 'Fly Dubai',
      code: 'FZ',
    },
    {
      name: 'Saudi Airlines',
      code: 'SV',
    },
    {
      name: 'Quatar Airlines',
      code: 'QA',
    },
  ];

  filteredAirline: any;

  private mSearchParam = new SearchParamModel();
  @ViewChild('departure_date') departure_date: ElementRef;
  @ViewChild('departure_date_1') departure_date_1: ElementRef;
  @ViewChild('return_date') return_date: ElementRef;
  @ViewChildren('departure_date_multi') departure_date_multi: QueryList<any>;

  //Pager
  page: number = 1;
  pageSize: number = 10;

  //History
  public bookingHistory = [];

  //Error
  one_way_departure: boolean = false;
  one_way_arrival: boolean = false;

  return_departure: boolean = false;
  return_arrival: boolean = false;

  multi_city_departure: boolean = false;
  multi_city_arrival: boolean = false;

  constructor(
    public dialog: MatDialog,
    public completerService: CompleterService,
    private airportService: AirportsService,
    private globalService: GlobalService,
    private translate: TranslateService,
    private translateUtilService: TranslateUtilService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private uapiService: UapiService,
    private toastr: ToastrService,
    private facebookService: FacebookService
  ) {
    localStorage.setItem('login', 'true');
    this.airportService.getAirports().subscribe((data) => {
      this.dataService = completerService.local(
        data,
        'city,IATACode',
        'fullName'
      );
    });
  }

  ngAfterViewInit() {
    this.dateInterval = setInterval(() => {
      this.departureDate = new Date($(this.departure_date.nativeElement).val());
      this.departureDate1 = new Date(
        $(this.departure_date_1.nativeElement).val()
      );
      this.returnDate = new Date($(this.return_date.nativeElement).val());
    }, 500);

    console.log('DATE CHANGE  > ', this.departureDateGC);
  }

  ngOnInit(): void {
    this.initFacebookService();
    this.calendarType = this.globalService.getSelectedCalendarType();
    this.loadScript(0);
    this.fetchBookingHistory();
    this.cdRef.detectChanges();
  }

  private initFacebookService(): void {
    console.log('FACEBOOK INIT >>> ');
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }

  onTabChange(e) {
    this.selectedTab = e.index;
    if (this.loadedIndexes.indexOf(e.index) < 0) {
      this.loadScript(e.index);
      this.loadedIndexes.push(e.index);
    } else {
    }
  }

  public loadScript(index) {
    let node = document.createElement('script');
    node.src = `assets/jquery/js/pickerload${index}.js`;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  pastDateFilter = (d: Date | null): boolean => {
    //Check For Today Date
    if (moment(d).isSame(moment(), 'day')) {
      return true;
    }

    //Check For Past Date
    if (moment(d).isBefore(moment())) {
      return false;
    }

    return true;
  };

  inboundDateFilter = (d: Date | null): boolean => {
    if (this.returnDate !== undefined) {
      if (moment(d).isAfter(moment(this.returnDate))) {
        return false;
      }
    }

    //Check For Today Date
    if (moment(d).isSame(moment(), 'day')) {
      return true;
    }

    //Check For Past Date
    if (moment(d).isBefore(moment())) {
      return false;
    }

    return true;
  };

  outboundDateFilter = (d: Date | null): boolean => {
    if (this.departureDate !== undefined) {
      if (moment(d).isBefore(moment(this.departureDate))) {
        return false;
      }
    }

    //Check For Today Date
    if (moment(d).isSame(moment(), 'day')) {
      return true;
    }

    //Check For Past Date
    if (moment(d).isBefore(moment())) {
      return false;
    }

    return true;
  };

  addLocation() {
    this.locations.push(1);
    this.length = this.locations.length;
    this.multiCityStr.push({
      departure: '',
      arrival: '',
      departure_date: '',
      departureObj: '',
      arrivalObj: '',
    });
    this.loadScript(2);
  }

  removeLocation() {
    this.locations.pop();
    this.length = this.locations.length;
    this.multiCityStr.pop();
  }

  departureAirportSelected(selected: CompleterItem, isReturn: boolean = false) {
    if (selected) {
      this.departureAirportObj = selected;

      this.departureReturnStr = selected.title;
      this.departureStr = selected.title;
      this.multiCityStr[0].departureObj = selected;
      this.multiCityStr[0].departure = selected.title;
    }
  }

  arrivalAirportSelected(selected: CompleterItem, isReturn: boolean = false) {
    if (selected) {
      this.arrivalAirportObj = selected;
      this.arrivalReturnStr = selected.title;
      this.arrivalStr = selected.title;
      this.multiCityStr[0].arrivalObj = selected;
      this.multiCityStr[0].arrival = selected.title;
    }
  }

  departureAirportSelectedMulti(selected: CompleterItem, index: number) {
    if (selected) {
      this.departureAirportObj = selected;
    }
    this.multiCityStr[index].departureObj = selected;
    this.multiCityStr[index].departure = selected.title;
    if (index === 0) {
      this.departureReturnStr = selected.title;
      this.departureStr = selected.title;
    }
  }

  arrivalAirportSelectedMulti(selected: CompleterItem, index: number) {
    if (selected) {
      this.arrivalAirportObj = selected;
    }
    this.multiCityStr[index].arrivalObj = selected;
    this.multiCityStr[index].arrival = selected.title;
    if (index === 0) {
      this.arrivalReturnStr = selected.title;
      this.arrivalStr = selected.title;
    }
  }

  //Check invalidDate obj
  makeDate(date: Date) {
    return new Date(date.getTime()); //<--error here
  }

  isValidDate(d) {
    return d instanceof Date && !isNaN(Number(new Date(d)));
  }

  search(flightType: string) {
    //ERR Handle
    if (!this.departureAirportObj || !this.arrivalAirportObj) {
      if (flightType === 'one-way') {
        if (!this.departureAirportObj) {
          if (!this.arrivalAirportObj) {
            this.one_way_arrival = true;
          }
          this.one_way_departure = true;
        } else {
          this.one_way_arrival = true;
        }
      } else if (flightType === 'return') {
        if (!this.departureAirportObj) {
          this.return_departure = true;
        } else {
          this.return_arrival = true;
        }
      } else if (flightType === 'multi-city') {
        //Will be back for Multi-City
        if (!this.departureAirportObj) {
          this.multi_city_departure = true;
        } else {
          this.multi_city_arrival = true;
        }
      }
      return;
    } else {
      //Reset All
      this.one_way_departure = false;
      this.one_way_arrival = false;
      this.return_departure = false;
      this.return_arrival = false;
    }

    let valid = true;
    if (this.dateInterval) {
      clearInterval(this.dateInterval);
    } else {
    }
    this.globalService.clearFlightSession();
    if (flightType === 'one-way') {
      const leg = new LegModel();
      if (!this.departureAirportObj) {
        this.departureAirportObj =
          this.globalService.getDepartureSearchLocation();
      }
      leg.from = this.departureAirportObj.originalObject.IATACode;
      leg.to = this.arrivalAirportObj.originalObject.IATACode;
      if (this.calendarType === 'GC') {
        leg.departureDate = moment(this.departureDateGC).format('YYYY-MM-DD');
      } else {
        if (
          this.isValidDate(new Date($(this.departure_date.nativeElement).val()))
        ) {
          leg.departureDate = moment(
            new Date($(this.departure_date.nativeElement).val())
          ).format('YYYY-MM-DD');
        } else {
          this.toastr.warning(
            'Please select departure date from Ethiopian Calendar',
            'ERR'
          );
          return;
        }
      }
      // valid = false
      this.mSearchParam.legs.push(leg);
      this.mSearchParam.passengers.ADT = Number(this.adultPassengers);
      this.mSearchParam.passengers.CNN = Number(this.childPassengers);
      this.mSearchParam.passengers.INF = Number(this.infantPassengers);
      this.mSearchParam.cabins.push(this.oneWayCabinClass);
      this.mSearchParam.pricing.currency = 'ETB';

      //Set Airline
      this.mSearchParam['filterAirline'] = this.filteredAirline;

      this.globalService.setSearchParams(this.mSearchParam);
      this.globalService.setDepartureSearchLocation(this.departureAirportObj);
      this.globalService.setArrivalSearchLocation(this.arrivalAirportObj);
      this.globalService.setFlightType('one-way');

      if (
        this.globalService.LOCAL_AIRPORTS.indexOf(leg.from) > -1 &&
        this.globalService.LOCAL_AIRPORTS.indexOf(leg.to) > -1
      ) {
        this.globalService.isLocalFlight = true;
        this.openPassengerTypeForm({});
      } else {
        this.router.navigate(['/search']);
        this.globalService.isLocalFlight = false;
      }
    } else if (flightType === 'return') {
      const outbound_leg = new LegModel();
      outbound_leg.from = this.departureAirportObj.originalObject.IATACode;
      outbound_leg.to = this.arrivalAirportObj.originalObject.IATACode;
      const inbound_leg = new LegModel();
      inbound_leg.from = this.arrivalAirportObj.originalObject.IATACode;
      inbound_leg.to = this.departureAirportObj.originalObject.IATACode;
      inbound_leg.departureDate = moment(this.returnDate).format('YYYY-MM-DD');
      if (this.calendarType === 'EC') {
        if (
          !this.isValidDate(
            new Date($(this.departure_date_1.nativeElement).val())
          )
        ) {
          this.toastr.warning(
            'Please select departure date from Ethiopian Calendar',
            'ERR'
          );
          return;
        } else if (
          !this.isValidDate(new Date($(this.return_date.nativeElement).val()))
        ) {
          this.toastr.warning(
            'Please select return date from Ethiopian Calendar',
            'ERR'
          );
          return;
        }

        outbound_leg.departureDate = moment(
          new Date($(this.departure_date_1.nativeElement).val())
        ).format('YYYY-MM-DD');
        inbound_leg.departureDate = moment(
          new Date($(this.return_date.nativeElement).val())
        ).format('YYYY-MM-DD');
      } else {
        outbound_leg.departureDate = moment(this.departureDateGC).format(
          'YYYY-MM-DD'
        );
        inbound_leg.departureDate = moment(this.returnDateGC).format(
          'YYYY-MM-DD'
        );
      }
      this.mSearchParam.legs.push(outbound_leg);
      this.mSearchParam.legs.push(inbound_leg);
      this.mSearchParam.passengers.ADT = Number(this.adultPassengers);
      this.mSearchParam.passengers.CNN = Number(this.childPassengers);
      this.mSearchParam.passengers.INF = Number(this.infantPassengers);
      this.mSearchParam.cabins.push(this.returnCabinClass);
      this.mSearchParam.pricing.currency = 'ETB';

      //Set Airline
      this.mSearchParam['filterAirline'] = this.filteredAirline;

      this.globalService.setSearchParams(this.mSearchParam);
      this.globalService.setDepartureSearchLocation(this.departureAirportObj);
      this.globalService.setArrivalSearchLocation(this.arrivalAirportObj);
      this.globalService.setFlightType('return');
      if (
        this.globalService.LOCAL_AIRPORTS.indexOf(
          this.mSearchParam.legs[0].from
        ) > -1 &&
        this.globalService.LOCAL_AIRPORTS.indexOf(
          this.mSearchParam.legs[0].to
        ) > -1
      ) {
        this.openPassengerTypeForm({});
        this.globalService.isLocalFlight = true;
      } else {
        this.router.navigate(['/search']);
        this.globalService.isLocalFlight = false;
      }
    } else if (flightType === 'multi-city') {
      if (this.validateTimes(this.departure_date_multi.toArray())) {
        let index = 0;
        let isLocalFlight = false;
        this.globalService.isLocalFlight = false;
        this.departure_date_multi.forEach((date) => {
          const leg = new LegModel();
          leg.from =
            this.multiCityStr[index].departureObj.originalObject.IATACode;
          leg.to = this.multiCityStr[index].arrivalObj.originalObject.IATACode;
          if (
            this.globalService.LOCAL_AIRPORTS.indexOf(leg.from) > -1 &&
            this.globalService.LOCAL_AIRPORTS.indexOf(leg.to) > -1
          ) {
            isLocalFlight = true;
            this.globalService.isLocalFlight = true;
          }
          if (this.calendarType === 'GC') {
            leg.departureDate = moment(
              this.multiCityStr[index].departure_date
            ).format('YYYY-MM-DD');
          } else {
            leg.departureDate = moment(
              new Date($(date.nativeElement).val())
            ).format('YYYY-MM-DD');
          }
          this.mSearchParam.legs.push(leg);
          index += 1;
        });
        // this.multiCityStr.map((location, i) => {
        //   const leg = new LegModel();
        //   leg.from = location.departureObj.originalObject.IATACode
        //   leg.to = location.arrivalObj.originalObject.IATACode
        //   if (this.calendarType === 'GC') {
        //     leg.departureDate = moment(location.departure_date).format("YYYY-MM-DD");
        //   } else {

        //     leg.departureDate = moment(new Date($(this.departure_date_multi._results[i].nativeElement).val())).format('YYYY-MM-DD')
        //   }
        //   this.mSearchParam.legs.push(leg)
        // });
        this.mSearchParam.passengers.ADT = Number(this.adultPassengers);
        this.mSearchParam.passengers.CNN = Number(this.childPassengers);
        this.mSearchParam.passengers.INF = Number(this.infantPassengers);
        this.mSearchParam.cabins.push(this.oneWayCabinClass);
        this.mSearchParam.pricing.currency = 'ETB';

        //Set Airline
        this.mSearchParam['filterAirline'] = this.filteredAirline;

        this.globalService.setSearchParams(this.mSearchParam);
        this.globalService.setDepartureSearchLocation(
          this.multiCityStr[0].departureObj
        );
        this.globalService.setArrivalSearchLocation(
          this.multiCityStr[0].arrivalObj
        );
        if (this.multiCityStr.length > 1) {
          this.globalService.setFlightType('multi-city');
        } else {
          this.globalService.setFlightType('one-way');
        }
        if (isLocalFlight) {
          this.openPassengerTypeForm({});
          this.globalService.isLocalFlight = true;
        } else {
          this.router.navigate(['/search']);
          this.globalService.isLocalFlight = false;
        }
      } else {
        valid = false;
      }
    }
  }

  onCalendarTypeChange(calendarType) {
    this.globalService.setSelectedCalendarType(calendarType);
  }

  //Cabin Class Select Element Change Update
  oneWayCabinOnChange(cabinClass) {
    this.oneWayCabinClass = cabinClass;
  }

  returnCabinOnChange(cabinClass) {
    this.returnCabinClass = cabinClass;
  }

  onDateChange(e) {}

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  fetchBookingHistory() {
    this.fetchingHistory = true;
    this.uapiService.getHistory().subscribe(
      (res: any) => {
        this.bookingHistory = res;
        this.bookingHistory.sort((a, b) => b.entry_date - a.entry_date);

        this.bookingHistory = this.bookingHistory.filter((_flight) => {
          if (
            moment(_flight.entry_date)
              .startOf('day')
              .isAfter(moment().add(-2, 'days').startOf('day'))
          ) {
            return true;
          }
          return false;
        });

        this.fetchingHistory = false;
      },
      (err) => {
        this.bookingHistory = [];
        this.fetchingHistory = false;
      }
    );
  }

  getTime(date) {
    return new Date(date).getTime();
  }

  validateTimes(dates: any[]) {
    let valid = true;
    dates.map((cdate, index) => {
      cdate = cdate.nativeElement.value;
      if (index > 0) {
        // if (index === dates.length - 1) { index = index - 1 }
        let tempdates = dates.splice(0, index + 1);
        tempdates.map((_date) => {
          _date = _date.nativeElement.value;

          if (this.getTime(cdate) < this.getTime(_date)) {
            valid = false;
          }
        });
      }
    });
    return valid;
  }

  getDate(date) {
    return new Date(date);
  }

  openPNR(pnr) {
    window.open(`/artboard?identifier=${pnr}&type=0`);
    // this.router.navigate(['/artboard'], { queryParams: { identifier: pnr, type : 0} });
  }

  openPassengerTypeForm(flight) {
    // if (this.authService.getAuthUser().roles.canTicket && flight.PaymentStatus === 'paid') {
    const dialogRef = this.dialog.open(PassengerTypeFormComponent, {
      // width: '60%',
      maxHeight: '90vh',
      data: { flight: this.mSearchParam },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.mSearchParam.eFilterType = parseInt(result.selected);
        this.globalService.setSearchParams(this.mSearchParam);
        this.router.navigate(['/search']);
      }
    });
    // }
  }
}
