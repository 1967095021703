import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateUtilService } from 'src/app/services/translate.service';
import { MatRadioChange } from '@angular/material/radio';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  currentlySelectedOption: Number = 0; // 0 - PNR , 1 - Name . 3 - Ticket Number
  universalRecordValue: any = "";
  selectedCalendarType: string;
  selectedLanguageType : string;

  constructor(private translate: TranslateService, private translateUtilService: TranslateUtilService,
    public authService: AuthService, public globalService: GlobalService, public router: Router, private storageService: StorageService) {
    translateUtilService.setDefaultLanguage(storageService.secureStorage.getItem('SELECTED_LANG') || 'en')
  }

  ngOnInit(): void {
    this.selectedCalendarType = this.globalService.getSelectedCalendarType()
  }

  radioChange(event: MatRadioChange) {
    this.currentlySelectedOption = event.value
  }

  onLanguageChange(){
    this.translateUtilService.switchLanguage(this.selectedLanguageType);
  }

  search(tooltip, greeting: string) {
    if (this.universalRecordValue === "") {
      this.showToolTip(tooltip, greeting)
      return;
    } else {

      //PNR
      if (this.currentlySelectedOption === 0) {

        if (this.universalRecordValue.length != 6) {
          this.showToolTip(tooltip, greeting)
          return;
        }

      }

      //Ticket
      if (this.currentlySelectedOption === 3) {
        if (this.universalRecordValue.length != 13) {
          this.showToolTip(tooltip, greeting)
          return;
        }
      }
    }
    this.router.navigate(['/artboard'], { queryParams: { identifier: this.universalRecordValue, type : this.currentlySelectedOption} });
  }

  onCalendarTypeChange() {
    this.globalService.setSelectedCalendarType(this.selectedCalendarType)
  }

  showToolTip(tooltip, greeting: string) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open({ greeting });
    }
  }
  
  onLogout() {
    this.authService.logout();
    this.router.navigate(['signin'])
  }
  register() {
    this.authService.register();
    this.router.navigate(['register'])
  }

}
