<app-header></app-header>
<div class="container whole-search">
    <div class="row">
        <div class="col-md-3">
            <div class="card">
                <div class="card-body">
                    <div class="label-box">
                        <div>
                            <img src="../assets/params/images/logo/filter.png" class="flight-labels-img" width="30"
                                height="30" alt="">
                            <span class="flight-labels">{{ 'Filter.title' | translate }}</span>
                        </div>
                        <i class="fa fa-chevron-down md:hidden" *ngIf="isCollapsedFilters"
                            (click)="isCollapsedFilters = !isCollapsedFilters"></i>
                        <i class="fa fa-chevron-up md:hidden" *ngIf="!isCollapsedFilters"
                            (click)="isCollapsedFilters = !isCollapsedFilters"></i>
                    </div>
                    <div [class]="{'block':isCollapsedFilters, 'hidden': (innerWidth <= 764 && isCollapsedFilters)}"
                        class="md:block">
                        <div class="stopsLabel">
                            <span class="stops-label-text">{{ 'SearchR.stops' | translate }}</span>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row stop-item">
                                    <div class="col-md-2 offset-md-1">
                                        <mat-checkbox [(ngModel)]="direct" class="example-margin"
                                            (change)="directFlightFilterChange($event)">{{ 'SearchR.direct' | translate
                                            }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="row stop-item">
                                    <div class="col-md-2 offset-md-1">
                                        <mat-checkbox [(ngModel)]="one_stop" class="example-margin"
                                            (change)="oneStopFlightFilterChange($event)">1 {{ 'SearchR.stop' | translate
                                            }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="row stop-item">
                                    <div class="col-md-2 offset-md-1">
                                        <mat-checkbox [(ngModel)]="two_stop" class="example-margin"
                                            (change)="twoStopFlightFilterChange($event)">2 {{ 'SearchR.stop' | translate
                                            }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="stopsLabel">
                            <span class="stops-label-text">{{ 'Filter.price' | translate }}</span>
                        </div>
                        <div class="row" *ngIf="isLoaded">
                            <div class="col-md-6"><span class="price-text">{{ formatMoney("ETB" + minimumTicketPrice)
                                    }}</span></div>
                            <div class="col-md-6"><span class="price-text">{{ formatMoney("ETB" + maximumTicketPrice)
                                    }}</span></div>
                            <div class="col-md-12">
                                <ng5-slider [(value)]="minimumTicketPrice" [(highValue)]="maximumTicketPrice"
                                    [options]="sliderOptions(slider)" (valueChange)="priceSliderValueChanged($event)">
                                </ng5-slider>
                            </div>
                        </div>
                        <div class="stopsLabel">
                            <span class="stops-label-text">{{ 'Filter.airlines' | translate }}</span>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row stop-item">
                                    <div class="col-md-2 offset-md-1">
                                        <mat-checkbox *ngFor="let carrier of airlines;let i = index"
                                            [(ngModel)]=" airlines[i]['checked'] "
                                            (change)="airlineFilterChange(airlines[i]['checked'], i)"
                                            class="example-margin">{{ getCarrierName(carrier['iatacode']) }}
                                        </mat-checkbox>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-12 show-label-box">
                    <span class="results-label">Showing results for</span>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-10 row">
                            <div class="col location-info-box">
                                <div class="">
                                    <span class="location-info">{{ this.departureAirportObj.originalObject.city }} (
                                        {{ this.departureAirportObj.originalObject.IATACode }} )</span>
                                </div>
                                <div class="">
                                    <span class="date-info-detail"
                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'">{{ getDepartureDate()
                                        }}</span>
                                    <span class="date-info-detail"
                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">{{
                                        utilService.getEthiopianDate(getDepartureDate(), true) }}</span>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <span class="date-info-detail"> to </span>
                            </div>
                            <div class="col date-info-box">
                                <div class="">
                                    <span class="location-info">{{ this.arrivalAirportObj.originalObject.city }} (
                                        {{ this.arrivalAirportObj.originalObject.IATACode }} )</span>
                                </div>
                                <div class="">
                                    <span class="date-info-detail"
                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'">{{ getArrivalDate()
                                        }}</span>
                                    <span class="date-info-detail"
                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">{{
                                        utilService.getEthiopianDate(getArrivalDate() , true) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button class="btn edit-btn" routerLink="/"><i class="fa fa-edit"></i><span
                                    class="edit-text">{{'Passenger.edit' | translate}}</span></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="top-content">
                <div class="container">
                    <div id="carousel-box" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner row w-100 mx-auto" role="listbox">
                            <div *ngFor="let airline of airlines; let i = index"
                                class="carousel-item  col-sm-6 col-md-3 col-lg-3" [class.active]="i === 0">
                                <img src="https://pics.avs.io/100/50/{{airline['iatacode']}}.png"
                                    class="img-fluid mx-auto d-block" alt="{{airline['iatacode']}}">
                            </div>

                        </div>
                        <a class="carousel-control-prev" href="#carousel-box" role="button" data-slide="prev">
                            <i class="fa fa-arrow-circle-left arrow-airlines-icon-left"></i>
                        </a>
                        <a class="carousel-control-next" href="#carousel-box" role="button" data-slide="next">
                            <i class="fa fa-arrow-circle-right arrow-airlines-icon-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="hasFlights">
                <div class="card flight-card md:hidden"
                    *ngIf="selectedOutBoundFlight && (innerWidth <= 764) && flightType == 'return'">
                    <div class="card-body custom-card-body">
                        <span><i class="text-bold fa fa-check-circle text-green-700"></i>
                            {{'SearchR.selectedd' | translate}}</span>
                        <div class="row item-bottom relative">
                            <div class="col-md-6"><img
                                    src="https://pics.avs.io/100/50/{{selectedOutBoundFlight.platingCarrier}}.png"
                                    width="100px"></div>
                            <div class="col-md-6 align-right">
                                <span class="total-price-text">{{formatMoney(selectedOutBoundFlight.totalPrice,
                                    selectedOutBoundFlight)}}</span>
                                <br /><span style="font-size: 12px;font-style: italic;">Total
                                    Price</span>
                            </div>
                            <span
                                class="absolute right-1 text-red-700 text-sm bg-red-50 rounded-full hover:bg-gray-200 p-1 px-3 cursor-pointer"
                                (click)="resetSearchSelection()">
                                <i class="fa fa-window-close"></i><span> REMOVE</span>
                            </span>
                        </div>
                        <div class="sm:flex-row flex-col flex flex-wrap text-center">
                            <div class="col sm:w-full xs:w-full text-center item-bottom padded-y">
                                <div class="col-md-6"><span class="book-detail-info">{{
                                        getAirportFullName(selectedOutBoundFlight.from) }}</span>
                                </div>
                                <div class="col-md-6"><span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                        class="book-detail-info">{{formatToDay(formatTimedZone(selectedOutBoundFlight.segments[0].departure))}}</span>
                                    <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                        class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(selectedOutBoundFlight.segments[0].departure))}}</span>
                                </div>
                                <div class="col-md-5">
                                    <span class="book-detail-info-1"
                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'">
                                        {{
                                        formatToTime(formatTimedZone(selectedOutBoundFlight.segments[0].departure))
                                        }}
                                    </span>
                                    <span class="book-detail-info-1"
                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                        {{
                                        utilService.formatEthiopiaTime(formatTimedZone(selectedOutBoundFlight.segments[0].departure))
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="row sm:w-full xs:w-full text-center item-bottom sm:py-3 xs:py-3">

                                <div class="col-md-2">
                                    <span style="font-size : 18px;font-weight : 700">To</span>
                                </div>

                            </div>
                            <div class="row sm:w-full xs:w-full text-center item-large-bottom">
                                <div class="col-md-6 align-right text-center"><span class="book-detail-info">{{
                                        getAirportFullName(selectedOutBoundFlight.to) }}</span>
                                </div>
                                <div class="col-md-6 align-right text-center"><span
                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                        class="book-detail-info">{{formatToDay(formatTimedZone(selectedOutBoundFlight.segments[selectedOutBoundFlight.segments.length
                                        - 1].arrival))}}</span>
                                    <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                        class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(selectedOutBoundFlight.segments[selectedOutBoundFlight.segments.length
                                        - 1].arrival))}}</span>
                                </div>
                                <div class="col-md-5 align-right text-center">
                                    <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                        class="book-detail-info-1">{{formatToTime(formatTimedZone(selectedOutBoundFlight.segments[selectedOutBoundFlight.segments.length
                                        - 1].arrival))}}</span>
                                    <span class="book-detail-info-1"
                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                        {{
                                        utilService.formatEthiopiaTime(formatTimedZone(selectedOutBoundFlight.segments[selectedOutBoundFlight.segments.length
                                        - 1].arrival)) }}
                                    </span>
                                    <span class="book-detail-info-1">
                                        {{ timeZoneDiff(
                                        selectedOutBoundFlight.segments[selectedOutBoundFlight.segments.length
                                        -
                                        1].arrival
                                        , selectedOutBoundFlight.segments[0].departure) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row padded-y">
                            <div class="col-md-4">
                                <span
                                    class="book-detail-info">{{getFlightType((selectedOutBoundFlight.segments))}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-search-box md:hidden text-center"
                    *ngIf="selectedOutBoundFlight && flightType == 'return'">
                    <h3 class="p-3 pb-1">{{'SearchR.selectin' | translate}}</h3>
                    <div class="col-md-6 pb-3 text-center" *ngIf="flightType == 'return' ">
                        <span>{{this.arrivalAirportObj.originalObject.city}}</span>
                        <span> - to - </span>
                        <span>{{this.departureAirportObj.originalObject.city}}</span>
                    </div>
                </div>
                <div class="card card-search-box"
                    *ngFor="let flight of flightSearchResult | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ; let i = index; trackBy: trackByIndex">
                    <div class="row">
                        <div class="col-md-5 offset-md-7">
                            <div class="d-flex flex-row justify-content-right">
                                <!-- <div class="d-flex flex-row price-box"> -->
                                <!-- <div class="price-sub-box">
                                        <span class="total-price-text">{{formatMoney(flight.totalPrice)}}</span>
                                    </div> -->
                                <!-- </div> -->
                                <!-- <div class="d-flex flex-row"><a class="btn btn-light custom-book-btn"
                                        (click)="goPassengerDetails()"
                                        *ngIf="selectedFlight && (selectedFlight.id === flight.id)">Book
                                        Now</a></div> -->
                            </div>
                            <!-- <a class="btn btn-light col-md-2 custom-book-btn" routerLink="/artboard">Book Now</a> -->
                        </div>
                    </div>
                    <div class="card-body"
                        [class]="{'hidden':(innerWidth <= 764 && ((selectedOutBoundFlight && selectedOutboundFlightParentIndex !== flight.id) && flightType === 'return'))}">
                        <div class="row">
                            <div *ngIf="(!selectedOutBoundFlight || innerWidth > 764 || flightType !== 'return')"
                                class="lg:w-1/2"><span
                                    class="book-location-name">{{this.departureAirportObj.originalObject.city}}</span><span
                                    class="book-location-name"> - to - </span><span
                                    class="book-location-name">{{this.arrivalAirportObj.originalObject.city}}</span>
                            </div>
                            <div class="col-md-6 book-return-box"
                                *ngIf="flightType == 'return' && ((innerWidth > 764 || selectedOutBoundFlight))"><span
                                    class="book-location-name">{{this.arrivalAirportObj.originalObject.city}}</span><span
                                    class="book-location-name"> - to -
                                </span><span
                                    class="book-location-name">{{this.departureAirportObj.originalObject.city}}</span>
                            </div>

                            <div class="w-full" *ngIf="flightType == 'return'">

                            </div>
                        </div>
                        <div class="row cards-row-box">
                            <div class="col-md-6"
                                *ngIf="(((innerWidth <= 764 && !selectedOutBoundFlight) || innerWidth > 764) && flightType == 'return') || flightType !== 'return'">
                                <div class="card flight-card"
                                    *ngFor="let outboundFlight of flight.directions[0]; let outboundIndex = index; trackBy : trackByIndex">
                                    <div class="card-body custom-card-body">
                                        <div class="row item-bottom">
                                            <div class="col-md-6"><img
                                                    src="https://pics.avs.io/100/50/{{outboundFlight.platingCarrier}}.png"
                                                    width="100px"></div>
                                            <div class="col-md-6 align-right">
                                                <span class="total-price-text">{{formatMoney(flight.totalPrice)}}</span>
                                                <br /><span style="font-size: 12px;font-style: italic;">Total
                                                    Price</span>
                                            </div>
                                        </div>
                                        <div class="md:grid md:grid-cols-3">
                                            <div class="row item-bottom padded-y text-center w-full">
                                                <div class="w-full"><span class="book-detail-info">{{
                                                        getAirportFullName(outboundFlight.from) }}</span>
                                                </div>
                                                <div class="w-full"><span
                                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                        class="book-detail-info">{{formatToDay(formatTimedZone(outboundFlight.segments[0].departure))}}</span>
                                                    <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                        class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(outboundFlight.segments[0].departure))}}</span>
                                                </div>

                                                <div class="w-full">
                                                    <span class="book-detail-info-1"
                                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'">
                                                        {{
                                                        formatToTime(formatTimedZone(outboundFlight.segments[0].departure))
                                                        }}
                                                    </span>
                                                    <span class="book-detail-info-1"
                                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                                        {{
                                                        utilService.formatEthiopiaTime(formatTimedZone(outboundFlight.segments[0].departure))
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                class="row item-bottom w-full text-center flex flex-wrap justify-center align-center">
                                                <span style="font-size : 18px;font-weight : 700">To</span>
                                            </div>
                                            <div class="row item-large-bottom w-full text-center">
                                                <div class="w-full align-right text-center"><span
                                                        class="book-detail-info">{{
                                                        getAirportFullName(outboundFlight.to) }}</span>
                                                </div>
                                                <div class="w-full align-right text-center"><span
                                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                        class="book-detail-info">{{formatToDay(formatTimedZone(outboundFlight.segments[outboundFlight.segments.length
                                                        - 1].arrival))}}</span>
                                                    <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                        class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(outboundFlight.segments[outboundFlight.segments.length
                                                        - 1].arrival))}}</span>
                                                </div>
                                                <div class="w-full align-right text-center">
                                                    <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                        class="book-detail-info-1">{{formatToTime(formatTimedZone(outboundFlight.segments[outboundFlight.segments.length
                                                        - 1].arrival))}}</span>
                                                    <span class="book-detail-info-1"
                                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                                        {{
                                                        utilService.formatEthiopiaTime(formatTimedZone(outboundFlight.segments[outboundFlight.segments.length
                                                        - 1].arrival)) }}
                                                    </span>
                                                    <span class="book-detail-info-1">
                                                        {{ timeZoneDiff(
                                                        outboundFlight.segments[outboundFlight.segments.length -
                                                        1].arrival
                                                        , outboundFlight.segments[0].departure) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row padded-y">
                                            <div class="col-md-4">
                                                <button class="btn default flight-detail-btn"
                                                    [mdePopoverTriggerFor]="popover" mdePopoverTriggerOn="click"><span
                                                        class="flight-detail"><i class="fa fa-info"></i>
                                                        {{ 'SearchR.flightdetail' | translate }}</span></button>
                                                <mde-popover #popover="mdePopover">
                                                    <mat-card class="popover-card">
                                                        <mat-card-content>
                                                            <div class="row popover-info-box-1">
                                                                <div class="col-md-12">
                                                                    <span class="popover-title">Journey Details</span>
                                                                </div>
                                                            </div>
                                                            <div class="row popover-info-box-1">
                                                                <div class="col-md-12">
                                                                    <span
                                                                        class="popover-content">{{getFlightType((outboundFlight.segments))}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row popover-info-box-1"
                                                                *ngFor="let segment of outboundFlight.segments;let segmentIndex = index">
                                                                <div class="col-md-6">
                                                                    <div class="row">
                                                                        <div class="col-md-9">
                                                                            <span
                                                                                *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                                                class="book-detail-info">{{formatToDay(formatTimedZone(segment.departure))}}<br />{{formatToTime(formatTimedZone(segment.departure))}}</span>
                                                                            <span
                                                                                *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                                                class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(segment.departure))}}<br />{{utilService.formatEthiopiaTime(formatTimedZone(segment.departure))}}</span>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <i class="fa fa-plane rotate-plane"
                                                                                aria-hidden="true"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <span class="popover-content">{{
                                                                        getAirportFullName(segment.from) }}</span>
                                                                </div>
                                                                <div class="col-md-6 box-empty">
                                                                    <div class="row">
                                                                        <div class="col-md-2 offset-md-8 box-divider sm:h-1">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <span class="popover-content">{{
                                                                        getCarrierName(segment.airline) }}</span><br>
                                                                    <span class="popover-content">Flight |
                                                                        {{segment.flightNumber}}</span><br>
                                                                    <span class="popover-content">AirCraft Type |
                                                                        {{segment.plane[0]}} </span><br>
                                                                    <span class="popover-content bolder">Class |
                                                                        {{segment.bookingClass}}</span>
                                                                </div>
                                                                <br />
                                                                <div class="col-md-12 text-centered"
                                                                    *ngIf="segmentIndex != outboundFlight.segments.length - 1 ">
                                                                    <span style="font-style: italic"> Transit Time :
                                                                        {{
                                                                        getTransitTime(outboundFlight.segments[segmentIndex
                                                                        + 1].departure , segment.arrival)}}
                                                                    </span><br />
                                                                </div>
                                                                <div class="col-md-6"
                                                                    *ngIf="segmentIndex == outboundFlight.segments.length - 1">
                                                                    <div class="row">
                                                                        <span
                                                                            *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                                            class="book-detail-info">{{formatToDay(formatTimedZone(segment.arrival))}}
                                                                            {{formatToTime(formatTimedZone(segment.arrival))}}</span>
                                                                        <span
                                                                            *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                                            class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(segment.arrival))}}
                                                                            {{utilService.formatEthiopiaTime(formatTimedZone(segment.arrival))}}</span>
                                                                        <div class="col-md-3">
                                                                            <i class="fa fa-plane rotate-plane"
                                                                                aria-hidden="true"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6"
                                                                    *ngIf="segmentIndex == outboundFlight.segments.length - 1">
                                                                    <span class="popover-content">{{
                                                                        getAirportFullName(segment.to) }}</span>
                                                                </div>
                                                                <br />
                                                            </div>
                                                            <div class="row popover-info-box-1 padded-y">
                                                                <div>
                                                                    <img src="../assets/params/icons/baggage_icon.png"
                                                                        class="baggage_icon" width="17px">
                                                                    <span class="popover-content">{{'SearchR.baggageal'
                                                                        | translate }}
                                                                        <b>{{outboundFlight.segments[0].baggage[0].amount}}
                                                                            {{outboundFlight.segments[0].baggage[0].units}}(s)</b></span>
                                                                </div>
                                                            </div>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </mde-popover>
                                                <br>
                                                <span
                                                    class="book-detail-info">{{getFlightType((outboundFlight.segments))}}</span>
                                            </div>
                                            <div class="col-md-8 align-right">
                                                <button class="btn btn-outline-dark"
                                                    (click)="selectOutBoundFlight(outboundFlight, outboundIndex, flight, i)"
                                                    [ngClass]="{  'selected-flight-btn' : (selectedOutboundFlightIndex === outboundIndex) && compareSegments(outboundFlight, selectedOutBoundFlight) }"
                                                    *ngIf="!(flightType === 'one-way' && selectedFlight && (selectedFlight.id === flight.id) && (selectedOutboundFlightIndex === outboundIndex))">{{'SearchR.selectout'
                                                    | translate}}</button>
                                                <a class="btn btn-light custom-book-btn" (click)="goPassengerDetails()"
                                                    *ngIf="flightType === 'one-way' && selectedFlight && (selectedFlight.id === flight.id && (selectedOutboundFlightIndex === outboundIndex))">Book
                                                    Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6"
                                *ngIf="(flightType === 'return' || flightType === 'multi-city') && ((innerWidth <= 764 && selectedOutBoundFlight) || innerWidth > 764) &&
                                ((selectedOutBoundFlight && selectedOutboundFlightParentIndex === flight.id && innerWidth <= 764) || !selectedOutBoundFlight || innerWidth > 764)">
                                <div class="card flight-card"
                                    *ngFor="let inboundFlight of flight.directions[1];let inboundIndex = index; trackBy: trackByIndex">
                                    <div class="card-body custom-card-body">
                                        <div class="row item-bottom">
                                            <div class="col-md-6"><img
                                                    src="https://pics.avs.io/100/50/{{inboundFlight.platingCarrier}}.png"
                                                    width="100px"></div>
                                            <div class="col-md-6 align-right">
                                                <span class="total-price-text">+ ETB 0</span>
                                            </div>
                                        </div>
                                        <div class="md:grid md:grid-cols-3">
                                            <div class="row item-bottom padded-y text-center w-full">
                                                <div class="w-full text-center"><span class="book-detail-info">{{
                                                        getAirportFullName(inboundFlight.from) }}</span>
                                                </div>
                                                <div class="w-full text-center">
                                                    <span class="book-detail-info-1"
                                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'">
                                                        {{
                                                        formatToTime(formatTimedZone(inboundFlight.segments[0].departure))
                                                        }}
                                                    </span>
                                                    <span class="book-detail-info-1"
                                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                                        {{
                                                        utilService.formatEthiopiaTime(formatTimedZone(inboundFlight.segments[0].departure))
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="w-full text-center"><span
                                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                        class="book-detail-info">{{formatToDay(formatTimedZone(inboundFlight.segments[0].departure))
                                                        }}</span>
                                                    <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                        class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(inboundFlight.segments[0].departure))
                                                        }}</span>
                                                </div>
                                            </div>
                                            <div
                                                class="row item-bottom w-full text-center flex flex-wrap justify-center align-center">
                                                <span style="font-size: 18px;font-weight : 700">To</span>
                                            </div>
                                            <div class="row item-large-bottom w-full text-center">
                                                <div class="w-full"><span class="book-detail-info">{{
                                                        getAirportFullName(inboundFlight.to) }}</span>
                                                </div>
                                                <div class="w-full">
                                                    <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                        class="book-detail-info-1">{{formatToTime(formatTimedZone(inboundFlight.segments[inboundFlight.segments.length
                                                        - 1].arrival))}}</span>
                                                    <span class="book-detail-info-1"
                                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                                        {{
                                                        utilService.formatEthiopiaTime(formatTimedZone(inboundFlight.segments[inboundFlight.segments.length
                                                        - 1].arrival)) }}
                                                    </span>
                                                    <span class="book-detail-info-1">
                                                        {{ timeZoneDiff(
                                                        inboundFlight.segments[inboundFlight.segments.length -
                                                        1].arrival ,
                                                        inboundFlight.segments[0].departure) }}</span>
                                                </div>
                                                <div class="w-full"><span
                                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                        class="book-detail-info">{{formatToDay(formatTimedZone(inboundFlight.segments[inboundFlight.segments.length
                                                        - 1].arrival))}}</span>
                                                    <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                        class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(inboundFlight.segments[inboundFlight.segments.length
                                                        - 1].arrival)) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row padded-y center-vertical">
                                            <div class="col-md-4">
                                                <button class="btn default padded-y flight-detail-btn"
                                                    [mdePopoverTriggerFor]="popover" mdePopoverTriggerOn="click"><span
                                                        class="flight-detail"><i class="fa fa-info"></i>
                                                        {{ 'SearchR.flightdetail' | translate }}</span></button>
                                                <mde-popover #popover="mdePopover">
                                                    <mat-card class="popover-card">
                                                        <mat-card-content>
                                                            <div class="row popover-info-box-1">
                                                                <div class="col-md-12">
                                                                    <span class="popover-title">Journey Details</span>
                                                                </div>
                                                            </div>
                                                            <div class="row popover-info-box-1">
                                                                <div class="col-md-12">
                                                                    <span
                                                                        class="popover-content">{{getFlightType((inboundFlight.segments))}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row popover-info-box-1"
                                                                *ngFor="let segment of inboundFlight.segments;let segmentIndex = index">
                                                                <div class="col-md-6">
                                                                    <div class="row">
                                                                        <div class="col-md-9">

                                                                            <span
                                                                                *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                                                class="book-detail-info">{{formatToDay(formatTimedZone(segment.departure))}}<br />{{formatToTime(formatTimedZone(segment.departure))}}</span>
                                                                            <span
                                                                                *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                                                class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(segment.departure))}}<br />{{utilService.formatEthiopiaTime(formatTimedZone(segment.departure))}}</span>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <i class="fa fa-plane rotate-plane"
                                                                                aria-hidden="true"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <span class="popover-content">{{
                                                                        getAirportFullName(segment.from) }}</span>
                                                                </div>
                                                                <div class="col-md-6 box-empty">
                                                                    <div class="row">
                                                                        <div class="col-md-2 offset-md-8 box-divider sm:py-3">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 py-2 md:py-0 md:px-9">
                                                                    <span class="popover-content">{{
                                                                        getCarrierName(segment.airline) }}</span><br>
                                                                    <span class="popover-content">Flight |
                                                                        {{segment.flightNumber}}</span><br>
                                                                    <span class="popover-content">AirCraft Type |
                                                                        {{segment.plane[0]}} </span><br>
                                                                    <span class="popover-content bolder">Class |
                                                                        {{segment.bookingClass}}</span>
                                                                </div>
                                                                <br />
                                                                <div class="col-md-12 text-centered"
                                                                    *ngIf="segmentIndex != inboundFlight.segments.length - 1 ">
                                                                    <span style="font-style: italic"> Transit Time :
                                                                        {{
                                                                        getTransitTime(inboundFlight.segments[segmentIndex
                                                                        + 1].departure , segment.arrival)}}
                                                                    </span><br />
                                                                </div>

                                                                <div class="col-md-6"
                                                                    *ngIf="segmentIndex == inboundFlight.segments.length - 1">
                                                                    <span
                                                                        *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                                        class="book-detail-info">{{formatToDay(formatTimedZone(segment.departure))}}
                                                                        {{formatToTime(segment.departure)}}</span>
                                                                    <span
                                                                        *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                                        class="book-detail-info">{{utilService.getEthiopianDate(formatTimedZone(segment.departure))
                                                                        }}
                                                                        {{utilService.formatEthiopiaTime(formatTimedZone(segment.departure))
                                                                        }}</span>

                                                                </div>
                                                                <div class="col-md-6"
                                                                    *ngIf="segmentIndex == inboundFlight.segments.length - 1">

                                                                    <span class="popover-content">{{
                                                                        getAirportFullName(segment.to) }}</span>

                                                                </div>
                                                                <br />
                                                            </div>

                                                            <div class="row popover-info-box-1">
                                                                <div>
                                                                    <img src="../assets/params/icons/baggage_icon.png"
                                                                        class="baggage_icon" width="17px">
                                                                    <span class="popover-content">{{'SearchR.baggageal'
                                                                        | translate }}
                                                                        {{inboundFlight.segments[0].baggage[0].amount}}
                                                                        {{inboundFlight.segments[0].baggage[0].units}}(s)</span>
                                                                </div>
                                                            </div>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </mde-popover>
                                                <br>
                                                <span
                                                    class="book-detail-info">{{getFlightType((inboundFlight.segments))}}</span>
                                            </div>
                                            <div class="col-md-8 align-right"><button class="btn btn-outline-dark"
                                                    (click)="selectInBoundFlight(inboundFlight, inboundIndex, flight, i)"
                                                    [disabled]='!((selectedOutboundFlightIndex >= 0)) || selectedOutboundFlightParentIndex !== flight.id'
                                                    [ngClass]="{  'selected-flight-btn' : (selectedInboundFlightIndex === inboundIndex) && compareSegments(inboundFlight, selectedInBoundFlight) 
                                                                && (selectedOutboundFlightIndex >= 0) && (selectedOutboundFlightParentIndex === flight.id)}"
                                                    *ngIf="!(flightType !== 'one-way' && selectedFlight && (selectedFlight.id === flight.id)
                                                                && (selectedInboundFlightIndex === inboundIndex))">{{'SearchR.selectin'
                                                    | translate}}</button>
                                                <a class="btn btn-light custom-book-btn" (click)="goPassengerDetails()"
                                                    *ngIf="flightType !== 'one-way' && selectedFlight && (selectedFlight.id === flight.id)
                                                            && (selectedInboundFlightIndex === inboundIndex)">Book
                                                    Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="!isLoaded">
                <div class="card card-search-box" *ngFor="let skeleton of skeletonResults">
                    <div class="row">
                        <div class="col-md-5 offset-md-7">
                            <div class="d-flex flex-row justify-content-right">
                                <!-- <div class="d-flex flex-row price-box">
                                    <div class="price-sub-box">
                                        <ngx-skeleton-loader [theme]="{
                                          width: '50%',
                                          'border-radius': '0',
                                          height: '10px'
                                        }"></ngx-skeleton-loader>
                                    </div>
                                </div> -->
                                <!-- <div class="d-flex flex-row col-md-4"><a class="btn btn-light custom-book-btn">
                                        <ngx-skeleton-loader [theme]="{
                                          width: '50px',
                                          'border-radius': '0',
                                          height: '10px'
                                        }"></ngx-skeleton-loader>
                                    </a></div> -->
                            </div>
                            <!-- <a class="btn btn-light col-md-2 custom-book-btn" routerLink="/artboard">Book Now</a> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="gravatar-title">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                          width: '100%',
                                          'border-radius': '0',
                                          height: '15px'
                                        }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">

                            </div>
                            <div class="col-md-4 book-return-box" *ngIf="flightType === 'return'">
                                <div class="gravatar-title">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                          width: '100%',
                                          'border-radius': '0',
                                          height: '15px'
                                        }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row cards-row-box">
                            <div class="col-md-6">
                                <div class="card flight-card" *ngFor="let outboundFlight of bookingHistory">
                                    <div class="card-body custom-card-body">
                                        <div class="row item-bottom">
                                            <div class="col-md-6">
                                                <div class="gravatar">
                                                    <ngx-skeleton-loader appearance="circle"
                                                        [theme]="{ width: '50px', height: '50px' }">
                                                    </ngx-skeleton-loader>
                                                </div>
                                            </div>
                                            <div class="col-md-2">

                                            </div>
                                            <div class="col-md-4 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row item-bottom">
                                            <div class="col-md-6">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px',
                                                          'margin-top' : '10px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px',
                                                          'margin-top' : '10px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row item-bottom">
                                            <div class="col-md-4">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">

                                            </div>
                                            <div class="col-md-4 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row item-large-bottom">
                                            <div class="col-md-6">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '10px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">

                                            </div>
                                            <div class="col-md-6 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="flightType === 'return'">
                                <div class="card flight-card" *ngFor="let outboundFlight of bookingHistory">
                                    <div class="card-body custom-card-body">
                                        <div class="row item-bottom">
                                            <div class="col-md-6">
                                                <div class="gravatar">
                                                    <ngx-skeleton-loader appearance="circle"
                                                        [theme]="{ width: '50px', height: '50px' }">
                                                    </ngx-skeleton-loader>
                                                </div>
                                            </div>
                                            <div class="col-md-2">

                                            </div>
                                            <div class="col-md-4 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row item-bottom">
                                            <div class="col-md-6">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px',
                                                          'margin-top' : '10px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px',
                                                          'margin-top' : '10px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row item-bottom">
                                            <div class="col-md-4">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">

                                            </div>
                                            <div class="col-md-4 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row item-large-bottom">
                                            <div class="col-md-6">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '10px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">

                                            </div>
                                            <div class="col-md-6 align-right">
                                                <div class="gravatar-title">
                                                    <div>
                                                        <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

            </div>


            <div class="noFlightFound col-md-12" *ngIf="!hasFlights">
                <div class="flex-col"
                    style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <img class="img-rounded" src="../../../../assets/imgs/No-Flight-Found-Eng.png" />
                    <button class="btn" style="border: solid 1px;
                    margin-top: 40px;
                    width: 150px;
                    height: 50px;" routerLink="/"><i class="fa fa-angle-left"></i><span class="edit-text">Go
                            Back</span></button>
                </div>
            </div>

            <div class="pagination-custom pagination justify-content-center">
                <ngb-pagination *ngIf="flightSearchResult" [(page)]="page" [pageSize]="selectedOutBoundFlight && flightType == 'return' ? flightSearchResultNoTouch[selectedOutboundFlightParentIndex].directions[1].length : pageSize"
                    [collectionSize]="selectedOutBoundFlight && flightType == 'return' ? flightSearchResultNoTouch[selectedOutboundFlightParentIndex].directions[1].length : flightSearchResult.length">
                </ngb-pagination>
            </div>

        </div>

    </div>
</div>

<script>

    $('#carousel-box').on('slide.bs.carousel', function (e) {
        /*
            CC 2.0 License Iatek LLC 2018 - Attribution required
        */
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 4;
        var totalItems = $('.carousel-item').length;

        if (idx >= totalItems - (itemsPerSlide - 1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction == "left") {
                    $('.carousel-item').eq(i).appendTo('.carousel-inner');
                }
                else {
                    $('.carousel-item').eq(0).appendTo('.carousel-inner');
                }
            }
        }
    });

</script>