import { Component, OnChanges, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UapiService } from 'src/app/services/http/uapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cybersource',
  templateUrl: './cybersource.component.html',
  styleUrls: ['./cybersource.component.css']
})
export class CybersourceComponent implements OnInit {
  selectedFlight;
  exchangeRate;
  price;
  data;
  url;
  pnr;
  pnrSearchBookingResponse: any;
  safe: SafeResourceUrl;
  merchant_ref_no = 'B' + new Date().getTime();

  constructor(public sanitizer: DomSanitizer, private router: Router, public globalService: GlobalService, private uapiService: UapiService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.uapiService.getExchangeRate('USD')
      .subscribe((result: any) => {
        this.exchangeRate = result
        console.log("exchange------", this.exchangeRate);
      }, err => {

      })
    $('iframe').on('load', e => console.log('E: ', e.target.contentWindow))
    console.log('ON INIT')
    this.selectedFlight = this.globalService.getSelectedFlight();
    console.log('CS DIALOG> SELECTED FLIGHT:', this.selectedFlight)
    this.price = this.globalService.getUSDPrice();
    console.log('CS DIALOG> PRICE:', this.price)
    this.data = this.globalService.getCybersourceData();
    console.log('CS DIALOG> DATA:', this.data)
    this.url = `https://bmsthyhuxretxtw.guzogo.com/cybersource-new/payment/payment_form.php?amount=${this.price}&${new URLSearchParams(this.data).toString()}`;

    this.safe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // let paymentReq = {
    //   reference_id: this.merchant_ref_no
    // }
    // this.uapiService.CSPaymentStatus(paymentReq)
    // .subscribe((result: any) => {
    //   if (result && result.orderInfo && result.orderInfo.form_value) {
    //     let formValue = JSON.parse(result.orderInfo.form_value)
    //     if (formValue.decision === "ACCEPT") {
    //       this.uapiService.updatePaymentStatus({
    //         updateParams: {
    //           pnr: this.pnr,
    //           PaymentMethod: 'Visa',
    //           status: "paid",
    //           paymentStatusUpdatedBy: 'system'
    //         }
    //       })
    //       console.log("Response resp > ", this.pnrSearchBookingResponse[0])
    //       this.globalService.setBookingResponse(this.pnrSearchBookingResponse[0])
    //         this.selectedFlight.pnr = this.pnrSearchBookingResponse[0].pnr
    //         this.globalService.setSelectedFlight(this.pnrSearchBookingResponse[0])
    //         this.globalService.setBookedFlight(this.pnrSearchBookingResponse[0])
    //         this.router.navigate(['/success'])
    //     }
    // }},err=>{
    //   console.log("errr]]]",err);
    // })

  }

  dismiss() {
    this.dialog.closeAll()
  }

  URLChangeHandler(iframe): void {
    const changes = '';
    console.log('EVENT: ', iframe.contentWindow)
    return;
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes) {
      console.log("object", changes);
      let paymentReq = {
        reference_id: this.merchant_ref_no
      }
      this.uapiService.CSPaymentStatus(paymentReq)
        .subscribe((result: any) => {
          if (result && result.orderInfo && result.orderInfo.form_value) {
            let formValue = JSON.parse(result.orderInfo.form_value)
            if (formValue.decision === "ACCEPT") {
              this.uapiService.updatePaymentStatus({
                updateParams: {
                  pnr: this.pnrSearchBookingResponse[0].pnr,
                  PaymentMethod: 'Visa',
                  status: "paid",
                  paymentStatusUpdatedBy: 'system'
                }
              })
              console.log("Response resp > ", this.pnrSearchBookingResponse[0])
              this.globalService.setBookingResponse(this.pnrSearchBookingResponse[0])
              this.selectedFlight.pnr = this.pnrSearchBookingResponse[0].pnr
              this.globalService.setSelectedFlight(this.pnrSearchBookingResponse[0])
              this.globalService.setBookedFlight(this.pnrSearchBookingResponse[0])
              this.router.navigate(['/success'])
            }
          }
        }, err => {
          console.log("errr]]]", err);
        })
    }

  }

}
