<app-header></app-header>
<div class="container loader" *ngIf="loading">
    <img src="../assets/gif/loading.gif" alt="loading... please wait">
</div>
<div class="container whole-artboard-page lg:w-3/4" *ngIf="loaded && !loading">
    <div class="alert alert-danger alert-dismissible fade show" *ngIf="bookingError" role="alert">
        <strong class="reservation-danger-text">Booking failed. Please check if all necessary fields are filled</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-danger fade show" *ngIf="isDuplicateFailure" role="alert">
        <strong class="reservation-danger-text">Booking failed due to duplicate booking.<br />Please contact +251-7473 for assitance to book your flight.</strong>
    </div>
    <div class="alert alert-success fade show" *ngIf="isRequestSubmitted" role="alert">
      <strong class="reservation-danger-text">You Request Successfully Submitted<br />Please contact +251-7473 for assistance to book your flight.</strong>
  </div>
    <div class="alert alert-danger fade show" *ngIf="paymentError" role="alert">
        <strong class="reservation-danger-text">Payment failed.<br />Your Booking has been made successfully, but your
            payment has failed<br /><br />Your PNR code is {{selectedFlight.pnr}}<br /><br />
        Please contact +251-7473 for further assistance</strong>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="label-box">
                        <img src="../assets/params/images/logo/airline_front.png" class="flight-labels-img" width="40"
                            height="40" alt="">
                        <span class="flight-labels">Flights Itinerary</span>


                    </div>
                </div>
            </div>
            <div class="row" *ngIf="routerParams.identifier === 'undefined'">
                <div class="col-md-12">
                    <!-- <h5 *ngIf="i === 0">{{ 'SearchR.selectedd' | translate }}</h5>
                    <h5 *ngIf="i === 1">{{ 'SearchR.selectedr' | translate }}</h5> -->
                    <div class="card selected-fligh-card" *ngFor="let segment of segments">
                        <div class="card-body">
                            <div class="grid grid-cols-3">
                                <div class="col location-name-row">
                                    <div class="col-md-4 ">
                                        <span class="fligh-location-name">{{ getAirportFullName(segment.from) }}</span>
                                    </div>
                                    <div class="col-md-4 offset-md-1">
                                        <span class="flight-times-1"
                                            *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                            {{ utilService.formatEthiopiaTime(segment.departure) }}</span>
                                        <span class="flight-times-1"
                                            *ngIf="globalService.getSelectedCalendarType() === 'GC'">
                                            {{ formatToTime(formatTimedZone(segment.departure))}}</span>
                                    </div>
                                    <div class="col-md-4 p-10">
                                        <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                            class="flight-dates-1">{{ utilService.getEthiopianDate(segment.departure)
                                            }}</span>
                                        <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                            class="flight-dates-1">{{ segment.departure | date: 'EEE, MMM d, yyyy'
                                            }}</span>
                                    </div>
                                    <div class="col-md-4 justify-text-center">
                                        <span class="journey-time" *ngIf="segment.duration">Journey Time:
                                            {{ getInt(segment.duration[0]/60) }} hr {{ getDecimals(segment.duration[0])
                                            }}
                                            m</span>
                                    </div>
                                </div>
                                <div class="col location-name-row">
                                    <div class="col-md-2">
                                        <img [src]='"https://pics.avs.io/200/50/"+ segment.airline +".png"'
                                            class="img-fluid mx-auto d-block" alt="img1">
                                    </div>
                                </div>
                                <div class="col ">
                                    <div class="col-md-4 ">
                                        <span class="right-texts fligh-location-name">{{ getAirportFullName(segment.to)
                                            }}</span>
                                    </div>
                                    <div class="col-md-4 ">
                                        <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                            class="right-texts flight-times-2">{{
                                            utilService.formatEthiopiaTime(segment.arrival) }}</span>
                                        <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                            class="right-texts flight-times-2">{{
                                            formatToTime(formatTimedZone(segment.arrival))}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="grid grid-cols-3">
                                <div class="col-md-4 p-10">
                                    <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                        class="right-texts flight-dates-2">{{
                                        utilService.getEthiopianDate(segment.arrival) }}</span>
                                    <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                        class="right-texts flight-dates-2">{{ segment.arrival | date: 'EEE, MMM d, yyyy'
                                        }}</span>
                                </div>
                            </div>
                            <div class="grid grid-cols-3 direct-box">
                                <div class="col-md-4 ">
                                    <span class="direct-text">Class {{ segment.bookingClass }}</span>
                                </div>
                                <div class="col-md-2 ">
                                    <span></span>
                                </div>
                                <div class="col-md-4 ">
                                    <span class="right-texts direct-text">Status: Not Booked</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="routerParams.identifier !== 'undefined'">
                <div class="col-md-12">
                    <div class="card selected-fligh-card" *ngIf="!urRecordLoaded">
                        <div class="card-body">
                            <div class="grid grid-cols-3 location-name-row">
                                <div class="col-md-4 ">
                                    <ngx-skeleton-loader [theme]="{
                                                          width: '100%',
                                                          'border-radius': '0',
                                                          height: '15px'
                                                        }"></ngx-skeleton-loader>
                                </div>
                                <div class="col-md-2 ">
                                    <div class="gravatar">
                                        <ngx-skeleton-loader
                                            [theme]="{ width: '100px', height: '80px','border-radius':'10px','margin-left' : '20px' }">
                                        </ngx-skeleton-loader>
                                    </div>
                                </div>
                                <div class="col-md-4 ">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                            width: '100%',
                                            'border-radius': '0',
                                            height: '15px'
                                          }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="col-md-4 ">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                            width: '100%',
                                            'border-radius': '0',
                                            height: '15px'
                                          }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                                <div class="col-md-2 ">
                                </div>
                                <div class="col-md-4">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                            width: '100%',
                                            'border-radius': '0',
                                            height: '15px'
                                          }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="col-md-4 p-10">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                            width: '100%',
                                            'border-radius': '0',
                                            height: '15px'
                                          }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                                <div class="col-md-4 justify-text-center">

                                </div>
                                <div class="col-md-4 p-10">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                            width: '100%',
                                            'border-radius': '0',
                                            height: '15px'
                                          }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-3 direct-box">
                                <div class="col-md-4 ">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                            width: '100%',
                                            'border-radius': '0',
                                            height: '15px'
                                          }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                                <div class="col-md-2 ">
                                    <span></span>
                                </div>
                                <div class="col-md-4 ">
                                    <div>
                                        <ngx-skeleton-loader [theme]="{
                                            width: '100%',
                                            'border-radius': '0',
                                            height: '15px'
                                          }"></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card selected-fligh-card" *ngIf="urRecordLoaded">
                        <div class="card-body" *ngFor="let segment of segments">
                            <div class="card-body">
                                <div class="col-md-2">
                                    <img [src]='"https://pics.avs.io/200/50/"+ segment.airline +".png"'
                                        class="img-fluid mx-auto d-block" alt="img1">
                                </div>
                                <div class="md:grid md:grid-cols-3 text-center row md:pt-0 pt-1 w-full">
                                    <div class="md:flex-col text-center flex-row flex flex-wrap w-full py-1 md:py-0">
                                        <div class="w-full row flex text-center md:py-1">
                                            <span class="text-center md:text-left w-full">{{
                                                getAirportFullName(segment.from)
                                                }}</span>
                                        </div>
                                        <div class="w-full row flex text-center md:py-1">
                                            <span class="w-full text-center md:text-left"
                                                *ngIf="globalService.getSelectedCalendarType() === 'EC'">
                                                {{ utilService.formatEthiopiaTime(segment.departure) }}</span>
                                            <span class="w-full text-center md:text-left"
                                                *ngIf="globalService.getSelectedCalendarType() === 'GC'">
                                                {{ formatToTime(formatTimedZone(segment.departure))}}</span>
                                        </div>
                                        <div class="w-full row flex text-center md:py-1">
                                            <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                class="w-full text-center md:text-left">{{
                                                utilService.getEthiopianDate(segment.departure)
                                                }}</span>
                                            <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                class="w-full text-center md:text-left">{{ segment.departure | date:
                                                'EEE, MMM d, yyyy'
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="md:flex-col text-center w-full flex-row flex flex-wrap md:py-3 py-3">
                                        <div class="text-center w-full">
                                            <span class="journey-time text-center w-full"
                                                *ngIf="segment.duration">Journey Time:
                                                {{ getInt(segment.duration[0]/60) }} hr {{
                                                getDecimals(segment.duration[0]) }}
                                                m</span>
                                        </div>
                                    </div>
                                    <div class="md:flex-col text-center flex-row flex flex-wrap w-full">
                                        <div class="flex w-full row flex-wrap text-center md:py-1">
                                            <span class="sm:text-center md:text-right w-full">{{
                                                getAirportFullName(segment.to)}}</span>
                                        </div>
                                        <div class="flex w-full row flex-wrap text-center md:py-1">
                                            <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                class="w-full md:text-right flight-times-2">{{
                                                utilService.formatEthiopiaTime(segment.arrival) }}</span>
                                            <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                class="w-full md:text-right flight-times-2">{{
                                                formatToTime(formatTimedZone(segment.arrival))}}</span>
                                        </div>
                                        <div class="flex w-full row flex-wrap text-center md:py-1">
                                            <span *ngIf="globalService.getSelectedCalendarType() === 'EC'"
                                                class="w-full md:text-right flight-dates-2">{{
                                                utilService.getEthiopianDate(segment.arrival) }}</span>
                                            <span *ngIf="globalService.getSelectedCalendarType() === 'GC'"
                                                class="w-full md:text-right flight-dates-2">{{ segment.arrival | date:
                                                'EEE, MMM
                                                d, yyyy'
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-col">
                                <div class="w-full grid grid-cols-3">
                                </div>
                                <div class="w-full grid grid-cols-3 direct-box">
                                    <div class=" ">
                                        <span class="direct-text">Class {{ segment.bookingClass }}</span>
                                    </div>
                                    <div class="col-md-2 ">
                                        <span></span>
                                    </div>
                                    <div class=" ">
                                        <span class="right-texts direct-text">Status: Not Booked</span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="fare-rule-box" *ngIf="!this.routerParams.identifier ">
        <div class="row">
            <div class="col-md-2">
                <span class="fare-rule-box-text" (click)="openFareRules()">{{ 'SearchR.farerules' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="loaded">
        <div class="card-body" style='width: 100%;'>
            <div class="row">
                <div class="col-md-12">
                    <div class="row passenger-detail-box">
                        <div class="col-md-12">
                            <div class="label-box">
                                <img src="../assets/params/images/logo/passenger_baggage.png" class="flight-labels-img"
                                    width="40" height="40" alt="">
                                <span class="flight-labels">{{ 'Passenger.title' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="bottom-line"></div>
                        </div>
                    </div>
                    <div class="card card-custom" *ngIf="universalRecordPassengers.length == 0 && this.urRecordLoaded ">
                        <div class="card-body" *ngFor="let passenger of passengers; let i = index;">
                            <div class="row passenger-detail-group-box">
                                <div class="col-md-12"><span class="adult-label">{{ ('Home.' +
                                        ageCategoryMap[passenger.ageCategory].toLowerCase()) | translate }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <form>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputFirst" class="form-label">{{ 'Passenger.firstname' |
                                                    translate }}</label>
                                                <input type="text" [ngModel]='passenger.firstName'
                                                    (ngModelChange)="onFieldChange(i, 'firstName', $event)"
                                                    name="firstName" class="form-control input-size" id="inputFirst"
                                                    placeholder="First name"
                                                    onkeypress="return /[a-z]/i.test(event.key)"
                                                    style="text-transform:uppercase">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputMiddle" class="form-label">{{ 'Passenger.middle_name' |
                                                    translate }}</label>
                                                <input type="text" [ngModel]='passenger.middleName'
                                                    (ngModelChange)="onFieldChange(i, 'middleName', $event)"
                                                    name="middleName" class="form-control input-size" id="inputMiddle"
                                                    placeholder="Middle name"
                                                    onkeypress="return /[a-z]/i.test(event.key)"
                                                    style="text-transform:uppercase">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputLast" class="form-label">{{ 'Passenger.lastname' |
                                                    translate }}</label>
                                                <input type="text" [ngModel]='passenger.lastName'
                                                    (ngModelChange)="onFieldChange(i, 'lastName', $event)"
                                                    name="lastName" class="form-control input-size" id="inputLast"
                                                    placeholder="Last Name" onkeypress="return /[a-z]/i.test(event.key)"
                                                    style="text-transform:uppercase">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputPhone" class="form-label">{{ 'Passenger.phone' |
                                                    translate }}</label>
                                                <input type="text" [ngModel]='passenger.phoneNumber'
                                                    (ngModelChange)="onFieldChange(i, 'phoneNumber', $event)"
                                                    name="phoneNumber" class="form-control input-size"
                                                    id="inputPassword4" placeholder="___">
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="inputEmail4" class="form-label">{{ 'Passenger.email' |
                                                    translate }}</label>
                                                <input type="email" [ngModel]='passenger.email'
                                                    (ngModelChange)="onFieldChange(i, 'email', $event)" name="email"
                                                    class="form-control input-size" id="inputEmail4"
                                                    placeholder="john.doe@gmail.com">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                        </div>
                                        <br>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <!-- <label for="inputBirthday" class="form-label">Birth Date</label> -->
                                                <!-- <input type="text" [ngModel]='passenger.birthDate'
                                                    (ngModelChange)="onFieldChange(i, 'birthDate', $event)"
                                                    name="birthDate" class="form-control input-size" id="inputBirthday"
                                                    placeholder="Birth Date"> -->
                                                <mat-form-field id="fromlocation">
                                                    <input matInput [matDatepicker]="picker" placeholder="Birth Date"
                                                        readonly="readonly" (click)="picker.open()"
                                                        [ngModel]='passenger.birthDate'
                                                        (ngModelChange)="onFieldChange(i, 'birthDate', $event)"
                                                        name="birthDate"
                                                        [min]="birthDateRange[passenger.ageCategory].max"
                                                        [max]="birthDateRange[passenger.ageCategory].min">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker
                                                        [startAt]="birthDateRange[passenger.ageCategory].min" #picker>
                                                    </mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputDocumentType"
                                                    class="form-label">{{'Passenger.nationality' | translate}}</label>
                                                <select id="inputDocumentType{{i}}" [ngModel]='passenger.nationality'
                                                    (ngModelChange)="onFieldChange(i, 'nationality', $event)"
                                                    name="nationality" class="form-control input-size">
                                                    <option>Choose...</option>
                                                    <option *ngFor="let country of countries"
                                                        [attr.selected]="country.code == 'ET' ? true : null"
                                                        [attr.value]="country.code">
                                                        {{country.name }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label for="inputState" class="form-label">{{ 'Passenger.sex' |
                                                    translate }}</label>
                                                <select id="inputState" [ngModel]='passenger.gender'
                                                    (ngModelChange)="onFieldChange(i, 'gender', $event)" name="gender"
                                                    class="form-control">
                                                    <option>Choose...</option>
                                                    <option selected value="M">{{ 'Passenger.male' | translate }}
                                                    </option>
                                                    <option value="F">{{ 'Passenger.female' | translate }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-row d-flex flex-row divider">
                                            <div class="divider-line d-flex flex-row"></div>
                                            <span *ngIf="collapse" class="close-btn d-flex flex-row"
                                                (click)="expand()"><i class="fa fa-angle-up"></i></span>
                                            <span *ngIf="!collapse" class="close-btn d-flex flex-row"
                                                (click)="expand()"><i class="fa fa-angle-down"></i></span>
                                        </div>
                                        <div *ngIf="collapse" class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputDocumentType" class="form-label">Document Type</label>
                                                <select id="inputDocumentType" class="form-control input-size">
                                                    <option>Choose...</option>
                                                    <option>I - ID Card</option>
                                                    <option selected>P - Passport</option>
                                                    <option>A - ID Card</option>
                                                    <option>C - ID Card</option>
                                                    <option>IP - Passport</option>
                                                    <option>AC - Tripulaçao Membro Cert.</option>
                                                    <option>F - Nao Std Aprocado Doc</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6" class="form-label">
                                                <label for="inputDocumentNumber">Document Number</label>
                                                <input type="text" class="form-control" id="inputDocumentNumber"
                                                    [ngModel]='passenger.passNumber'
                                                    (ngModelChange)="onFieldChange(i, 'passNumber', $event)"
                                                    name="passNumber">
                                            </div>
                                        </div>
                                        <div *ngIf="collapse" class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputDocumentCountry{{i}}" class="form-label">Document Issue
                                                    Country</label>
                                                <select id="inputDocumentCountry" class="form-control input-size"
                                                    [ngModel]='passenger.passCountry'
                                                    (ngModelChange)="onFieldChange(i, 'passCountry', $event)"
                                                    name="passCountry">
                                                    <option selected>Choose...</option>
                                                    <option *ngFor="let country of countries"
                                                        [selected]="country.code === 'ET'" [value]="country.code">
                                                        {{ country.name }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <!-- <label for="inputExpireDate" class="form-label">Expiration Date</label>
                                                <input type="text" class="form-control input-size" id="inputExpireDate"> -->
                                                <mat-form-field id="fromlocation">
                                                    <input matInput [matDatepicker]="expDatePicker"
                                                        placeholder="Expiration Date" readonly="readonly"
                                                        (click)="expDatePicker.open()"
                                                        [ngModel]='passenger.passExpireDate'
                                                        (ngModelChange)="onFieldChange(i, 'passExpireDate', $event)"
                                                        [min]='passExpDate' name="passExpireDate">
                                                    <mat-datepicker-toggle matSuffix [for]="expDatePicker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker [startAt]="passExpDate" #expDatePicker>
                                                    </mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- pcr -->
                            <div class="card payment-method condition-box"
                                *ngIf="authUserRole && checkIfFlightIsInternational() && passenger.ageCategory !== 'INF'">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="label-box" id="i">
                                                <mat-slide-toggle
                                                    [disabled]="isPnrRecordSuccessful && this.routerParams.type ==='0'"
                                                    [(ngModel)]="pcrTest[i]" color="primary">
                                                    <!-- <img src="../assets/params/images/logo/test-tube.png" class="flight-labels-img"
                                                id="test-tube-img" width="40" height="40" alt=""> -->
                                                    <span class="flight-labels">PCR Test</span>
                                                </mat-slide-toggle>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="pcrTest[i]">
                                        <div class="card accept-card col-6">
                                            <div class="card-body">
                                                <div class="row accept-title-box">
                                                    <div class="col-md-10">
                                                        <div class="row">
                                                            <div class="col-md-10">
                                                                <p>Covid-19 PCR test at Wudassie Diagnostic Center</p>
                                                                <img id="diagnosis-center-logo"
                                                                    src="../assets/params/images/logo/Wudassie-logo.png"
                                                                    alt="">
                                                                <p class="row">
                                                                    <img id="location-marker"
                                                                        src="../assets/params/images/logo/location-marker.png" />
                                                                    <span>
                                                                        Churchill Road,
                                                                        Pass Theodros Square,
                                                                        MK Building
                                                                        Addis Ababa <br /> +251-940-101010</span>
                                                                </p>
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul style="list-style: none;" class="card-body">
                                            <div *ngFor="let info of pcr_packages; let i=index">
                                                <li>
                                                    <div class="row stop-item">
                                                        <div class="col-md-2 offset-md-1">
                                                            <mat-checkbox class="example-margin"
                                                                (change)="addupPCRPrice($event, info,passenger, i)">
                                                                {{ info.description }}
                                                            </mat-checkbox>
                                                        </div>
                                                        <!-- <div class="row stop-item">
                                          <div class="col-md-2 offset-md-1">
                                              <mat-checkbox [(ngModel)]="direct" class="example-margin"
                                                  (change)="directFlightFilterChange($event)">{{ 'SearchR.direct' | translate }}
                                              </mat-checkbox>
                                          </div>
                                      </div> -->
                                                    </div>
                                                </li>

                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- Kulbi Gebriel -->
                            <div class="card payment-method condition-box"
                                *ngIf="KulbiGebrielPackages && authUserRole && checkIsDireDawaFlight() && passenger.ageCategory !== 'INF'">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="label-box" id="i">
                                                <mat-slide-toggle
                                                    [disabled]="isPnrRecordSuccessful && this.routerParams.type ==='0'"
                                                    [(ngModel)]="KulbiGebrielPackage[i]"
                                                    (ngModelChange)="addupKulbiGebriel($event, passenger, i)"
                                                    color="primary">
                                                    <!-- <img src="../assets/params/images/logo/test-tube.png" class="flight-labels-img"
                                                id="test-tube-img" width="40" height="40" alt=""> -->
                                                    <span class="flight-labels">Kulbi Gebriel Package</span>
                                                </mat-slide-toggle>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="KulbiGebrielPackage[i]">
                                        <div class="card accept-card col-6">
                                            <div class="card-body">
                                                <div class="row accept-title-box">
                                                    <div class="col-md-10">
                                                        <div class="row">
                                                            <div class="col-md-10">
                                                                <p>{{KulbiGebrielPackages.description}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-custom" *ngIf="urRecordLoaded && universalRecordPassengers.length > 0">
                        <div class="card-body" *ngFor="let passenger of universalRecordPassengers; let i = index;">
                            <div class="row passenger-detail-group-box">
                                <div class="col-md-12"><span class="adult-label">{{
                                        ageCategoryMap[passenger.ageCategory] ? (('Home.' +
                                        ageCategoryMap[passenger.ageCategory].toLowerCase()) | translate) : "" }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <form>
                                        <div class="form-row">
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputFirst{{i}}" class="form-label">{{ 'Passenger.firstname'
                                                    | translate }}</label>
                                                <input type="text" [ngModel]='passenger.firstName'
                                                    (ngModelChange)="onFieldChange(i, 'firstName', $event)"
                                                    name="firstName" class="form-control input-size"
                                                    id="inputFirst{{i}}" placeholder="First name">
                                            </div>
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputMiddle{{i}}" class="form-label">{{
                                                    'Passenger.middle_name' | translate }}</label>
                                                <input type="text" [ngModel]='passenger.middleName'
                                                    (ngModelChange)="onFieldChange(i, 'middleName', $event)"
                                                    name="middleName" class="form-control input-size"
                                                    id="inputMiddle{{i}}" placeholder="Middle name">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputLast{{i}}" class="form-label">{{ 'Passenger.lastname' |
                                                    translate }}</label>
                                                <input type="text" [ngModel]='passenger.lastName'
                                                    (ngModelChange)="onFieldChange(i, 'lastName', $event)"
                                                    name="lastName" class="form-control input-size" id="inputLast{{i}}"
                                                    placeholder="Last Name">
                                            </div>
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputPhone{{i}}" class="form-label">{{ 'Passenger.phone' |
                                                    translate }}</label>
                                                <input type="text" [ngModel]='passenger.phoneNumber'
                                                    (ngModelChange)="onFieldChange(i, 'phoneNumber', $event)"
                                                    name="phoneNumber" class="form-control input-size"
                                                    id="inputPhone{{i}}" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputEmail{{i}}" class="form-label">{{ 'Passenger.email' |
                                                    translate }}</label>
                                                <input type="email" [ngModel]='passenger.email'
                                                    (ngModelChange)="onFieldChange(i, 'email', $event)" name="email"
                                                    class="form-control input-size" id="inputEmail{{i}}"
                                                    placeholder="john.doe@gmail.com">
                                            </div>
                                            <div class="form-group col-md-6 col-sm 12">
                                                <!-- <label for="inputBirthday" class="form-label">Birth Date</label> -->
                                                <!-- <input type="text" [ngModel]='passenger.birthDate'
                                                    (ngModelChange)="onFieldChange(i, 'birthDate', $event)"
                                                    name="birthDate" class="form-control input-size" id="inputBirthday"
                                                    placeholder="Birth Date"> -->
                                                <mat-form-field id="fromlocation{{i}}" *ngIf="passenger.birthDate">
                                                    <input matInput [matDatepicker]="picker" placeholder="Birth Date"
                                                        readonly="readonly" (click)="picker.open()"
                                                        [ngModel]='passenger.birthDate'
                                                        (ngModelChange)="onFieldChange(i, 'birthDate', $event)"
                                                        name="birthDate"
                                                        [min]="birthDateRange[passenger.ageCategory].max"
                                                        [max]="birthDateRange[passenger.ageCategory].min">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker
                                                        [startAt]="birthDateRange[passenger.ageCategory].min" #picker>
                                                    </mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputDocumentType{{i}}"
                                                    class="form-label">{{'Passenger.nationality' | translate}}</label>
                                                <select id="inputDocumentType{{i}}" [ngModel]='passenger.passCountry'
                                                    (ngModelChange)="onFieldChange(i, 'passCountry', $event)"
                                                    name="passCountry" class="form-control input-size">
                                                    <option selected>Choose...</option>
                                                    <option *ngFor="let country of countries"
                                                        [selected]="country.code === 'ET'" [value]="country.code">
                                                        {{ country.name }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-4 col-sm 12">
                                                <label for="inputState{{i}}" class="form-label">{{ 'Passenger.sex' |
                                                    translate }}</label>
                                                <select id="inputState{{i}}" [ngModel]='passenger.gender'
                                                    (ngModelChange)="onFieldChange(i, 'gender', $event)" name="gender"
                                                    class="form-control">
                                                    <option selected>Choose...</option>
                                                    <option value="M">{{ 'Passenger.male' | translate }}</option>
                                                    <option value="F">{{ 'Passenger.female' | translate }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-row d-flex flex-row divider">
                                            <div class="divider-line d-flex flex-row"></div>
                                            <span *ngIf="collapse" class="close-btn d-flex flex-row"
                                                (click)="expand()"><i class="fa fa-angle-up"></i></span>
                                            <span *ngIf="!collapse" class="close-btn d-flex flex-row"
                                                (click)="expand()"><i class="fa fa-angle-down"></i></span>
                                        </div>
                                        <div *ngIf="collapse" class="form-row">
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputDocumentType{{i}}" class="form-label">Document
                                                    Type</label>
                                                <select id="inputDocumentType{{i}}" class="form-control input-size">
                                                    <option selected>Choose...</option>
                                                    <option>I - ID Card</option>
                                                    <option>P - Passport</option>
                                                    <option>A - ID Card</option>
                                                    <option>C - ID Card</option>
                                                    <option>IP - Passport</option>
                                                    <option>AC - Tripulaçao Membro Cert.</option>
                                                    <option>F - Nao Std Aprocado Doc</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6 col-sm 12" class="form-label">
                                                <label for="inputDocumentNumber{{i}}">Document Number</label>
                                                <input type="text" class="form-control" id="inputDocumentNumber{{i}}"
                                                    [ngModel]='passenger.passNumber'
                                                    (ngModelChange)="onFieldChange(i, 'passNumber', $event)"
                                                    name="passNumber">
                                            </div>
                                        </div>
                                        <div *ngIf="collapse" class="form-row">
                                            <div class="form-group col-md-6 col-sm 12">
                                                <label for="inputDocumentCountry{{i}}" class="form-label">Document Issue
                                                    Country</label>
                                                <select id="inputDocumentCountry{{i}}" class="form-control input-size"
                                                    [ngModel]='passenger.passCountry'
                                                    (ngModelChange)="onFieldChange(i, 'passCountry', $event)"
                                                    name="passCountry">
                                                    <option selected>Choose...</option>
                                                    <option *ngFor="let country of countries"
                                                        [selected]="country.code === 'ET'" [value]="country.code">
                                                        {{ country.name }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6 col-sm 12">
                                                <!-- <label for="inputExpireDate" class="form-label">Expiration Date</label>
                                                <input type="text" class="form-control input-size" id="inputExpireDate"> -->
                                                <mat-form-field id="fromlocation{{i}}">
                                                    <input matInput [matDatepicker]="expDatePicker"
                                                        placeholder="Expiration Date" readonly="readonly"
                                                        (click)="expDatePicker.open()"
                                                        [ngModel]='passenger.passExpireDate'
                                                        (ngModelChange)="onFieldChange(i, 'passExpireDate', $event)"
                                                        [min]='passExpDate' name="passExpireDate">
                                                    <mat-datepicker-toggle matSuffix [for]="expDatePicker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker [startAt]="passExpDate" #expDatePicker>
                                                    </mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- seat selection -->
    <div class="card seat-selection-box" *ngIf=" !this.routerParams.identifier">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="label-box">
                        <img src="../assets/params/images/logo/seatselect.png" class="flight-labels-img" width="40"
                            height="40" alt="">
                        <span class="flight-labels">Seat Selection</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card card-custom">
                    <div class="grid p-10 card-body">
                        <table class="table table-borderless custom-table">
                            <thead class="seat-table-header">
                                <tr>
                                    <th scope="col">Passenger</th>
                                    <th scope="col"></th>
                                    <th scope="col" *ngFor="let segment of segments">Seat for<br />{{ segment.from }} -
                                        {{ segment.to }}</th>
                                </tr>
                            </thead>
                            <tbody class="seat-table-body" *ngFor="let passenger of passengers">
                                <tr
                                    *ngIf="passenger.firstName && passenger.lastName && passenger.ageCategory !== 'INF'">
                                    <td>{{ passenger.firstName }} {{ passenger.middleName }} {{ passenger.lastName }}
                                    </td>
                                    <td></td>
                                    <td *ngFor="let segment of segments; let i = index;">
                                        <span *ngIf="passenger.seatAssigned && passenger.seatAssigned[i]">
                                            {{ passenger.seatAssigned[i].seat }}
                                        </span>
                                        <span *ngIf="!passenger.seatAssigned || !passenger.seatAssigned[i]">-</span>
                                    </td>
                                    <td><button class="btn choose-seat" [disabled]="postingBooking"
                                            (click)="goSelectSeat()">Choose Seat</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- payment method -->
    <div class="card payment-method">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="label-box">
                        <span class="flight-labels">{{'Checkout.title' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <mat-card *ngFor="let paymentMethod of paymentMethods" (click)="selectPaymentMethod(paymentMethod)"
                    class="payment-card">
                    <div class="payment-selection-overlay"
                        [ngClass]="{'visible': (selectedPaymentMethod === paymentMethod.code) || (paymentMethod.code === 'VISA' && isBrowserSafari)}">
                        <mat-icon *ngIf="selectedPaymentMethod === paymentMethod.code">check</mat-icon>
                        <div *ngIf="paymentMethod.code === 'VISA' && isBrowserSafari" class="card col-10">
                            <span><strong>VISA/Mastercard</strong></span>
                            <span>Please use another browser to complete payment with Credit/Debit card</span>
                        </div>
                    </div>
                    <mat-card-content>
                        <div class="payment-method-image" [ngStyle]="{background: 'url('+paymentMethod.image+')'}">
                        </div>
                        <mat-divider *ngIf="paymentMethod.code === 'VISA' && !isBrowserSafari"></mat-divider>
                        <p>{{ paymentMethod.name }}</p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <!-- receipt requirement -->
    <div class="card payment-method condition-box">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="label-box">
                        <mat-slide-toggle [disabled]="isPnrRecordSuccessful && this.routerParams.type ==='0'"
                            [(ngModel)]="receiptRequirement" color="primary">
                            <!-- <img src="../assets/params/images/logo/test-tube.png" class="flight-labels-img"
                                id="test-tube-img" width="40" height="40" alt=""> -->
                            <span class="flight-labels">{{ 'Passenger.receipt' | translate }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="receiptRequirement">
            </div>
            <div class="row" *ngIf="receiptRequirement">
                <div class="card accept-card">
                    <div class="card-body">
                        <div class="row accept-title-box">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-row">
                                            <div class="form-group">
                                                <label for="company-name" class="form-label">{{ 'Passenger.company_name'
                                                    |
                                                    translate }}</label>
                                                <input type="text" [(ngModel)]='selectedFlight.company.name'
                                                    name="firstName" class="form-control input-size" id="company-name"
                                                    placeholder="company name" style="text-transform:uppercase">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group">
                                                <label for="tin-number" class="form-label">{{ 'Passenger.tin_number' |
                                                    translate }}</label>
                                                <input type="text" [(ngModel)]='selectedFlight.company.number'
                                                    name="middleName" class="form-control input-size" id="tin-number"
                                                    placeholder="tin number" style="text-transform:uppercase">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card condition-box" *ngIf=" !this.routerParams.identifier || (this.routerParams.type === '0') ">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="label-box">
                        <img src="../assets/params/images/logo/general.png" class="flight-labels-img" width="40"
                            height="40" alt="">
                        <span class="flight-labels">General Condition</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card accept-card">
                    <div class="card-body">
                        <div class="row accept-title-box">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-11">
                                        <span class="accept-title">Acceptance of the General Condtions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <i class="fa fa-check-square accept-title"></i>
                            </div>
                            <div class="col-md-11">
                                <span class="accept-content">I have read and agreed to the
                                    <span id="fare-rules-link" (click)="openTerms()">Terms and Conditions</span> and
                                    <span id="fare-rules-link" (click)="openFareRules()">Fare Rules</span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card accept-card"
                    *ngIf="searchParams.flight? searchParams.flight.passengers.INF : searchParams.passengers.INF">
                    <div class="card-body">
                        <div class="row accept-title-box">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-11">
                                        <span class="accept-title"><i class="fa fa-info-circle"></i> Remark</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11">
                                <span class="accept-content">
                                    <span id="">For infant passengers, you need to provide birth certificate or any
                                        proof of infancy at the airport</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row book-button-box">
                <div class="col-md-12">
                    <span *ngIf="selectedFlight.totalPrice && pcrTest">{{'Filter.price' | translate}}:
                        <b>{{ _parseFloat(selectedFlight.totalPrice.substring(3)) | currency: 'ETB '
                            }}</b></span>
                    <span *ngIf="selectedFlight.totalPrice && !pcrTest">{{'Filter.price' | translate}}:
                        <b>{{ selectedFlight.totalPrice.substring(3) | currency: 'ETB '
                            }}</b></span>
                    <button *ngIf="!this.routerParams.identifier" class="btn btn-warning search-btn-custom"
                        [disabled]="postingBooking" [ngClass]="{'disabled': postingBooking}" (click)="book()">Book
                        Now</button>
                    <button *ngIf="isPnrRecordSuccessful && this.routerParams.type ==='0' "
                        class="btn btn-warning search-btn-custom" [disabled]="postingBooking"
                        [ngClass]="{'disabled': postingBooking}" (click)="book(true)">Proceed To Issue Ticket</button>
                    <img src="../assets/gif/loading.gif" *ngIf="postingBooking" alt="processing">
                </div>
                <div>
                </div>
            </div>
        </div>
    </div>
</div>
