import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Options } from 'ng5-slider';
import { GlobalService } from 'src/app/services/global.service';
import { UapiService } from 'src/app/services/http/uapi.service';
import { FlightSearchResultModel, FlightSearchItemModel } from 'src/app/models/search-result.model';
import { AirportsService } from 'src/app/services/airports.service';

// import * as moment from 'moment';
import * as moment from "moment-timezone";
import { FormGroup, FormControl } from '@angular/forms';
import { CurrencyService } from 'src/app/services/currency.service';
import { Observable } from 'rxjs/Observable';
import { filter } from 'rxjs/operators';
import { of, Subscription, Subject, interval } from 'rxjs';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

interface SliderDetails {
  value: number;
  highValue: number;
  floor: number;
  ceil: number;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  slider: SliderDetails;
  direct: boolean = false;
  one_stop: boolean = false;
  two_stop: boolean = false;
  isCollapsedFilters: boolean = false;

  flightSearchResult: FlightSearchResultModel[];
  flightSearchResultBkp: FlightSearchResultModel[];
  flightSearchResultNoTouch: FlightSearchResultModel[];
  airlines: any[] = [];
  airports: any = [];
  carriers: any = [];

  checkedOrUnchecked: boolean = false;
  public departureAirportObj;
  public arrivalAirportObj;
  public flightType;

  //Price
  public minimumTicketPrice: number = 0;
  public maximumTicketPrice: number = 0;

  //Pagination
  page: number = 1;
  pageSize: number = 5;

  //Async-For-Flight-Search-Result-Filter
  sub: Subscription;
  manualTimer$: Observable<number> = interval(1000);

  //Flight Selection
  public selectedOutboundFlightIndex: number;
  public selectedOutboundFlightParentIndex: number;
  public selectedOutBoundFlight: any = null;

  public selectedInBoundFlight: any;
  public selectedInboundFlightIndex: number;
  public selectedFlight: any;

  public hasFlights: boolean = true;
  public skeletonResults = ["1", "2", "3"]
  public bookingHistory = ["1", "2", "3"]
  public isLoaded: boolean = false;
  public innerWidth: any;
  constructor(public globalService: GlobalService, private snackBarService: MatSnackBar, private uapiService: UapiService, public airportService: AirportsService,
    private currencyService: CurrencyService, private cdRef: ChangeDetectorRef, private router: Router, public utilService: UtilService) {

    this.airportService.getAirports().subscribe(data => {
      this.airports = data
    })

    this.airportService.getCarriers().subscribe(data => {
      this.carriers = data;
    })

    this.sub = this.manualTimer$.subscribe(() => {
      this.cdRef.markForCheck(); // Bad
    });

  }

  ngOnInit(): void {
    this.departureAirportObj = this.globalService.getDepartureSearchLocation();
    this.arrivalAirportObj = this.globalService.getArrivalSearchLocation();
    // Return either one-way , return or multi-city
    this.flightType = this.globalService.getFlightType();
    this.getShopping(this.globalService.getSearchParams());
    // this.globalService.setSelectedCalendarType("EC")
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 764) {
      this.isCollapsedFilters = true
    }
  }

  async getShopping(searchParams) {

    this.uapiService.postShoppingList(searchParams)
      .subscribe((result: any) => {
        const resp = result.response
        if (resp) {
          resp.map((flight, index) => {
            flight.id = index
          })
          this.flightSearchResult = (resp)
          localStorage.setItem('SEARCHRESULTS', JSON.stringify(resp))
          this.flightSearchResult.map(flight => {
            flight.directions.map(dir => {
              dir.map(_dir => {
                const newSegments = []
                _dir.segments.map(segment => {
                  if (segment.techStops && segment.techStops.length > 0) {
                    let _segment = {}
                    const _moreSegments = []
                    if (segment.details) {
                      segment.details.map((detail, i) => {
                        if (i === 0) {
                          let departureTime: any = moment(segment.departure).add(detail.flightTime, 'minutes')
                          departureTime = departureTime.toISOString(true).replace('Z', '+03:00')
                          _segment = {
                            "from": segment.from,
                            "to": detail.destination,
                            "group": segment.group,
                            "departure": detail.departure,
                            "arrival": departureTime,
                            "airline": segment.airline,
                            "flightNumber": segment.flightNumber,
                            "uapi_segment_ref": segment.uapi_segment_ref,
                            "uapiSegmentReference": segment.uapi_segment_ref,
                            "serviceClass": segment.serviceClass,
                            "plane": [detail.equipment],
                            "duration": [detail.flightTime],
                            "techStops": [],
                            "bookingClass": segment.bookingClass,
                            "baggage": segment.baggage,
                            "fareBasisCode": segment.fareBasisCode
                          }
                          newSegments.push(_segment)
                        } else {
                          let departureTime: any = moment(detail.departure).add(detail.flightTime, 'minutes')
                          departureTime = departureTime.toISOString(true).replace('Z', '+03:00')
                          console.log(detail)
                          _moreSegments.push({
                            "from": newSegments[i - 1].to,
                            "to": detail.destination,
                            "group": segment.group,
                            "departure": detail.departure,
                            "arrival": departureTime,
                            "airline": segment.airline,
                            "flightNumber": segment.flightNumber,
                            "uapi_segment_ref": segment.uapi_segment_ref,
                            "uapiSegmentReference": segment.uapi_segment_ref,
                            "serviceClass": segment.serviceClass,
                            "plane": [detail.equipment],
                            "duration": [detail.flightTime],
                            "techStops": [],
                            "bookingClass": segment.bookingClass,
                            "baggage": segment.baggage,
                            "fareBasisCode": segment.fareBasisCode
                          })
                        }
                      })
                      newSegments.push(..._moreSegments)
                    }
                  } else {
                    newSegments.push(segment)
                  }
                })
                _dir.segments = newSegments
              })
            })
          })
          this.flightSearchResultBkp = this.flightSearchResult;
          this.flightSearchResultNoTouch = JSON.parse(localStorage.getItem('SEARCHRESULTS'));
          console.log(this.flightSearchResultNoTouch)
          this.isLoaded = true
          if (resp.length > 0) {
            this.hasFlights = true
            this.extractAirlines(resp);
            this.extractTicketPrices(resp);
          } else {
            this.hasFlights = false
          }
        } else {
          this.hasFlights = false;
          this.isLoaded = true;
        }
      }, err => {
        this.hasFlights = false;
        this.isLoaded = true;
      })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  formatMoney(amount: string, selectedOutBoundFlight = null) {
    if (selectedOutBoundFlight) {
      amount = this.flightSearchResult[this.selectedOutboundFlightParentIndex].totalPrice
    }
    return this.currencyService.formatMoney(amount);
  }

  extractAirlines(flightResult) {
    if (flightResult.length > 0) {

    }
    flightResult.forEach(flight => {
      if (flight.directions && flight.directions.length > 0) {

        let carrier = flight.directions[0][0].platingCarrier
        this.airlines.push({ 'iatacode': carrier, 'checked': false })
      }
    });

    this.airlines = (Array.from(new Set(this.airlines.map(item => item.iatacode)))).map(airline => {
      return { 'iatacode': airline, 'checked': false }
    })
  }

  extractTicketPrices(flightResult) {

    flightResult.forEach(flight => {

      if (flight.totalPrice) {
        let price = Number(flight.totalPrice.replace(/[^0-9]/g, ''))

        if (this.minimumTicketPrice == 0 && price > 0) {
          this.minimumTicketPrice = price
        }

        if (this.maximumTicketPrice == 0) {
          this.maximumTicketPrice = price
        }

        if (this.minimumTicketPrice > price) {
          this.minimumTicketPrice = price
        }

        if (this.maximumTicketPrice < price) {
          this.maximumTicketPrice = price
        }
      }

    })


    this.slider = {
      value: this.minimumTicketPrice,
      highValue: this.maximumTicketPrice,
      floor: this.minimumTicketPrice,
      ceil: this.maximumTicketPrice
    }

  }

  sliderOptions(slider: SliderDetails): Options {
    if (slider) {
      return {
        floor: slider.floor,
        ceil: slider.ceil
      };
    } else {
      return {
        floor: 0,
        ceil: 0
      }
    }

  }

  formatToTime(dateTime) {
    return moment(dateTime).tz('Africa/Nairobi').format('HH:mm')
  }

  formatToDay(dateTime) {
    return moment(dateTime).tz('Africa/Nairobi').format('dddd, MMMM DD, YYYY')
  }

  getFlightType(segments) {
    if (segments.length == 1) {
      return "Direct"
    } else if (segments.length == 2) {
      return "1 Stop"
    } else if (segments.length > 2) {
      return "2 Stops"
    } else {
      return ""
    }
  }

  getAirportFullName(IATACode) {
    let airport = this.airports.find(airport => airport.IATACode === IATACode)
    if (airport) {
      return airport.airport + " (" + airport.IATACode + ")"
    } else {
      return ""
    }
  }

  getDepartureDate() {
    let departure = (this.globalService.getSearchParams()).legs[0].departureDate

    return this.formatToDay(departure);
  }

  getArrivalDate() {

    let arrival = (this.globalService.getSearchParams()).legs[(this.globalService.getSearchParams()).legs.length - 1].departureDate

    return this.formatToDay(arrival)

  }

  getCarrierName(carrierIATACode) {

    let carrierFound = this.carriers.find(carrier => carrier.carrier_code === carrierIATACode)

    if (carrierFound) {
      return carrierFound.carrier_name1
    } else {
      return carrierIATACode
    }

  }

  priceSliderValueChanged(value): void {

    this.filter()
  }

  checkAirlines() {

  }

  airlineCheckmarkTapped(carrier, c, event) {

  }

  selectOutBoundFlight(outboundFlight, outboundFlightIndex, parentFlight, flightIndex) {

    if (this.selectedFlight && this.selectedFlight.id !== parentFlight.id) {
      this.selectedFlight = null;
    }
    this.selectedOutboundFlightIndex = outboundFlightIndex
    this.selectedOutBoundFlight = this.flightSearchResultNoTouch[parentFlight.id].directions[0][outboundFlightIndex]
    if (this.flightType === 'one-way') {
      this.selectedFlight = this.flightSearchResultNoTouch[parentFlight.id]
    }
    this.selectedOutboundFlightParentIndex = parentFlight.id;
    console.log(this.selectedOutboundFlightParentIndex, this.selectedOutboundFlightParentIndex, parentFlight.id)
    if (this.flightType !== 'one-way' && this.innerWidth <= 764) {
      this.snackBarService.openFromComponent(DepartureFlightSnackBarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['bg-white', 'font-bold', 'text-center']
      })
    }
  }

  selectInBoundFlight(inboundFlight, inboundFlightIndex, parentFlight, flightIndex) {
    this.selectedInboundFlightIndex = inboundFlightIndex
    this.selectedInBoundFlight = this.flightSearchResultNoTouch[parentFlight.id].directions[1][inboundFlightIndex]
    if (this.selectedOutBoundFlight && this.selectedOutboundFlightParentIndex === parentFlight.id) {
      this.selectedFlight = this.flightSearchResultNoTouch[parentFlight.id]
    }
  }

  compareSegments(inboundFlight, selectedFlight) {

    let flag: boolean = true;
    (inboundFlight.segments).forEach((inbound, idx) => {
      if (selectedFlight.segments[idx] && (inbound.uapi_segment_ref !== (selectedFlight.segments)[idx].uapi_segment_ref)) {
        flag = false
      }
    });
    return flag;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  //Filter : ToDo ::: Filter By LayOver , Airline , Price
  directFlightFilterChange(event) {
    this.filter()
  }

  oneStopFlightFilterChange(event) {
    this.filter()
  }

  twoStopFlightFilterChange(event) {
    this.filter()
  }

  filter() {

    this.flightSearchResult = this.flightSearchResultBkp

    //Filter : LayOver
    this.flightSearchResult = this.flightSearchResult.filter(flight => {

      //LayOver Filters
      let flag, flag2, flag3 = false;
      if (this.direct)
        flag = this.filterWithLayovers('Direct', flight)

      if (this.one_stop)
        flag2 = this.filterWithLayovers('1 Stop', flight)

      if (this.two_stop)
        flag3 = this.filterWithLayovers('2 Stops', flight)

      //No Filter Applied
      if (!this.direct) {
        if (!this.one_stop) {
          if (!this.two_stop) {
            return true
          }
        }
      }
      return (flag || flag2 || flag3);


    });


    //Filter : Airline
    this.flightSearchResult = this.flightSearchResult.filter(flight => {

      //Airline Filters
      let flag4, updateFlag = false;

      //Just For Checking
      if (flight.directions.length > 0) {

        let platingCarrier = flight.directions[0][0].platingCarrier;
        this.airlines.forEach(airline => {
          if (airline.checked && (platingCarrier === airline['iatacode'])) {
            flag4 = true
          }

        })

      }

      this.airlines.forEach(airline => {
        if (airline.checked) {
          updateFlag = true
        }
      })

      // No Airline Filter Applied
      if (!updateFlag) {
        return true
      }

      return flag4

    })

    //Filter : Price Filter
    this.flightSearchResult = this.flightSearchResult.filter(flight => {

      let flightPrice = Number(flight.totalPrice.replace(/[^0-9]/g, ''))

      if (flightPrice >= this.minimumTicketPrice && flightPrice <= this.maximumTicketPrice) {
        return true
      }
      return false
    });

  }

  filterWithLayovers(layOverType: string, flight: FlightSearchResultModel) {

    let flag: boolean = true;
    //OutBound
    if (flight.directions[0]) {

      flight.directions[0].forEach(outbound => {
        if (this.getFlightType(outbound.segments) !== layOverType) {
          flag = false
        }
      })

    }

    //InBound
    if (flight.directions.length > 1) {

      flight.directions[1].forEach(outbound => {
        if (this.getFlightType(outbound.segments) !== layOverType) {
          flag = false
        }
      })

    }

    return flag
  }

  airlineFilterChange(airlineFilterStatus, index) {

    this.airlines[index].checked = airlineFilterStatus
    this.filter()
  }

  airlineSelected(airlineShortCode) {

    let selectedAirlineIndex = this.airlines.findIndex(airline => airline.iatacode === airlineShortCode);

    this.airlines[selectedAirlineIndex].checked = true
    this.filter()
  }

  ngOnDestroy() {
    // To avoid memory leaks, very easy to forget
    // Another reason to use async pipe - it unsubs automatically when component is destroyed
    this.sub.unsubscribe();
  }

  goPassengerDetails() {
    this.globalService.clearFlightSession()
    this.globalService.setSelectedOutboundFlight(this.selectedOutBoundFlight)
    this.globalService.setSelectedInboundFlight(this.selectedInBoundFlight)
    console.log('PASSENGER DETAILS: ', this.selectedFlight)
    this.globalService.setSelectedFlight(this.selectedFlight)
    if (this.globalService.getPassengers()) {
      this.globalService.getPassengers().map(passenger => {
        passenger.seatAssigned = null;
      })
    }
    this.globalService.removeFareRulesResp()
    this.snackBarService.dismiss();
    this.router.navigate(['/artboard'])
  }

  offsetExtract(incomingTime) {
    var time = (incomingTime.split('T')[1]).includes('-') ? (incomingTime.split('T')[1]).split('-')[1] : (incomingTime.split('T')[1]).split('+')[1]

    var sign = (incomingTime.split('T')[1]).includes('-') ? '-' : '+'

    var offset = time.split(':')

    var min = parseInt(offset[0]) * 60

    return min / 60
  }

  timeZoneDiff(arrival, departure) {

    let diff = parseInt((moment(arrival).format('L')).split('/')[1]) - parseInt((moment(departure).format('L')).split('/')[1])

    if (diff > 0) {
      return "( +" + diff + " )"
    } else {
      return ""
    }

  }

  formatTimedZone(time, formatting = "") {

    const reference = (moment().format('Z'))
    const ref = reference.includes('+') ? parseInt(reference.split(':')[0]) : parseInt(reference.split(':')[0]) * -1
    var sign = (time.split('T')[1]).includes('-') ? '-' : '+'

    var offset = sign === "-" ? (-1 * this.offsetExtract(time) - ref) : this.offsetExtract(time) - ref

    var val = offset > 0 ? "+" + offset : JSON.stringify(offset)

    // return moment(time).add(val, 'hours')
    return moment(time)
  }

  getTransitTime(departsFromTransitToNextFlight, arrivedFromPreviousFlight) {

    const transitTimeInMinute = parseFloat(moment.duration((moment(departsFromTransitToNextFlight)).diff(moment(arrivedFromPreviousFlight))).asMinutes() + "").toFixed(2)

    const hr = Math.floor(parseInt(transitTimeInMinute) / 60)
    const minutes = Math.floor(parseInt(transitTimeInMinute) % 60)
    return `${hr} hr ${minutes} min`

  }

  resetSearchSelection() {
    this.selectedOutBoundFlight = null;
    this.selectedFlight = null;
    this.selectedOutboundFlightIndex = null;
    this.selectedOutboundFlightParentIndex = null;
    this.snackBarService.dismiss();
  }

}


@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: 'snack-bar-component.html',
  styles: [`
    .example-pizza-party {
      color: #1e1c66;
    },
    i.fa-plane-arrival {
      transform: rotateY(180deg);
    }
  `],
})
export class DepartureFlightSnackBarComponent {}