<h2 mat-dialog-title>Secure Payment</h2>
<mat-dialog-content class="mat-typography">
    <div *ngIf="loading" class="container centered">
        <img src="../assets/gif/loading.gif" alt="loading">
    </div>
    <iframe *ngIf="!loading" #frame (load)="URLChangeHandler(frame)" [srcdoc]="data.html" frameBorder="0"
        [src]="safe"></iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="dismiss()">Close</button>
</mat-dialog-actions>