import { Component, OnInit } from '@angular/core';
import { UapiService } from 'src/app/services/http/uapi.service';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-fareinfo',
  templateUrl: './fareinfo.component.html',
  styleUrls: ['./fareinfo.component.css']
})
export class FareinfoComponent implements OnInit {

  loadingFareRules = false;
  fareRules = { inbound: [], outbound: [] }
  outboundSegmentDetail: any;
  inboundSegmentDetail: any;

  noFareInfo = false;
  constructor(private uapiService: UapiService, private globalService: GlobalService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchFareRules()
  }

  async fetchFareRules() {
    this.loadingFareRules = true;
    const bookedResponse = this.globalService.getBookingResponse();
    const params = {
      segments: [],
      passengers: {
        ADT: 0,
        CNN: 0,
        INF: 0
      },
      long: true,
      requestId: 'test',
    }
    if (bookedResponse) {
      //It is UR
      if (bookedResponse.segments) {
        params.segments = bookedResponse.segments.map(_segment => _segment);
        bookedResponse.passengers.map((_passenger, index) => {
          params.passengers[_passenger[index].ageCategory] += 1
        })
      }
    } else {
      const outboundSegments = this.globalService.getSelectedOutboundFlight() ? this.globalService.getSelectedOutboundFlight().segments : []
      // console.log(this.globalService.getSelectedFlight(), this.globalService.getSelectedOutboundFlight())
      const returnSegments = (this.globalService.getSelectedInboundFlight() && this.globalService.getSelectedInboundFlight().segments.length > 0) ? (this.globalService.getSelectedInboundFlight() ?
        this.globalService.getSelectedInboundFlight().segments : []) : []
      const passengers = this.globalService.getSearchParams().passengers;
      passengers['ADT'] = parseInt(passengers['ADT'])
      passengers['CNN'] = parseInt(passengers['CNN'])
      passengers['INF'] = parseInt(passengers['INF'])
      params.segments = outboundSegments.concat(returnSegments)
      params.passengers = passengers
    }
    let res: any = null;
    // if (this.globalService.getFareRulesResp()) {
    //   res = this.globalService.getFareRulesResp()
    // } else {
    res = await this.uapiService.getFareRules(params).toPromise()

    this.globalService.setFareRulesResp(res)
    // }
    if (!res) {
      this.noFareInfo = true;
      this.loadingFareRules = false;
    } else {
      res.fareRules.forEach((_fareRule, _idx) => {
        console.log(_fareRule)
        if (_idx === 0) {
          this.fareRules.outbound.push(_fareRule['_']);
        } else {
          this.fareRules.inbound.push(_fareRule['_'])
        }
      })
      Object.keys(res).forEach((_segment, _idx) => {
        if (_idx === 0) {
          this.outboundSegmentDetail = res[_segment];
        } else {
          this.inboundSegmentDetail = res[_segment];
        }
      })
      this.loadingFareRules = false;
    }
  }

  dismiss() {
    this.dialog.closeAll()
  }

}

