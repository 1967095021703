import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  MatTabsModule
} from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MdePopoverModule } from '@material-extended/mde';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { Ng5SliderModule } from 'ng5-slider';

import { AppComponent } from './app.component';
import { HomeComponent } from './modules/general/home/home.component';
import { AboutComponent } from './modules/general/about/about.component';
import { SigninComponent } from './modules/general/signin/signin.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { SearchComponent } from './modules/general/search/search.component';
import { ArtboardComponent } from './modules/general/artboard/artboard.component';
import { SuccessComponent } from './modules/general/success/success.component';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SeatComponent } from './modules/general/seat/seat.component';

import { Globals } from './globals';
import { PreviewComponent } from './modules/general/preview/preview.component'

import { Ng2CompleterModule } from "ng2-completer";

import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpLoaderFactory } from './services/translate.service';
import { HeaderComponent } from './modules/general/common/header/header.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { LayoutModule } from '@angular/cdk/layout';
import { MatStepperModule } from '@angular/material/stepper'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FareinfoComponent } from './components/fareinfo/fareinfo.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PassengerTypeFormComponent } from './components/passenger-type-form/passenger-type-form.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CybersourceComponent } from './modules/general/cybersource/cybersource.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TermsAndConditionsComponent } from './modules/general/terms-and-conditions/terms-and-conditions.component';
import { HellocashComponent } from './components/hellocash/hellocash.component';
import { EbirrDialogComponent } from './components/ebirr-dialog/ebirr-dialog.component';
import { AwashBankModalComponent } from './components/awash-bank-modal/awash-bank-modal.component';
import { TelebirrDialogComponent } from './components/telebirr-dialog/telebirr-dialog.component';
import { FacebookModule } from 'ngx-facebook';

export const MaterialModules = [
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatCardModule,
  MatTabsModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatCheckboxModule,
  FormsModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatSelectModule,
  CdkStepperModule,
  MatDialogModule,
  MatStepperModule,
  MatChipsModule,
  MatGridListModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatSnackBarModule
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    SigninComponent,
    NotFoundComponent,
    SearchComponent,
    ArtboardComponent,
    SuccessComponent,
    SeatComponent,
    PreviewComponent,
    HeaderComponent,
    FareinfoComponent,
    PaymentMethodsComponent,
    PassengerTypeFormComponent,
    CybersourceComponent,
    TermsAndConditionsComponent,
    HellocashComponent,
    EbirrDialogComponent,
    AwashBankModalComponent,
    TelebirrDialogComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    NoopAnimationsModule,
    ...MaterialModules,
    MdePopoverModule,
    Ng5SliderModule,
    Ng2CompleterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxSkeletonLoaderModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    FacebookModule.forRoot()
  ],
  exports: [],
  providers: [Globals, JwtHelperService, AuthService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
