import { Segment } from './../../../models/search-result.model';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UtilService } from 'src/app/services/util.service';
import { AirportsService } from 'src/app/services/airports.service';
import { PassengerModel } from 'src/app/models/passenger.model';
import { UapiService } from 'src/app/services/http/uapi.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FareinfoComponent } from 'src/app/components/fareinfo/fareinfo.component';
import { ToastrService } from 'ngx-toastr';
import { PaymentMethodsComponent } from 'src/app/components/payment-methods/payment-methods.component';
import * as moment from 'moment-timezone';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { parse } from 'path';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CybersourceComponent } from '../cybersource/cybersource.component';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { HellocashComponent } from 'src/app/components/hellocash/hellocash.component';
import { EbirrDialogComponent } from 'src/app/components/ebirr-dialog/ebirr-dialog.component';
import { AwashBankModalComponent } from 'src/app/components/awash-bank-modal/awash-bank-modal.component';
import { TelebirrDialogComponent } from 'src/app/components/telebirr-dialog/telebirr-dialog.component';
import { TelebirrService } from 'src/app/services/telebirr.service';

@Component({
  selector: 'app-artboard',
  templateUrl: './artboard.component.html',
  styleUrls: ['./artboard.component.css'],
})
export class ArtboardComponent implements OnInit {
  collapse: boolean = true;
  routeParameters: any;
  flight: any;
  airports: any[] = [];
  flightStatCodes: any[] = [];
  selectedFlight;
  loaded = false;
  urRecordLoaded = false;
  passengers: PassengerModel[] = [];
  bookingResp: any;
  countries = [];
  ageCategoryMap = {
    ADT: 'Adult',
    CNN: 'Child',
    INF: 'Infant',
  };
  merchant_ref_no = 'B' + new Date().getTime();
  birthDateRange = {
    ADT: { min: new Date(), max: new Date() },
    CNN: { min: new Date(), max: new Date() },
    INF: { min: new Date(), max: new Date() },
  };
  adultMinDate: Date = new Date();
  childDateRange: Date[] = [new Date(), new Date()];
  infantMaxDate: Date = new Date();
  segments = [];
  passExpDate = new Date();
  routerParams;
  universalRecordPassengers: PassengerModel[] = [];
  postingBooking = false;
  bookingError = false;
  rules = { inbound: [], outbound: [] };
  loadingFareRules = false;
  dialogRef: any;
  pnrSearchBookingResponse: any;
  isPnrRecordSuccessful = false;
  paymentMethods = [
    {
      name: 'Bank of Abyssinia',
      image: 'assets/imgs/payment_gateways/BOA.png',
      code: 'Bank of Abyssinia',
      description:
        'You can complete the payment at your nearest Bank of Abyssina branch.',
    },
    {
      name: 'Bank of Abyssina ATM',
      image: 'assets/imgs/payment_gateways/boa_atm.png',
      code: 'boa_atm',
      description:
        'You can use this PNR code to complete your payment at your nearest Bank of Abyssinia ATM.',
    },
    {
      name: 'Visa',
      image: 'assets/imgs/payment_gateways/visa_debit.jpg',
      code: 'VISA',
      description:
        'After choosing to continue, your flight will be reserved and redirect to payment.',
    },
    {
      name: 'Commercial Bank of Ethiopia',
      image: 'assets/imgs/payment_gateways/CBE.png',
      code: 'cbe',
      description:
        'You can complete the payment at your nearest Commercial Bank of Ethiopia branch.',
    },
    {
      name: 'Oromia Bank',
      image: 'assets/imgs/payment_gateways/oib_logo.png',
      code: 'oib',
      description:
        'You can complete the payment at your nearest Oromia Bank branch.',
    },
    {
      name: 'Berhan Bank',
      image: 'assets/imgs/payment_gateways/Berhan_Bank.png',
      code: 'Berhan Bank',
      description:
        'You can complete the payment at your nearest Berhan Bank branch.',
    },
    {
      name: 'Hibret Bank',
      image: 'assets/imgs/payment_gateways/Hibret_Bank.jpg',
      code: 'Hibret Bank',
      description:
        'You can complete the payment at your nearest Hibret Bank branch.',
    },
    {
      name: 'Bunna Bank',
      image: 'assets/imgs/payment_gateways/bunna_international_bank.png',
      code: 'Bunna Bank',
      description:
        'You can complete the payment at your nearest Bunna Bank branch.',
    },
    {
      name: 'Abay Bank',
      image: 'assets/imgs/payment_gateways/abay_bank.png',
      code: 'Abay Bank',
      description:
        'You can complete the payment at your nearest Abay bank branch.',
    },
    {
      name: 'Debub Global Bank',
      image: 'assets/imgs/payment_gateways/debub_global.png',
      code: 'Debub Global Bank',
      description:
        'You can complete the payment at your nearest Debub Global Bank branch.',
    },
    {
      name: 'Enat Bank',
      image: 'assets/imgs/payment_gateways/Enat_Bank.png',
      code: 'Enat Bank',
      description:
        'You can complete the payment at your nearest Enat Bank branch.',
    },
    {
      name: 'Wegagen HelloCash',
      image: 'assets/imgs/payment_gateways/wegagen_hellocash.png',
      code: 'HelloCash',
      description: ' ',
    },
    {
      name: 'Wegagen',
      image: 'assets/imgs/payment_gateways/wegagen.png',
      code: 'wegagen',
      description:
        'You can complete the payment at your nearest Wegagen Bank branch.',
    },
    {
      name: 'Awash Bank',
      image: 'assets/imgs/payment_gateways/Awash_Bank_Final_logo.jpg',
      code: 'Awash Bank',
      description:
        'You can complete the payment at your nearest Awash Bank branch.',
    },
    {
      name: 'Telebirr',
      image: 'assets/imgs/payment_gateways/telebirr-logo.png',
      code: 'telebirr',
      description:
        'Your flight has been reserved successfully. You will receive your e-ticket shortly.',
    },
    {
      name: 'Amhara Bank',
      image: 'assets/imgs/payment_gateways/Amhara_bank.jpg',
      code: 'Amhara Bank',
      description:
        'You can complete the payment at your nearest Amahra Bank branch.',
    },

    {
      name: 'Tsehay Bank',
      image: 'assets/imgs/payment_gateways/tsehay_bank.png',
      code: 'Tsehay Bank',
      description:
        'You can complete the payment at your nearest Tsehay Bank branch.',
    },
  ];
  selectedPaymentMethod: any;
  authUserRole: any = true;
  loading = false;
  pcrTest: any[];
  pcrAddup = 0;
  receiptRequirement = false;
  pcr_packages: any;
  passenger_package: any;
  exchangeRate: any;
  transportationPackageAvailable = false;
  KulbiGebrielPackages: any = { addup: 0, selected: false };
  KulbiGebrielPackage: any[];
  LOCAL_AIRPORTS = [
    'ADD',
    'AMH',
    'ASO',
    'AWA',
    'AXU',
    'BJR',
    'DIR',
    'GDE',
    'GDQ',
    'GMB',
    'BCO',
    'GOB',
    'JIJ',
    'JIM',
    'LLI',
    'MQX',
    'SHC',
    'SZE',
    'DSE',
  ];
  searchParams: any;
  public showContainer: boolean;
  paymentError: boolean = false;
  isBrowserSafari = false;
  isDuplicateFailure = null;
  isRequestSubmitted = null;
  hellocash_phone: string;
  bookingResponse: any;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public globalService: GlobalService,
    public utilService: UtilService,
    private router: Router,
    private airportService: AirportsService,
    private cdRef: ChangeDetectorRef,
    private uapiService: UapiService,
    private activateRouter: ActivatedRoute,
    private authService: AuthService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.isBrowserSafari =
      this.utilService.detectBrowser().indexOf('Safari') > -1;
    console.log(
      'IS BROWSER SAFARI: ',
      this.isBrowserSafari,
      navigator.userAgent,
      navigator.userAgent.indexOf('Safari')
    );
    this.loading = true;
    this.breakpointObserver
      .observe(['(min-width: 400px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });
    this.searchParams = this.globalService.getSearchParams();
    console.log('SEARCH PARAMS: ', this.globalService.getSearchParams());
    // this.authUserRole = this.authService.getAuthUser().role
    this.passExpDate.setUTCMonth(new Date().getUTCMonth() + 6);
    this.selectedFlight = this.globalService.getSelectedFlight();
    this.selectedFlight.totalPriceBkp = this.selectedFlight.totalPrice;
    this.airportService.getAirports().subscribe((data) => {
      this.airports = data;
    });
    this.airportService.getFlightStatCodes().subscribe((data) => {
      this.flightStatCodes = data;
    });
    this.uapiService.getPCRPackage().subscribe(
      (_info) => {
        this.pcr_packages = _info;
      },
      (err) => {}
    );
    this.uapiService.getKulbiGebrielPackage().subscribe(
      (_info: any) => {
        this.KulbiGebrielPackages = _info[0];
        console.log('INFO: ', _info);
      },
      (err) => {}
    );
    this.uapiService.getExchangeRate('USD').subscribe(
      (result: any) => {
        this.exchangeRate = result;
        console.log('exchange------', this.exchangeRate);
      },
      (err) => {}
    );
    this.utilService
      .getCountries()
      .subscribe((countries) => (this.countries = countries));
    this.adultMinDate.setUTCFullYear(new Date().getUTCFullYear() - 13);
    this.childDateRange[0].setUTCFullYear(new Date().getUTCFullYear() - 2);
    this.childDateRange[1].setUTCFullYear(new Date().getUTCFullYear() - 12);
    this.infantMaxDate.setUTCFullYear(new Date().getUTCFullYear() - 2);
    this.birthDateRange.ADT.min = this.adultMinDate;
    this.birthDateRange.ADT.max.setUTCFullYear(
      new Date().getUTCFullYear() - 170
    );
    this.birthDateRange.CNN.min = this.childDateRange[0];
    this.birthDateRange.CNN.max = this.childDateRange[1];
    this.birthDateRange.INF.max = this.infantMaxDate;
    this.activateRouter.queryParams.subscribe(async (params) => {
      this.routerParams = params;
      // Defaults to 0 if no query param provided.
      if (params.identifier) {
        this.universalRecordPassengers = []; // Reset UR Search
        //Reterive U-Record
        if (params.type) {
          if (params.type === '0') {
            await this.getUniversalRecordByPNR(params);
            this.initVars();
          } else if (params.type === '2') {
          } else if (params.type == '3') {
            await this.getUniversalRecordByTicket(params);
            this.initVars();
          }
        }
      } else {
        this.initVars();
      }
    });
  }

  async getUniversalRecordByPNR(param) {
    const response: any = await this.uapiService
      .postUniversalRecordByPNR(param)
      .toPromise();
    if (response.status) {
      this.segments = [];
      response.resp[0].segments.map((segment) => {
        this.segments.push(segment);
      });
      response.resp[0].passengerCounts = {
        ADT: 0,
        INF: 0,
        CNN: 0,
      };
      response.resp[0].passengers.map((passenger) => {
        const _passenger = new PassengerModel();
        _passenger.firstName = passenger.firstName;
        _passenger.lastName = passenger.lastName;
        _passenger.nationality = 'et';
        _passenger.ageCategory = passenger.ageCategory;
        _passenger.birthDate = passenger.birthDate;
        _passenger.gender = passenger.gender;
        this.universalRecordPassengers.push(passenger);
        if (passenger.ageCategory === 'ADT') {
          response.resp[0].passengerCounts.ADT += 1;
        } else if (passenger.ageCategory === 'CNN') {
          response.resp[0].passengerCounts.CNN += 1;
        } else if (passenger.ageCategory === 'INF') {
          response.resp[0].passengerCounts.INF += 1;
        }
      });
      response.resp[0].emails.map((email) => {
        this.universalRecordPassengers[email.index - 1].email = email.email;
      });
      this.selectedFlight = response.resp[0];
      this.globalService.setSelectedFlight(this.selectedFlight);
      this.cdRef.detectChanges();
      this.urRecordLoaded = true;
      this.pnrSearchBookingResponse = response.resp;
      this.isPnrRecordSuccessful = true;
    } else {
      this.urRecordLoaded = false;
      this.toastr.warning('PNR Not Found :( ', 'ERR');
    }
    if (!response) {
      this.urRecordLoaded = false;
      this.toastr.warning(
        'PNR Import Service Not Available , Please Try Again Later :(',
        'ERR'
      );
    }
  }

  async getUniversalRecordByTicket(param) {
    this.uapiService.postUniversalRecordByTicket(param).subscribe(
      (response: any) => {
        if (response.status) {
          this.segments = [];
          response.resp.tickets[0].coupons.map((segment) => {
            this.segments.push(segment);
          });
          response.resp.passengerCounts = {
            ADT: 0,
            INF: 0,
            CNN: 0,
          };
          response.resp.passengers.map((passenger) => {
            const _passenger = new PassengerModel();
            _passenger.firstName = passenger.firstName;
            _passenger.lastName = passenger.lastName;
            // _passenger.nationality = 'et';
            // _passenger.ageCategory = passenger.ageCategory
            // _passenger.birthDate = passenger.birthDate
            // _passenger.gender = passenger.gender
            this.universalRecordPassengers.push(passenger);
            if (passenger.ageCategory === 'ADT') {
              response.resp[0].passengerCounts.ADT += 1;
            } else if (passenger.ageCategory === 'CNN') {
              response.resp[0].passengerCounts.CNN += 1;
            } else if (passenger.ageCategory === 'INF') {
              response.resp[0].passengerCounts.INF += 1;
            }
          });
          this.selectedFlight = response.resp;
          this.globalService.setSelectedFlight(this.selectedFlight);
          this.urRecordLoaded = true;
        } else {
          this.urRecordLoaded = false;
          this.toastr.warning('Ticket Not Found :( ', ' ERR ');
        }
      },
      (err) => {
        this.urRecordLoaded = false;
        this.toastr.warning(
          'Ticket Import Service Not Available , Please Try Again Later :(',
          'ERR'
        );
      }
    );
    this.cdRef.detectChanges();
  }

  getAirportFullName(IATACode) {
    let airport = this.airports.find(
      (airport) => airport.IATACode === IATACode
    );
    if (airport) {
      return airport.airport + ' (' + airport.IATACode + ')';
    } else {
      return '';
    }
  }

  findFlightStatCode(statCode) {
    let flightStat = this.flightStatCodes.find(
      (_flightStatCode) => _flightStatCode.Status_Code === statCode
    );
    if (flightStat) {
      return `( ${flightStat.Description} )`;
    } else {
      return '';
    }
  }

  initVars() {
    if (this.globalService.getPassengers()) {
      this.passengers = this.globalService.getPassengers();
    } else {
      for (let i = 0; i < this.selectedFlight.passengerCounts.ADT; i++) {
        const passenger = new PassengerModel();
        passenger.nationality = 'et';
        passenger.type = 'ADT';
        passenger.ageCategory = 'ADT';
        passenger.packages = [];
        this.passengers.push(passenger);
      }
      for (let i = 0; i < this.selectedFlight.passengerCounts.CNN; i++) {
        const passenger = new PassengerModel();
        passenger.nationality = 'et';
        passenger.type = 'CNN';
        passenger.ageCategory = 'CNN';
        passenger.packages = [];

        this.passengers.push(passenger);
      }
      for (let i = 0; i < this.selectedFlight.passengerCounts.INF; i++) {
        const passenger = new PassengerModel();
        passenger.nationality = 'et';
        passenger.type = 'INF';
        passenger.ageCategory = 'INF';
        passenger.packages = [];

        this.passengers.push(passenger);
      }
    }
    this.pcrTest = new Array(this.passengers.length).fill(false);
    this.KulbiGebrielPackage = new Array(this.passengers.length).fill(false);
    console.log(this.pcrTest);
    console.log(this.KulbiGebrielPackage);
    // if (this.passengers) {
    //   this.passengers.map(passenger => {
    //     passenger.passExpireDate = '01-10-2024'
    //     passenger.birthDate = '01-10-1997'
    //     // passenger.ageCategory = 'ADT'
    //   })
    // }
    let outboundDirection = this.globalService.getSelectedOutboundFlight();
    let inboundDirection = this.globalService.getSelectedInboundFlight();
    let flightType = this.globalService.getFlightType();
    if (this.selectedFlight.directions) {
      this.selectedFlight.directions[0] = [];
      this.selectedFlight.directions[0][0] = [];
      this.selectedFlight.directions[0][1] = [];
    } else {
      //initialize one : Case For PNR Import and Ticket Import
      this.selectedFlight.directions = [];
      this.selectedFlight.directions[0] = [];
      this.selectedFlight.directions[1] = [];
    }
    if (outboundDirection) {
      this.selectedFlight.directions[0][0] = outboundDirection;
      this.segments = [...outboundDirection.segments];
    }
    if (inboundDirection && flightType !== 'one-way') {
      this.selectedFlight.directions[0][1] = inboundDirection;
      this.segments = [...this.segments, ...inboundDirection.segments];
    }
    if (this.globalService.getSelectedFlight().segments) {
      this.selectedFlight = this.globalService.getSelectedFlight();
      this.segments = this.globalService.getSelectedFlight().segments;
    }
    this.globalService.setSegments(this.segments);
    const newSegments = [];
    this.segments.map((segment) => {
      if (segment.techStops && segment.techStops.length > 0) {
        let _segment = {};
        const _moreSegments = [];
        if (segment.details) {
          segment.details.map((detail, i) => {
            if (i === 0) {
              let departureTime: any = moment(segment.departure).add(
                detail.flightTime,
                'minutes'
              );
              departureTime = departureTime
                .toISOString(true)
                .replace('Z', '+03:00');
              _segment = {
                from: segment.from,
                to: detail.destination,
                group: segment.group,
                departure: detail.departure,
                arrival: departureTime,
                airline: segment.airline,
                flightNumber: segment.flightNumber,
                uapi_segment_ref: segment.uapi_segment_ref,
                uapiSegmentReference: segment.uapi_segment_ref,
                serviceClass: segment.serviceClass,
                plane: [detail.equipment],
                duration: [detail.flightTime],
                techStops: [],
                bookingClass: segment.bookingClass,
                baggage: segment.baggage,
                fareBasisCode: segment.fareBasisCode,
              };
              newSegments.push(_segment);
            } else {
              let departureTime: any = moment(detail.departure).add(
                detail.flightTime,
                'minutes'
              );
              departureTime = departureTime
                .toISOString(true)
                .replace('Z', '+03:00');
              console.log(detail);
              _moreSegments.push({
                from: newSegments[i - 1].to,
                to: detail.destination,
                group: segment.group,
                departure: detail.departure,
                arrival: departureTime,
                airline: segment.airline,
                flightNumber: segment.flightNumber,
                uapi_segment_ref: segment.uapi_segment_ref,
                uapiSegmentReference: segment.uapi_segment_ref,
                serviceClass: segment.serviceClass,
                plane: [detail.equipment],
                duration: [detail.flightTime],
                techStops: [],
                bookingClass: segment.bookingClass,
                baggage: segment.baggage,
                fareBasisCode: segment.fareBasisCode,
              });
            }
          });
          newSegments.push(..._moreSegments);
        }
      } else {
        newSegments.push(segment);
      }
    });
    this.segments = newSegments;
    if (this.selectedFlight && this.selectedFlight.packages) {
      this.selectedFlight.packages.map((_package) => {
        if (_package.name === 'pcr') {
          // this.pcrTest = true
          this.pcrAddup = _package.addup;
        }
      });
    }

    if (!this.selectedFlight.company) {
      this.selectedFlight.company = { name: '', number: '' };
    } else {
      this.receiptRequirement = true;
    }
    this.loaded = true;
    this.urRecordLoaded = true;
    this.loading = false;
  }

  array(n): any[] {
    return Array(n);
  }

  getDecimals(num) {
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return rminutes;
  }

  getInt(num) {
    return parseInt(num);
  }

  expand() {
    if (this.collapse) {
      this.collapse = false;
    } else {
      this.collapse = true;
    }
  }

  setPassengers() {
    this.globalService.setPassengers(this.passengers);
  }

  goSelectSeat() {
    this.setPassengers();
    this.router.navigate(['seat']);
  }

  onFieldChange(i, paramName, e) {
    this.passengers[i][paramName] = e;
  }
  formatMoneyUsd(money) {
    let exchangerate = this.exchangeRate;
    let result = money / exchangerate;

    return Math.round(result);
  }

  addupKulbiGebriel(event, passenger, index) {
    let packagePrice = this.KulbiGebrielPackages.addup;
    let price = Number(
      this.selectedFlight.totalPrice.toString().split('ETB')[1]
    );
    if (passenger.packages && !Array.isArray(passenger.packages)) {
      passenger.packages = [];
    }
    if (this.KulbiGebrielPackage[index]) {
      price += Number(packagePrice);
      passenger.packages.push({
        name: 'KulbiGebriel',
        addup: packagePrice,
        description: this.KulbiGebrielPackages.description,
      });
    } else {
      price -= Number(packagePrice);
      let selectedPackageIndex = passenger.packages.findIndex(
        (_package) => _package.index === index
      );
      passenger.packages.splice(selectedPackageIndex, 1);
    }
    this.selectedFlight.totalPrice = 'ETB' + price;
  }

  addupPCRPrice(event, info, passenger, index) {
    let pcrPrice = info.addup;
    let price = Number(
      this.selectedFlight.totalPrice.toString().split('ETB')[1]
    );
    if (passenger.packages && !Array.isArray(passenger.packages)) {
      passenger.packages = [];
    }

    if (event.checked) {
      price += Number(pcrPrice);
      passenger.packages.push({
        name: 'pcr',
        addup: pcrPrice,
        description: info.description,
      });
    } else {
      price -= Number(pcrPrice);
      let selectedPackageIndex = passenger.packages.findIndex(
        (_package) => _package.index === index
      );
      passenger.packages.splice(selectedPackageIndex, 1);
    }

    this.selectedFlight.totalPrice = 'ETB' + price;
  }

  checkIfFlightIsInternational() {
    if (
      this.LOCAL_AIRPORTS.indexOf(
        this.selectedFlight.directions[0][0].to.toUpperCase()
      ) < 0 ||
      this.LOCAL_AIRPORTS.indexOf(
        this.selectedFlight.directions[0][0].from.toUpperCase()
      ) < 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIsDireDawaFlight() {
    let isDireDawaFlight = false;
    this.selectedFlight.directions.map((dir) => {
      dir.map((segment) => {
        if (segment.from === 'ADD' && segment.to === 'DIR') {
          isDireDawaFlight = true;
        }
      });
    });
    return isDireDawaFlight;
  }

  book(isIssueTicket = false) {
    if (this.globalService.getSelectedPaymentMethod() === 'HelloCash') {
      if (!this.hellocash_phone) {
        this.openHelloCashDialog();
        return;
      }
    } else if (this.globalService.getSelectedPaymentMethod() === 'awashbank') {
      this.openAwashBankDialog();
      return;
    }
    this.bookingError = false;
    this.isDuplicateFailure = false;
    this.isRequestSubmitted = false;

    this.paymentError = false;
    this.globalService.setPaymentError(false);
    let price2 = Number(
      this.selectedFlight.totalPrice.toString().split('ETB')[1]
    );
    let price = this.formatMoneyUsd(price2);
    if (this.routerParams && isIssueTicket) {
      if (this.routerParams.identifier) {
        this.universalRecordPassengers = []; // Reset UR Search
        //Reterive U-Record
        if (this.routerParams.type) {
          if (this.routerParams.type === '0') {
            console.log('Response resp > ', this.pnrSearchBookingResponse[0]);
            this.globalService.setBookingResponse(
              this.pnrSearchBookingResponse[0]
            );
            this.selectedFlight.pnr = this.pnrSearchBookingResponse[0].pnr;
            this.globalService.setSelectedFlight(
              this.pnrSearchBookingResponse[0]
            );
            this.globalService.setBookedFlight(
              this.pnrSearchBookingResponse[0]
            );
            this.globalService.setPaymentError(false);
            this.router.navigate(['/success']);
          }
        }
      }
    } else {
      if (this.pcrTest) {
        // this.selectedFlight.totalPrice = parseFloat(this.selectedFlight.totalPrice.substring(3)) + this.pcrAddup
        // this.selectedFlight.packages = [{name: 'pcr', addup: this.pcrAddup}]
      }
      this.postingBooking = true;
      this.bookingError = false;
      this.setPassengers(); //All Passenger Details are not accessiable if we skip Seatmap and Try to Book ... this will solve it
      const segments = this.globalService.getSegments();
      const bookingParams = {
        segments: segments,
        rule: 'SIP',
        passengers: this.passengers,
        phone: {
          countryCode: '38',
          location: 'IEV',
          number: this.globalService.getPassengers()[0].phoneNumber,
        },
        allowWaitlist: true,
        totalPrice: this.selectedFlight.totalPrice,
        email: this.globalService.getPassengers()[0].email,
      };
      console.log('booking > ', bookingParams);
      this.uapiService
        .postBooking(bookingParams)
        .toPromise()
        .then((resp) => {
          console.log('IN RESP: ', resp);
          if (resp) {
            this.bookingResp = resp;
            if (resp[0]) {
              console.log('book resp line 384 > ', resp[0]);
              this.globalService.setBookingResponse(resp[0]);
              const queueParams = {
                loyality_card:
                  this.globalService.getPassengers()[0].loyality_card,
                queue: '50',
                pcc: resp[0].bookingPCC,
                pnr: resp[0].pnr,
                passenger: this.globalService.getPassengers(),
                phone: this.globalService.getPassengers()[0].phoneNumber,
                email: this.globalService.getPassengers()[0].email,
                first_name: this.globalService.getPassengers()[0].firstName,
                title: this.globalService.getPassengers()[0].title,
                last_name: this.globalService.getPassengers()[0].lastName,
                middle_name: this.globalService.getPassengers()[0].middleName,
                totalPrice:
                  this.globalService.getSelectedPaymentMethod() === 'VISA'
                    ? 'USD' + price
                    : this.selectedFlight.totalPrice,
                user: this.authService.getAuthUser()
                  ? this.authService.getAuthUser().email
                  : 'guest',
                payment_method:
                  this.globalService.getSelectedPaymentMethod() ||
                  'GuzoGo-Call-Center',
                isCallCenter: true,
                payment_status: 'pending',
                receipt_requirement: false,
                company: this.receiptRequirement
                  ? this.selectedFlight.company
                  : null,
                documentURL: null,
                seatAssigned:
                  this.globalService.getPassengers()[0].seatAssigned,
                reminderSet: false,
                packages: this.selectedFlight.packages,
                remainderCronAllowed: 3,
                isWeb: true,
                hellocash_phone: this.hellocash_phone,
              };
              return this.uapiService.postQueue(queueParams, {
                inbound: this.globalService.getSelectedFlight().directions[1],
                outbound: this.globalService.getSelectedFlight().directions[0],
                resp: this.bookingResp,
              });
            }
          }
        })
        .then((resp) => {
          resp.subscribe(
            (queueResp: any) => {
              console.log(
                'QUEUE RESP: ',
                { resp, queueResp },
                this.globalService.getSelectedPaymentMethod()
              );
              if (queueResp) {
                const _selectedPaymentMethod =
                  this.globalService.getSelectedPaymentMethod();
                this.globalService.setBookingResponse(queueResp);
                this.selectedFlight.pnr = queueResp.BookingResponse.pnr;
                this.selectedFlight.npnr = queueResp.BookingResponse.npnr;
                this.globalService.setSelectedFlight(this.selectedFlight);
                this.globalService.setBookedFlight(this.selectedFlight);
                if (_selectedPaymentMethod === 'VISA') {
                  let data = {
                    action: 'make_payment',
                    profile_id: '',
                    access_key: '',
                    transaction_uuid: '',
                    signed_date_time: '',
                    signed_field_names:
                      'device_fingerprint_id,profile_id,access_key,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,payment_method,transaction_type,reference_number,auth_trans_ref_no,amount,currency,merchant_descriptor,override_custom_receipt_page',
                    unsigned_field_names:
                      'card_type,card_number,card_expiry_date,card_cvn,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,customer_ip_address,line_item_count,item_0_code,item_0_sku,item_0_name,item_0_quantity,item_0_unit_price,item_1_code,item_1_sku,item_1_name,item_1_quantity,item_1_unit_price,merchant_defined_data1,merchant_defined_data2,merchant_defined_data9,merchant_defined_data10,merchant_defined_data11',
                    payment_method: 'card',
                    transaction_type: 'sale',
                    reference_number: this.merchant_ref_no,
                    auth_trans_ref_no: this.merchant_ref_no,
                    amount: price,
                    currency: 'USD',
                    locale: 'en-us',
                    merchant_descriptor: 'GuzoGo',
                    bill_to_forename: '',
                    bill_to_surname: '',
                    bill_to_email: '',
                    bill_to_phone: '',
                    bill_to_address_line1: '',
                    bill_to_address_line2: '',
                    bill_to_address_city: '',
                    bill_to_address_state: '',
                    bill_to_address_country: '',
                    bill_to_address_postal_code: '',
                    override_custom_receipt_page:
                      'https://bmsthyhuxretxtw.guzogo.com/cybersource/sa-sop/response.php',
                    device_fingerprint_id: '',
                    customer_ip_address: '',
                    line_item_count: '',
                    item_0_sku: '',
                    item_0_code: '',
                    item_0_name: '',
                    item_0_quantity: '',
                    item_0_unit_price: '',
                    item_1_sku: '',
                    item_1_code: '',
                    item_1_name: '',
                    item_1_quantity: '',
                    item_1_unit_price: '',
                    merchant_defined_data1: '',
                    merchant_defined_data2: '',
                    merchant_defined_data9: this.selectedFlight.departure
                      ? this.selectedFlight.departure
                      : '', //Hours Till Departure ( Int Only)
                    merchant_defined_data10: this.selectedFlight.flightType
                      ? this.selectedFlight.flightType
                      : '', //Flight Type ( OneWay / RoundTrip / Circle ( Multi-City) )
                    merchant_defined_data11: this.segments[0].from
                      ? this.segments[0].from
                      : '', //Flight Segment Chain
                  };
                  console.log('QUEUE RESPONSE DATA: ', data);
                  this.globalService.setCybersourceData(data);
                  console.log('QUEUE RESPONSE PRICE: ', price);
                  this.globalService.setUSDPrice(price);
                  this.openCSPaymentDialog();
                  console.log('QUEUE RESPONSE');
                  // this.openFareRules()
                } else if (_selectedPaymentMethod === 'ebirr') {
                  this.openEbirrDialog(queueResp.ebirr);
                } else if (_selectedPaymentMethod === 'telebirr') {
                  console.log('telebirr payment method');
                  this.uapiService
                    .postTelebirrPayment({
                      pnr: this.selectedFlight.pnr,
                      totalPrice: this.selectedFlight.totalPrice,
                    })
                    .toPromise()
                    .then((telebirrPaymentResponse) => {
                      this.openTelebirrDialog({
                        ...queueResp,
                        telebirrPaymentResponse,
                      });
                    });
                } else {
                  this.globalService.setPaymentError(false);
                  this.router.navigate(['/success']);
                }
              }
            },
            (err) => {
              this.postingBooking = false;
              this.bookingError = true;
            }
          );
        })
        .catch((err) => {
          console.error('BOOKING FAILURE: ', err.error.error);
          if (err.error.error === 'duplicate') {
            this.isDuplicateFailure = true;
          } else if (err.error.error === 'req_submitted') {
            this.isRequestSubmitted = true;
          } else {
            this.bookingError = true;
          }
          this.postingBooking = false;
        });
    }
  }

  openFareRules() {
    this.dialogRef = this.dialog.open(FareinfoComponent);
  }

  openTerms() {
    this.dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '80vw',
      height: '80vh',
    });
  }

  openEbirrDialog(ebirrProps) {
    console.log('EBIRR PROPS: ', ebirrProps);
    this.dialogRef = this.dialog
      .open(EbirrDialogComponent, {
        panelClass: 'bookCardDialog',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '60vh',
        width: '60vw',
        data: ebirrProps,
      })
      .afterClosed()
      .subscribe((data) => {
        console.log('DATA: ', data);
        // if (data.helloCashPhoneNumber) {
        //   this.hellocash_phone = data.helloCashPhoneNumber;
        //   this.book()
        // } else {
        //   this.hellocash_phone = null;
        // }
      });
  }

  openHelloCashDialog() {
    this.dialogRef = this.dialog
      .open(HellocashComponent, {
        panelClass: 'bookCardDialog',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '60vh',
        width: '60vw',
      })
      .afterClosed()
      .subscribe((data) => {
        if (data.helloCashPhoneNumber) {
          this.hellocash_phone = data.helloCashPhoneNumber;
          this.book();
        } else {
          this.hellocash_phone = null;
        }
      });
  }

  openTelebirrDialog(queueResp) {
    console.log({ queueResp });
    this.dialogRef = this.dialog
      .open(TelebirrDialogComponent, {
        panelClass: 'bookCardDialog',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '80vh',
        width: '80vw',
        data: {
          telebirrURL: queueResp.telebirrPaymentResponse.data.toPayUrl,
          successURL: queueResp.telebirrPaymentResponse.successURL,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.uapiService
          .pnrInfo({
            pnr: queueResp.pnr,
            token:
              'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiQkFOS09GQUJZU1NJTklBIiwicGFzc3dvcmQiOiJxVHRScmU0UGlpcTVaSEdER2VvdVhTRDdZeW1weThJWXlFY1ZYc0tlcThOZ3ptS0E2ajh1SERzRWE5S1IiLCJpYXQiOjE1MTYyMzkwMjJ9.TKr6LzsaERAPbNAbFW2EYP1jhov6T6DYHfqKGQsp-7OZXfjI4O8aXhRklrnlybpguspviJ7gXv8p6F6hG0X0iw',
          })
          .subscribe(
            (res: any) => {
              if (res.pnr) {
                if (res.payment_status === 'paid') {
                  this.globalService.getBookingResponse().PaymentStatus =
                    'paid';
                  this.globalService.getBookingResponse().PaymentStatus =
                    'paid';
                  this.globalService.setPaymentError(false);
                  this.router.navigate(['/success']);
                }
              }
            },
            (err) => {}
          );
      });
  }

  openAwashBankDialog() {
    this.dialogRef = this.dialog
      .open(AwashBankModalComponent, {
        panelClass: 'awash-bank-dialog',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '70vh',
        width: '60vw',
      })
      .afterClosed()
      .subscribe((data) => {
        if (data.helloCashPhoneNumber) {
          this.hellocash_phone = data.helloCashPhoneNumber;
          this.book();
        } else {
          this.hellocash_phone = null;
        }
      });
  }

  openCSPaymentDialog() {
    this.dialogRef = this.dialog
      .open(CybersourceComponent, {
        panelClass: 'bookCardDialog',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '80vh',
        width: '80vw',
      })
      .afterClosed()
      .subscribe((data) => {
        this.uapiService
          .checkCSPaymentStatus({
            reference_id: this.merchant_ref_no,
          })
          .toPromise()
          .then((_response: any) => {
            if (
              _response &&
              _response.orderInfo &&
              _response.orderInfo.form_value
            ) {
              let formValue = JSON.parse(_response.orderInfo.form_value);
              if (formValue) {
                if (formValue.decision === 'ACCEPT') {
                  const _bkg_rsp = this.globalService.getBookingResponse();
                  _bkg_rsp.PaymentStats = 'paid';
                  this.globalService.setBookingResponse(_bkg_rsp);
                  this.globalService.setPaymentError(false);
                  this.router.navigate(['/success']);
                } else {
                  this.paymentError = true;
                  this.globalService.setPaymentError(true);
                  this.postingBooking = false;
                }
              }
              this.router.navigate(['/success']);
            }
          })
          .catch((_err) => {
            this.postingBooking = false;
            this.globalService.setPaymentError(true);
            this.paymentError = true;
            console.log('CS Not Recieved Yet', _err);
          });
      });
  }

  selectPaymentMethod(method) {
    this.selectedFlight.totalPrice = this.selectedFlight.totalPriceBkp;
    if (method.code === 'VISA' && this.isBrowserSafari) {
      return;
    } else if (method.code === 'telebirr') {
      this.selectedFlight.totalPrice = this.utilService.getAddUpByPercentage(
        this.selectedFlight.totalPrice,
        0.5
      );
    }
    this.globalService.setSelectedPaymentMethod(method.code);
    this.selectedPaymentMethod = method.code;
    this.globalService.setSelectedPaymentMethodObj(method);
  }

  formatTimedZone(time, formatting = '') {
    const reference = moment().format('Z');
    const ref = reference.includes('+')
      ? parseInt(reference.split(':')[0])
      : parseInt(reference.split(':')[0]) * -1;
    var sign = time.split('T')[1].includes('-') ? '-' : '+';
    var offset =
      sign === '-'
        ? -1 * this.offsetExtract(time) - ref
        : this.offsetExtract(time) - ref;
    var val = offset > 0 ? '+' + offset : JSON.stringify(offset);
    // return moment(time).add(val, 'hours')
    return moment(time);
  }

  offsetExtract(incomingTime) {
    // incomingTime = incomingTime.toString()
    var time = incomingTime.split('T')[1].includes('-')
      ? incomingTime.split('T')[1].split('-')[1]
      : incomingTime.split('T')[1].split('+')[1];

    var sign = incomingTime.split('T')[1].includes('-') ? '-' : '+';

    var offset = time.split(':');

    var min = parseInt(offset[0]) * 60;

    return min / 60;
  }

  formatToTime(dateTime) {
    return moment(dateTime).tz('Africa/Nairobi').format('HH:mm');
  }

  // handlePCRTestCheckbox() {
  //   this.pcrTest = !this.pcrTest
  //   console.log(this.pcrTest)
  // }

  _parseFloat(str) {
    return parseFloat(str);
  }
}
