import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
// const SecureStorage = require('secure-web-storage');
const SECRET_KEY = "j-ZfyUOuStDm4af8wp6zbGF88ddwCzZn2m7YorrrHO5otPJfBc1YEYdD-KCKAZ_GB_D5MOQo0GYpGNyIhBg1WHhrO6JA-ZLOM3TGHWaXU4XbqT55jWFtbSZOH9KHdqrqVVTTeFfYxNA7M8NYqHXyDnFI4Hm9qxvpwuR8amY-MyATJqgentZ5CJD33kGhwdhRDVP05Odsx6HbflALhbksIDAwbKNlXrn9oSwmtTWN4GaUMXkeVWyj5jwq7FFUqFvo0X3y5tGfoUEhdgMvqm4uMhmIam8cvDgLutc1dYWdLXb1zj7Aou8MEtY94NOs0wesCt7dmA-PnsBm38rwAfnuiQ"
const salt = CryptoJS.lib.WordArray.random(128 / 8);
const iv = CryptoJS.lib.WordArray.random(128 / 8);

class SecureStorage {

  storage: any;
  secret: string;

  constructor(storage, methods) {
    this.encrypt = methods.encrypt;
    this.decrypt = methods.decrypt;
    this.storage = storage;
  }

  encrypt = data => { }
  decrypt = data => { }

  getItem = (key): any => {
    return this.decrypt(this.storage.getItem(key))
  }

  setItem = (key, value) => {
    this.storage.setItem(key, this.encrypt(value))
  }

  removeItem = key => {
    this.storage.removeItem(key)
  }
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  constructor() { }

  
  public secureStorage = new SecureStorage(localStorage, {
    // Encrypt the localstorage data
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY, { iv: iv, salt: salt });
      data = data.toString();
      return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data) {
      if (data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY, { iv: iv, salt: salt });
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      } else {
        return null;
      }
    }
  });
}
